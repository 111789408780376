import React, { Fragment, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Modal from 'react-modal'
import { roleByAccountId, roleByWorkspaceId } from '../../apis/account/api'
import { ImportBox } from './style'
import FooterComponent from '../layout/footer'
import MenuSettingComponent from '../layout/menusetting'
import TopbarSettingsComponent from '../layout/topbarworkspacesettings'
import AlertComponent from '../layout/alert'
import TopbarNotiComponent from '../layout/topbarNotification'
import { MdOutlineFileDownload } from 'react-icons/md'
import LoadingComponent from '../layout/loading'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'
import { planDetail } from '../../apis/plan/api'
import { MdError, MdClose } from 'react-icons/md'
import { Form } from 'react-bootstrap'
import { uploadFileStatus, importTagById } from '../../apis/workspace/api'

const ImportComponent = () => {
  const { accountId, workspaceId } = useParams()
  const [accountDetail, setAccountDetail] = useState('')
  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')
  const [activeAccountRole, setActiveAccountRole] = useState('')
  const [planInfo, setPlanInfo] = useState('')

  const [isImport, setIsImport] = useState(false)
  const [readyUpload, setReadyUpload] = useState(false)

  const accessToken = localStorage.getItem('token')
  const [isLoadingPage, setIsLoadingPage] = useState(false)
  const [uploadFile, setUploadFile] = useState('')
  const [randomKey, setRandomKey] = useState(0)

  const [openPopupWarningFileSize, setOpenPopupWarningFileSize] =
    useState(false)
  const [openPopupWarningFail, setOpenPopupWarningFail] = useState(false)
  const [openPopupWarningError, setOpenPopupWarningError] = useState(false)
  const [openPopupWarningConfirm, setOpenPopupWarningConfirm] = useState(false)
  const [importId, setImportId] = useState('')
  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })

  const customStylesSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '436px',
    },
  }
  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setActiveWorkspaceRole(data.data.role)
    }
  }
  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setActiveAccountRole(data.data.role)
      if (data.data.role !== 'None') {
        getPlanInfo()
      }
    }
  }
  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setPlanInfo(data.plan_of_account_detail)
    }
  }

  const uploadFileImport = async () => {
    setOpenPopupWarningConfirm(true)
  }

  const confirmImport = async () => {
    setOpenPopupWarningConfirm(false)
    setIsImport(true)
    let obj = {
      import_id: importId,
    }
    const { data, status } = await importTagById(accessToken, workspaceId, obj)
    if (status === 200) {
      setTimeout(() => {
        setIsImport(false)
        setUploadFile('')
        setReadyUpload(false)
        setShowAlert({
          msg: 'Tag Settings has been imported successfully',
          status: 200,
          isShow: true,
        })
        setTimeout(() => {
          setShowAlert({
            msg: 'Tag Settings has been imported successfully',
            status: 200,
            isShow: false,
          })
        }, 5000)
      }, 2000)
    } else {
      setOpenPopupWarningConfirm(false)
      setOpenPopupWarningFail(true)
    }
  }

  const handleFileChange = (e) => {
    let randomString = Math.random().toString(36)
    setRandomKey(randomString)
    if (e.target.files) {
      if (e.target.files[0].size > 50000000) {
        e.target.value = null
        setUploadFile('')
        setOpenPopupWarningFileSize(true)
      } else {
        setUploadFile(e.target.files[0])
        statusUploadFile(e.target.files[0])
      }
    }
  }

  const statusUploadFile = async (file = '') => {
    const formData = new FormData()
    formData.append('file', file || uploadFile)

    const { data, status } = await uploadFileStatus(
      accessToken,
      workspaceId,
      formData,
    )
    if (status === 200) {
      setReadyUpload(true)
      setOpenPopupWarningError(false)
      setImportId(data.import_id)
    } else {
      setOpenPopupWarningError(true)
      setReadyUpload(false)
    }
  }

  useEffect(() => {
    document.title = 'Import | Tag Turbo'
    getRoleByAccountId()
    getRoleByWorkspaceId()
  }, [])

  return (
    <ImportBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          <TopbarSettingsComponent accountDetailFn={setAccountDetail} />
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarFreeNotiComponent planInfo={planInfo} />
              <TopbarNotiComponent />

              <MenuSettingComponent
                accountId={accountId}
                id={workspaceId}
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
              />
              {showAlert.isShow && (
                <AlertComponent
                  messageAlert={showAlert.msg}
                  status={showAlert.status}
                  alertType={showAlert.status ? 'alert-success' : 'alert-error'}
                />
              )}
              <div className="content-menu content-scroll ">
                <div className="container-md animate fadeIn one p-4 min-h-82">
                  <div className="content">
                    <div className="d-flex justify-content-between item-center mb-4">
                      <div className="col-12">
                        <h4 className="m-0 text-20 text-semibold">
                          Import Tag Settings
                        </h4>
                      </div>
                    </div>

                    <div className="card p-2 border box-shadow-center bg-white mt-2">
                      <div className="card-body">
                        <div className="p-3 mb-3 warningzone text-emphasis-danger d-flex">
                          <div className="mx-1">
                            <MdError />
                          </div>
                          <div className="mx-1">
                            {' '}
                            <p className="text-14 mb-1 text-semibold">
                              Warning: Importing Tag Settings Will Override
                              Current Tag Settings and Metadata
                            </p>
                            <p className="text-14 mb-1">
                              Please be aware that if you choose to import tag
                              settings from another workspace, your current tag
                              configurations and associated metadata will be
                              replaced. We recommend reviewing the imported tag
                              settings and metadata carefully to ensure they
                              align with your requirements.
                            </p>
                          </div>
                        </div>
                        <p className="text-14 text-semibold text-dark-gray">
                          Choose File
                        </p>
                        <div className="d-flex">
                          <Form.Control
                            className="w-280 btn-h-44 bg-white file-name"
                            disabled
                            value={uploadFile ? uploadFile.name : ''}
                            placeholder="Choose file"
                          />
                          <div>
                            <MdClose
                              className={`icon-remove cursor ${uploadFile ? '' : 'd-none'
                                }`}
                              onClick={() => (
                                setUploadFile(''), setReadyUpload(false)
                              )}
                            />
                          </div>

                          <Form.Group
                            controlId="formFile"
                            className="mb-3 px-3"
                          >
                            <div className="custom-file-input">
                              <Form.Control
                                type="file"
                                accept="application/json"
                                onChange={(e) => handleFileChange(e)}
                                key={randomKey || ''}
                              />
                              <div className="custom-file-label">
                                Browse file
                              </div>
                            </div>
                          </Form.Group>
                        </div>

                        {isImport ? (
                          <a className="btn btn-outline-primary  text-14 text-semibold text-14 w-160 btn-h-44 mb-3">
                            <span
                              className="spinner-border flex-shrink-0 mx-2"
                              role="status"
                            ></span>
                            Importing File
                          </a>
                        ) : (
                          <button
                            className="btn btn-blue text-semibold text-14 w-160 btn-h-44 mb-3"
                            onClick={() => uploadFileImport()}
                            disabled={!readyUpload}
                          >
                            <MdOutlineFileDownload
                              className="mx-0"
                              style={{ marginTop: '-3px' }}
                            />{' '}
                            Import File
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="my-3">
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupWarningFileSize}
        contentLabel="modal"
        style={customStylesSM}
        onRequestClose={() => setOpenPopupWarningFileSize(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <div className="modal-dialog modal-md ">
          <div className="modal-content">
            <div className="modal-header bg-danger">
              <div className="p-1 text-white">
                <p className="py-0 mb-0 text-20"></p>
              </div>
            </div>
            <div className="d-flex justify-content-end px-2">
              <div>
                <MdClose
                  className="cursor"
                  onClick={() => setOpenPopupWarningFileSize(false)}
                />
              </div>
            </div>
            <div className="modal-body p-3 mt-3 text-center">
              <img
                className="mb-2"
                src="/assets/img/warning.png"
                alt="tag turbo"
              />

              <p className="text-18 text-semibold text-danger">
                File size limit!
              </p>
              <p className="text-14">
                The import file is too large to upload. <br />
                Maximum upload file size: 50 MB. Please select another file.
              </p>
            </div>
            <div className="modal-footer justify-content-center px-5 pb-4">
              <div
                className="btn btn-danger text-semibold text-14 w-140 btn-h-44"
                onClick={() => setOpenPopupWarningFileSize(false)}
              >
                Close
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupWarningError}
        contentLabel="modal"
        style={customStylesSM}
        onRequestClose={() => setOpenPopupWarningError(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <div className="modal-dialog modal-md ">
          <div className="modal-content">
            <div className="modal-header bg-danger">
              <div className="p-1 text-white">
                <p className="py-0 mb-0 text-20"></p>
              </div>
            </div>
            <div className="d-flex justify-content-end px-2">
              <div>
                <MdClose
                  className="cursor"
                  onClick={() => setOpenPopupWarningError(false)}
                />
              </div>
            </div>
            <div className="modal-body p-3 mt-3 text-center">
              <img
                className="mb-2"
                src="/assets/img/warning.png"
                alt="tag turbo"
              />

              <p className="text-18 text-semibold text-danger">Error!</p>
              <p className="text-14">
                Something went wrong. Check to see if your connection is secure
                and try again.
              </p>
            </div>
            <div className="modal-footer justify-content-center px-5 pb-4">
              <div
                className="btn cursor text-14 w-140 btn-h-44"
                onClick={() => setOpenPopupWarningError(false)}
              >
                Close
              </div>
              <div
                className="btn btn-danger text-semibold text-14 w-140 btn-h-44"
                onClick={() => statusUploadFile()}
              >
                Retry
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupWarningFail}
        contentLabel="modal"
        style={customStylesSM}
        onRequestClose={() => setOpenPopupWarningFail(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <div className="modal-dialog modal-md ">
          <div className="modal-content">
            <div className="modal-header bg-danger">
              <div className="p-1 text-white">
                <p className="py-0 mb-0 text-20"></p>
              </div>
            </div>
            <div className="d-flex justify-content-end px-2">
              <div>
                <MdClose
                  className="cursor"
                  onClick={() => setOpenPopupWarningFail(false)}
                />
              </div>
            </div>
            <div className="modal-body p-3 mt-3 text-center">
              <img
                className="mb-2"
                src="/assets/img/warning.png"
                alt="tag turbo"
              />

              <p className="text-18 text-semibold text-danger">
                Import Failed!
              </p>
              <p className="text-14">
                The selected file you're trying to import contains data fields
                that do not match our system's requirements.
                <br />
                <br />
                Please ensure that your file adheres to the correct format and
                data structure before attempting to import again.
              </p>
            </div>
            <div className="modal-footer justify-content-center px-5 pb-4">
              <div
                className="btn btn-danger text-semibold text-14 w-140 btn-h-44"
                onClick={() => setOpenPopupWarningFail(false)}
              >
                Close
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupWarningConfirm}
        contentLabel="modal"
        style={customStylesSM}
        onRequestClose={() => setOpenPopupWarningConfirm(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <div className="modal-dialog modal-md ">
          <div className="modal-content">
            <div className="modal-header bg-warning">
              <div className="p-1 text-white">
                <p className="py-0 mb-0 text-20"></p>
              </div>
            </div>
            <div className="d-flex justify-content-end px-2">
              <div>
                <MdClose
                  className="cursor"
                  onClick={() => setOpenPopupWarningConfirm(false)}
                />
              </div>
            </div>
            <div className="modal-body p-3 mt-3 text-center">
              <img
                className="mb-2"
                src="/assets/img/warning_yellow.png"
                alt="tag turbo"
              />

              <p className="text-18 text-semibold text-warning">Warning!</p>
              <p className="text-14">
                The system will create a new tag to replace <br />
                the old tag. If you don’t want to replace the old tag, <br />
                Please cancel this step.
              </p>
            </div>
            <div className="modal-footer justify-content-center px-5 pb-4">
              <div
                className="btn cursor text-14 w-140 btn-h-44"
                onClick={() => setOpenPopupWarningConfirm(false)}
              >
                Cancel
              </div>
              <div
                className="btn btn-warning text-white text-semibold text-14 w-140 btn-h-44"
                onClick={() => confirmImport()}
              >
                Confirm
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </ImportBox>
  )
}

export default ImportComponent
