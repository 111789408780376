import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { roleByAccountId, roleByWorkspaceId } from '../../apis/account/api'
import { ExportBox } from './style'
import FooterComponent from '../layout/footer'
import MenuSettingComponent from '../layout/menusetting'
import TopbarSettingsComponent from '../layout/topbarworkspacesettings'
import AlertComponent from '../layout/alert'
import TopbarNotiComponent from '../layout/topbarNotification'
import DataTable from 'react-data-table-component'
import { MdOutlineFileUpload } from 'react-icons/md'
import Select from 'react-select'
import LoadingComponent from '../layout/loading'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'
import { planDetail } from '../../apis/plan/api'
import {
  versionList,
  publishTagList,
  exportTags,
} from '../../apis/workspace/api'
import Collapse from 'react-bootstrap/Collapse'
import { timeSince, numberWithCommas } from '../../helper/helper'
import { Button } from 'react-bootstrap'

var _tempTag = []

const ExportComponent = () => {
  const { accountId, workspaceId } = useParams()

  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')
  const [activeAccountRole, setActiveAccountRole] = useState('')

  const [tagSettingList, setTagSettingList] = useState('')
  const [accountDetail, setAccountDetail] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const [versionItem, setVersionItem] = useState([])
  const [selectVersion, setSelectVersion] = useState('')
  const [versionItemDefault, setVersionItemDefault] = useState([])
  const [openTag, setOpenTag] = useState(true)

  const accessToken = localStorage.getItem('token')
  const [isLoadingPage, setIsLoadingPage] = useState(false)
  const [planInfo, setPlanInfo] = useState('')
  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })
  const [dataTagsList, setDataTagsList] = useState([])
  const [isCheckedNull, setIsCheckedNull] = useState(false)

  const [isBtnLoading, setIsBtnLoading] = useState(false)
  const [workspaceName, setWorkspaceName] = useState('')

  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setActiveWorkspaceRole(data.data.role)
    }
  }
  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setActiveAccountRole(data.data.role)
      if (data.data.role !== 'None') {
        getPlanInfo()
      }
    }
  }

  useEffect(() => {
    document.title = 'Export | Tag Turbo'
    getVerionsList()
    getRoleByAccountId()
    getRoleByWorkspaceId()
  }, [])

  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setPlanInfo(data.plan_of_account_detail)
    }
  }

  const getPublishTagList = async (version) => {
    let obj = {
      version,
    }
    const { data, status } = await publishTagList(accessToken, obj, workspaceId)
    if (status === 200) {
      let tagsList = data.tags
      tagsList.forEach(function (obj) {
        obj.id = obj.id
        obj.tagName = obj.tag_name
        obj.page = obj.is_global ? 'Global' : obj.path
        obj.type = obj.tag_type
        obj.status = (
          <span
            className={`badge badge-${
              obj.publish_status === 'published' ? 'light-blue' : 'light-gray'
            }`}
          >
            {obj.publish_status}
          </span>
        )
        obj.lastEdit = obj.update_at
      })
      setDataTagsList(tagsList.map((item) => item.id))

      setTagSettingList(tagsList)
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }

  const handleChangeVersion = async (e) => {
    _tempTag = []
    setSelectVersion(e.value)
    getPublishTagList(e.value)
  }

  const getVerionsList = async () => {
    const { data, status } = await versionList(accessToken, workspaceId)
    if (data?.workspace_detail?.version_list.length > 0 && status === 200) {
      setWorkspaceName(data?.workspace_detail?.name)
      let version = data.workspace_detail.version_list
      version.forEach(function (obj) {
        obj.value = obj.version
        obj.version = obj.version
        obj.active = obj.active
        obj.label = (
          <div>
            Version # {obj.version}{' '}
            {obj.active && <span className="badge badge-green">• Live</span>}
          </div>
        )
        delete obj.create_at
        delete obj.published_by
        delete obj.update_at
        delete obj.version
        delete obj.tags
      })

      setVersionItem(version)
      let filerActive = version.filter((item) => item.active)
      setVersionItemDefault(filerActive)

      getPublishTagList(filerActive[0]?.value)
      setSelectVersion(data?.workspace_detail?.active_version)
      setIsLoadingPage(false)
      setIsLoading(false)
    } else {
      setIsLoadingPage(false)
      setIsLoading(false)
    }
  }

  const columnsTagSetting = [
    {
      name: 'Tag Name',
      selector: (row) => row.tagName,
      sortable: true,
    },
    {
      name: 'Page',
      selector: (row) => row.page,
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row) => row.type,
      sortable: false,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: false,
    },
    {
      name: 'Last Edited',
      selector: (row) => row.lastEdit,
      cell: (row) => <div>{timeSince(row.lastEdit)}</div>,
      sortable: true,
    },
  ]
  const handleAlert = (msg, status) => {
    setShowAlert({
      msg,
      status,
      isShow: true,
    })

    setTimeout(() => {
      setShowAlert({
        msg,
        status,
        isShow: false,
      })
    }, 10000)
  }

  const checkAll = (isCheck) => {
    setIsCheckedNull(isCheck)
  }

  const handleCheckedTag = useCallback(
    (state) => {
      if (state && state.length !== 0) {
        _tempTag = state.selectedRows.map((item) => item.id)
        if (state.selectedCount === 0) {
          checkAll(true)
        } else {
          checkAll(false)
        }
      }
    },
    [checkAll],
  )

  const exportTagsList = async () => {
    setIsBtnLoading(true)
    let obj = {
      version: selectVersion,
      tag_ids: _tempTag,
    }

    const { data, status } = await exportTags(
      accessToken,
      workspaceId,
      obj,
      workspaceName,
    )
    if (status === 200) {
      setIsBtnLoading(false)
      handleAlert('Tag Settings has been exported successfully.', true)
    } else {
      setIsBtnLoading(false)
      handleAlert('Failed to export Tag Settings.', false)
    }
  }

  const rowSelectCritera = useCallback((row) => {
    return row.id
  }, [])

  return (
    <ExportBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          <TopbarSettingsComponent accountDetailFn={setAccountDetail} />
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarFreeNotiComponent planInfo={planInfo} />
              <TopbarNotiComponent />

              <MenuSettingComponent
                accountId={accountId}
                id={workspaceId}
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
              />
              {showAlert.isShow && (
                <AlertComponent
                  messageAlert={showAlert.msg}
                  status={showAlert.status}
                  alertType={showAlert.status ? 'alert-success' : 'alert-error'}
                />
              )}
              <div className="content-menu content-scroll ">
                <div className="container-md animate fadeIn one p-4 min-h-82">
                  <div className="content">
                    <div className="d-flex justify-content-between item-center mb-4">
                      <div className="col-6">
                        <h4 className="m-0 text-20 text-semibold">
                          Export Tag Settings
                        </h4>
                      </div>

                      <div className="col-6 text-right">
                        {isBtnLoading ? (
                          <a className="btn btn-outline-primary  text-14 text-semibold">
                            <span
                              className="spinner-border flex-shrink-0 mx-2"
                              role="status"
                            ></span>
                            Export Tag Settings
                          </a>
                        ) : (
                          <Fragment>
                            {tagSettingList.length > 0 ? (
                              <Button
                                className="btn badge-blue text-14 text-semibold"
                                onClick={() => exportTagsList()}
                                disabled={isCheckedNull}
                              >
                                <MdOutlineFileUpload className="mx-2" />
                                Export Tag Settings
                              </Button>
                            ) : (
                              ''
                            )}
                          </Fragment>
                        )}
                      </div>
                    </div>
                    {isLoading ? (
                      <LoadingComponent />
                    ) : tagSettingList.length > 0 ? (
                      <div className="card p-2 border box-shadow-center bg-white mt-2">
                        <div className="card-body d-flex">
                          <div className="col-7">
                            <p className="text-18 text-semibold">
                              Tag Settings
                            </p>
                          </div>
                          <div className="col-5 d-flex">
                            <p className="text-18 text-semibold px-2">
                              Select Version
                            </p>
                            {versionItem.length !== 0 ? (
                              <Select
                                options={versionItem}
                                defaultValue={versionItemDefault}
                                className="col-8 fixed-select"
                                onChange={(e) => handleChangeVersion(e)}
                              />
                            ) : (
                              <Fragment />
                            )}
                          </div>
                        </div>
                        <div className="card-body">
                          <div
                            // onClick={() => setOpenTag(!openTag)}
                            aria-controls="example-collapse-text"
                            aria-expanded={openTag}
                            className={`header-collapse d-flex ${
                              openTag ? 'non-bd-bt' : ''
                            }`}
                          >
                            <span className="text-12 col-8 text-semibold">
                              Tag ({numberWithCommas(tagSettingList.length)}{' '}
                              Tags)
                            </span>
                          </div>
                          <Collapse in={openTag}>
                            <div id="example-collapse-text">
                              <div className="item-center">
                                <DataTable
                                  pagination={true}
                                  className="-fadeIn mt-0 none-overflow"
                                  center={true}
                                  columns={columnsTagSetting}
                                  data={tagSettingList}
                                  selectableRows={_tempTag}
                                  onSelectedRowsChange={(e) =>
                                    handleCheckedTag(e)
                                  }
                                  selectableRowSelected={rowSelectCritera}
                                />
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    ) : (
                      <div className="p-2 mt-2">
                        <div className="card-body my-4 box-card">
                          <div className="d-flex justify-content-center item-center text-center">
                            <div>
                              <img
                                src="/assets/img/nodata.png"
                                alt="tag turbo"
                              />
                              <h4 className="text-gray">No data found</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="my-3">
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </ExportBox>
  )
}

export default ExportComponent
