/*global chrome*/
import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { ActivityBox } from './style'
import FooterComponent from '../layout/footer'
import TopbarComponent from '../layout/topbar'
import MenuSettingComponent from '../layout/menusetting'
import TopbarNotiComponent from '../layout/topbarNotification'
import { planDetail } from '../../apis/plan/api'
import { allAccountByToken } from '../../apis/account/api'

import { accountActivityList } from '../../apis/tags/api'
import { roleByAccountId, roleByWorkspaceId } from '../../apis/account/api'
import { formatDateShortMonthAndTime } from '../../helper/helper'
import LoadingComponent from '../layout/loading'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'
import { FaFilter } from '@react-icons/all-files/fa/FaFilter'
import { MdExpandMore } from 'react-icons/md'

import Dropdown from 'react-bootstrap/Dropdown'

var _tempTag = []
var lastItem = ''

const ActivityLogComponent = () => {
  const [isReset, setIsReset] = useState(false)
  const [isloadingTable, setIsLoadingTable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLastItem, setIsLastItem] = useState(false)
  const [allTagsList, setAllTagsList] = useState([])
  const [isSuspended, setIsSuspended] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)
  const [isNewSearch, setIsNewSearch] = useState(false)
  const [workspaceList, setWorkspaceList] = useState([])

  const { accountId, workspaceId } = useParams()
  const [activeAccountRole, setActiveAccountRole] = useState('')
  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')
  const [planInfo, setPlanInfo] = useState('')

  const [selectFilterCate, setSelectFilterCate] = useState('')
  const [selectFilterWorkspace, setSelectFilterWorkspace] = useState('')
  const [selectFilterActivity, setSelectFilterActivity] = useState('')
  const [displaySelectFilterActivity, setDisplaySelectFilterActivity] =
    useState('')
  const [optionFilter, setOptionFilter] = useState('')

  const accessToken = localStorage.getItem('token')
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [showDropdownFilter, setShowDropdownFilter] = useState(false)
  const ref = useRef()

  const handleClickOutside = (event) => {
    try {
      if (!event?.target?.className?.includes('btn-filter')) {
        if (ref.current && ref.current.contains(event.target)) {
          return
        } else {
          setShowDropdownFilter(false)
        }
      }
    } catch (error) {
      setShowDropdownFilter(false)
    }
  }
  useEffect(() => {
    document.title = 'Account Activity | Tag Turbo'
    setIsLoadingPage(true)
    getPlanInfo()

    getRoleByAccountId()
    getAccountList()
    if (workspaceId) {
      getRoleByWorkspaceId()
      if (_tempTag.length === 0) {
        getAccountActivityList()
      }
    }
  }, [])

  useEffect(() => {
    if (isNewSearch) {
      getAccountActivityList()
    }
  }, [isNewSearch])

  useEffect(() => {
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setIsLoadingPage(false)
      setPlanInfo(data.plan_of_account_detail)
      if (
        data.plan_of_account_detail &&
        data.plan_of_account_detail.end_extend_at &&
        !planInfo.is_cancel
      ) {
        setIsSuspended(true)
      }
    }
  }
  const handleScroll = (event) => {
    if (
      event.currentTarget.scrollTop + window.innerHeight - 70 >
      event.currentTarget.scrollHeight
    ) {
      if (!isLoading && !isLastItem && allTagsList.length > 0) {
        setIsScrolling(true)
        getAccountActivityList(allTagsList[allTagsList.length - 1].id)
      }
    }
  }

  const getAccountList = async () => {
    const { data, status } = await allAccountByToken(accessToken)
    if (status === 200) {
      let workspaceByAccId = data.data.account_list.filter(
        (item) => item.id === accountId,
      )
      if (workspaceByAccId.length !== 0) {
        setWorkspaceList(workspaceByAccId[0].workspace_list)
      }
    }
  }

  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setActiveAccountRole(data.data.role)
    }
  }
  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setActiveWorkspaceRole(data.data.role)
    }
  }

  const handleFilterAcctivityList = () => {
    _tempTag = []
    // setIsNewSearch(true);
    setIsLoading(true)
    setIsLoadingTable(true)
    setShowDropdownFilter(false)
    setOptionFilter(true)
    setAllTagsList([])
    getAccountActivityList()
    setTimeout(() => {
      setIsLoadingTable(false)
    }, 1500)

  }

  const clearFilter = () => {
    setIsLoading(true)
    setIsLoadingTable(true)
    setShowDropdownFilter(false)
    setSelectFilterWorkspace('')
    setDisplaySelectFilterActivity('')
    setSelectFilterCate('')
    setSelectFilterActivity('')
    setAllTagsList([])
    _tempTag = []
    lastItem = ''

    setOptionFilter(false)
    getAccountActivityList('', 'new')

    setTimeout(() => {
      setIsLoadingTable(false)
    }, 1500)
    setTimeout(() => {
      setIsLoading(false)
    }, 700)
  }

  const getAccountActivityList = async (lastId = '', option = '') => {
    let obj = {
      page_size: 25,
      last_field_id: lastId,
    }

    if (option !== 'new') {
      if (selectFilterCate === 'All Workspace') {
        obj.workspace_id = ''
      } else {
        obj.workspace_id =
          selectFilterWorkspace === 'All Workspace' ? '' : selectFilterWorkspace
        obj.category =
          selectFilterCate === 'All Category' ? '' : selectFilterCate
        obj.activity =
          selectFilterActivity === 'All Activity' ? '' : selectFilterActivity
      }
    } else {
      obj.workspace_id = ''
      obj.category = ''
      obj.activity = ''

      setAllTagsList([])
      lastItem = ''
      _tempTag = []
    }
    const { data, status } = await accountActivityList(
      accessToken,
      accountId,
      obj,
    )

    if (status === 200 && data.data.activity?.length !== 0) {
      setIsLastItem(false)
      if (
        !selectFilterWorkspace &&
        lastItem !== data.data.activity[data.data.activity.length - 1].id
      ) {
        lastItem = data.data.activity[data.data.activity.length - 1].id
        _tempTag.push(...data.data.activity)
        setAllTagsList(_tempTag.map((item) => item))
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      } else {
        setTimeout(() => {
          setIsLoading(false)

          setAllTagsList((prevItems) => [...prevItems, ...data.data.activity])
        }, 1000)
      }
    } else {
      setIsLastItem(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }

  const handleSelectAccount = (category) => {
    setSelectFilterCate(category)
    if (category === 'Account') {
      setSelectFilterWorkspace('All Workspace')
      setDisplaySelectFilterActivity('-')
    } else {
      setDisplaySelectFilterActivity('Select All')
    }
  }

  return (
    <ActivityBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          <TopbarComponent
            isPublish={true}
            resetList={isReset}
            callBackFn={''}
            activeWorkspaceRole={activeWorkspaceRole}
            activeAccountRole={activeAccountRole}
          />
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarFreeNotiComponent planInfo={planInfo} />
              <TopbarNotiComponent
                planInfo={planInfo}
                activeAccountRole={activeAccountRole}
              />

              <MenuSettingComponent
                accountId={accountId}
                id={workspaceId}
                activeAccountRole={activeAccountRole}
                activeWorkspaceRole={activeWorkspaceRole}
              />

              <div
                className="content-menu content-scroll "
                onScroll={handleScroll}
              >
                <div className="container-md animate fadeIn one p-4 min-h-82">
                  <div className="content">
                    <div className="d-flex justify-content-between item-center mb-4">
                      <div className="col-6">
                        <h4 className="m-0 text-20 text-semibold">
                          Account Activity{' '}
                        </h4>
                      </div>
                      <div className="col-6 text-right">
                        <button
                          disabled={isLoading}
                          className="btn-outline btn-blue btn-filter"
                          onClick={() =>
                            setShowDropdownFilter(!showDropdownFilter)
                          }
                        >
                          {isLoading ? (
                            <span
                              className="spinner-border flex-shrink-0 mx-2"
                              role="status"
                            ></span>
                          ) : (
                            <FaFilter className="mx-2 fixed-icon" />
                          )}
                          Filter
                        </button>

                      </div>
                    </div>
                    {optionFilter && (
                      <div className="d-flex mb-4">
                        <b className="text-12">Search By</b>
                        <div className="search-label">
                          Category: {selectFilterCate || 'Select All'}
                        </div>
                        <div className="search-label">
                          Workspace Name:{' '}
                          {displaySelectFilterActivity || 'Select All'}
                        </div>
                        <div className="search-label">
                          Activity: {selectFilterActivity || 'Select All'}
                        </div>
                      </div>
                    )}
                    {showDropdownFilter && (
                      <div
                        className="card w-25 p-3 dropdown-filter innerFilter"
                        ref={ref}
                      >
                        <p className="mb-1">Category</p>
                        <Dropdown className="mb-2 cursor ">
                          <Dropdown.Toggle className="dropdown-full dropdown-white d-flex justify-content-between ">
                            {selectFilterCate || 'Select All'}
                            <MdExpandMore className="mt-1" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="boxSetting">
                            <Dropdown.Item
                              onClick={() =>
                                handleSelectAccount('All Category')
                              }
                              className="dropdown-item-setting text-14 w-ellipsis"
                            >
                              All Category
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleSelectAccount('Workspace')}
                              className="dropdown-item-setting text-14 w-ellipsis"
                            >
                              Workspace
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleSelectAccount('Account')}
                              className="dropdown-item-setting text-14 w-ellipsis"
                            >
                              Account
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <p className="mb-1">Workspace Name</p>
                        <Dropdown className="mb-2 cursor ">
                          <Dropdown.Toggle
                            className="dropdown-full dropdown-white d-flex justify-content-between "
                            disabled={
                              selectFilterCate === 'Account' ? true : false
                            }
                          >
                            <p className="mb-0">
                              {displaySelectFilterActivity || 'Select All'}
                            </p>
                            <MdExpandMore className="mt-1" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="boxSetting">
                            <Dropdown.Item
                              onClick={() => (
                                setSelectFilterWorkspace('All Workspace'),
                                setDisplaySelectFilterActivity('All Workspace')
                              )}
                              className="dropdown-item-setting text-14 w-ellipsis"
                            >
                              Select All
                            </Dropdown.Item>
                            {workspaceList?.map((item, i) => {
                              return (
                                <Dropdown.Item
                                  key={i}
                                  onClick={() => (
                                    setSelectFilterWorkspace(item.id),
                                    setDisplaySelectFilterActivity(item.name)
                                  )}
                                  className="dropdown-item-setting text-14 w-ellipsis"
                                >
                                  {item.name}
                                </Dropdown.Item>
                              )
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                        <p className="mb-1">Activity</p>
                        <Dropdown className="mb-2 cursor ">
                          <Dropdown.Toggle className="dropdown-full dropdown-white d-flex justify-content-between">
                            {selectFilterActivity || 'Select All'}
                            <MdExpandMore className="mt-1" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="boxSetting">
                            <Dropdown.Item
                              onClick={() =>
                                setSelectFilterActivity('All Activity')
                              }
                              className="dropdown-item-setting text-14 w-ellipsis"
                            >
                              All Activity
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setSelectFilterActivity('Created')}
                              className="dropdown-item-setting text-14 w-ellipsis"
                            >
                              Created
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                setSelectFilterActivity('Accepted')
                              }
                              className="dropdown-item-setting text-14 w-ellipsis"
                            >
                              Accepted
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setSelectFilterActivity('Invited')}
                              className="dropdown-item-setting text-14 w-ellipsis"
                            >
                              Invited
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setSelectFilterActivity('Edited')}
                              className="dropdown-item-setting text-14 w-ellipsis"
                            >
                              Edited
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setSelectFilterActivity('Deleted')}
                              className="dropdown-item-setting text-14 w-ellipsis"
                            >
                              Deleted
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>

                        <div className="d-flex justify-content-end">
                          <span
                            className="p-2 -cursor text-14 clear-btn"
                            onClick={() => clearFilter()}
                          >
                            Clear All
                          </span>
                          <button
                            className="btn badge-blue px-3 text-14"
                            onClick={() => handleFilterAcctivityList()}
                          >
                            <b>Apply</b>
                          </button>
                        </div>
                      </div>
                    )}
                    {isloadingTable ? (
                      <LoadingComponent />
                    ) : allTagsList && allTagsList.length > 0 ? (
                      <Fragment>
                        <div className="border-shadow table-border-radius rounded bg-white mt-2 box-shadow-center">
                          <table className="table m-0  ">
                            <thead className="table-light">
                              <tr>
                                <td className="text-12">User</td>
                                <td className="text-12">Category</td>
                                <td className="text-12">Workspace Name</td>
                                <td className="text-12">Activity </td>
                                <td className="text-12">Details </td>
                                <td className="text-12">Date </td>
                              </tr>
                            </thead>

                            <tbody>
                              {allTagsList &&
                                allTagsList.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td className="text-12">
                                        {item.email_user}
                                      </td>
                                      <td className="text-12">
                                        {item.category}
                                      </td>
                                      <td className="text-12 w-table ">
                                        {item.workspace_name}
                                      </td>
                                      <td className="text-12">
                                        {item.activity}
                                      </td>
                                      <td className="text-12 w-25">
                                        {item.message}
                                      </td>
                                      <td className="text-12">
                                        {formatDateShortMonthAndTime(
                                          item.create_at,
                                        )}
                                      </td>
                                    </tr>
                                  )
                                })}

                              {isLoading && (
                                <tr>
                                  <td className="text-center" colSpan="6">
                                    <img
                                      src="/assets/img/Loading-spin.gif"
                                      className="loading-sm"
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Fragment>
                    ) : (
                      <div className="p-2 mt-5">
                        <div className="card-body my-4 box-card">
                          <div className="d-flex justify-content-center item-center text-center">
                            <div>
                              <img
                                src="/assets/img/nodata.png"
                                alt="tag turbo"
                              />
                              <h4 className="text-gray">No data found</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="my-3">
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </ActivityBox>
  )
}

export default ActivityLogComponent
