import React, { Fragment, useState, useEffect, useMemo } from 'react'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'

import { TagBox, RadioBox } from './style'
import FooterComponent from '../layout/footer'
import TopbarComponent from '../layout/topbar'
import MenuComponent from '../layout/menu'
import TopbarNotiComponent from '../layout/topbarNotification'

import {
  MdClose,
  MdNavigateNext,
  MdArrowDropDown,
  MdCheckCircle,
  MdWarning,
  MdInfo,
} from 'react-icons/md'
import { RiCheckboxCircleFill } from 'react-icons/ri'
import {
  channelList,
  ga4Info,
  saveGa4,
  unsyncGa4,
  authGoogleToken,
  accountSummaries,
  measurementIdGA4,
  saveGGAds,
  ggAdsInfo,
  unsyncGGAds,
  saveMetaAds,
  saveCMP,
  unsyncConsentMode,
  metaAdsInfo,
  unsyncPixelAds,
  saveTikTokAds,
  tiktokAdsInfo,
  unsyncTiktokAds,
  cmpInfo,
  getCurrentPlan,
} from '../../apis/integration/api'
import { useGoogleLogin } from '@react-oauth/google'

import AlertComponent from '../layout/alert'
import { roleByAccountId, roleByWorkspaceId } from '../../apis/account/api'
import LoadingComponent from '../layout/loading'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'
import { planDetail } from '../../apis/plan/api'

const IntegrationComponent = () => {
  const [isReset, setIsReset] = useState(false)
  const [openPopupIntegrationsSetting, setOpenPopupIntegrationsSetting] =
    useState(false)
  const [openPopupCookiePlusSetting, setOpenPopupCookiePlusSetting] =
    useState(false)
  const [openPopupConsentModeSetting, setOpenPopupConsentModeSetting] =
    useState(false)
  const [openPopupGoogleAdsSetting, setOpenPopupGoogleAdsSetting] =
    useState(false)
  const [openPopupMetaAdsSetting, setOpenPopupMetaAdsSetting] = useState(false)
  const [openPopupConfirmUnsync, setOpenPopupConfirmUnsync] = useState(false)
  const [openPopupConfirmUnsyncGGAds, setOpenPopupConfirmUnsyncGGAds] =
    useState(false)
  const [openPopupConfirmUnsyncMetaAds, setOpenPopupConfirmUnsyncMetaAds] =
    useState(false)
  const [openPopupConfirmUnsyncCMP, setOpenPopupConfirmUnsyncCMP] =
    useState(false)
  const [channels, setChannels] = useState({ ga4: '' })
  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })
  const [activeAccountRole, setActiveAccountRole] = useState('')
  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [planInfo, setPlanInfo] = useState('')
  const [validatePropertyId, setValidatePropertyId] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [validateMeasurementId, setValidateMeasurementId] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [accountList, setAccountList] = useState(['TAG TURBO GTP'])
  const [measurementIdList, setMeasurementIdList] = useState(['TAG TURBO GTP'])
  const [propertyIdList, setPropertyIdList] = useState(['TAG TURBO GTP'])
  const [analyticsAccount, setAnalyticsAccount] = useState('')

  const [conversionId, setConversionId] = useState('')

  const [analyticName, setAnalyticName] = useState('')
  const [adStorageName, setAdStorageName] = useState('')
  const [functionalName, setFunctionalName] = useState('')
  const [personalName, setPersonalName] = useState('')
  const [securityName, setSecurityName] = useState('')

  const [measurementId, setMeasurementId] = useState('')
  const [cookiePlusId, setCookiePlusId] = useState('')
  const [consentModeId, setConsentModeId] = useState('')

  const [cmpDataLayerEvent, setCmpDataLayerEvent] = useState('')
  const [cmpDataLayerVariable, setCmpDataLayerVariable] = useState('')

  const [cmpAnalyticStorage, setCmpAnalyticStorage] = useState(false)
  const [cmpAdstorage, setCmpAdstorage] = useState(false)
  const [cmpFunctionStorage, setCmpFunctionStorage] = useState(false)
  const [cmpPersonalStorage, setCmpPersonalStorage] = useState(false)
  const [cmpAdsDataStorage, setCmpAdsDataStorage] = useState(false)
  const [cmpUrlPassthroughStorage, setCmpUrlPassthroughStorage] =
    useState(false)
  const [cmpWaitForUpdate, setCmpWaitForUpdate] = useState(500)

  const [propertyId, setPropertyId] = useState('')
  const [displayPropertyId, setDisplayPropertyId] = useState('')
  const [emailGA, setEmailGA] = useState('')
  const [userInfoGA, setUserInfoGA] = useState('')
  const [selectedMeasurementId, setSelectedMeasurementId] = useState('')
  const [dataGa4Info, setDataGa4Info] = useState('')

  const [openPopupUnsyncTryAgain, setOpenPopupUnsyncTryAgain] = useState(false)
  const [openPopupSyncTryAgain, setOpenPopupSyncTryAgain] = useState(false)

  const [emailGGAds, setEmailGGAds] = useState('')
  const [metaID, setMetaID] = useState('')

  const [integrationsType, setIntegrationsType] = useState('')
  const [isAutoTrack, setIsAutoTrack] = useState(true)
  const [enableConsentMode, setEnableConsentMode] = useState(false)

  const [isDisableInput, setIsDisableInput] = useState(false)

  const [openPopupTiktokAdsSetting, setOpenPopupTiktokAdsSetting] =
    useState(false)
  const [tiktokId, setTiktokId] = useState('')
  const [openPopupConfirmUnsyncTiktokAds, setOpenPopupConfirmUnsyncTiktokAds] =
    useState(false)
  const [isPlanDetail, setIsPlanDetail] = useState('')
  const [openPopupQuotaLimitReached, setOpenPopupQuotaLimitReached] =
    useState(false)
  const [consentMode, setConsentMode] = useState('basic')
  const [isLoading, setIsLoading] = useState(false)

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      inset: '50% auto auto 59%',
      position: 'absolute',
      height: '100vh',
      width: '82%',
    },
  }
  const customStylesSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '450px',
    },
  }
  const { accountId, workspaceId } = useParams()
  const token = localStorage.getItem('token')
  const accessToken = localStorage.getItem('token')

  const refreshPage = async () => {
    const response = await channelList(token, workspaceId)
    if (response?.data?.status) {
      setTimeout(() => {
        setChannels(response.data.status)
      }, 1000)
    }
    if (response?.status === 500) {
      console.error('refreshPage : ', response.data)
    }
    await getRoleByAccountId()
    await getRoleByWorkspaceId()
    setOpenPopupIntegrationsSetting(false)
    setOpenPopupGoogleAdsSetting(false)
    setOpenPopupMetaAdsSetting(false)
    setOpenPopupTiktokAdsSetting(false)
  }

  const onclickHandleAccount = (item) => {
    setAnalyticsAccount(item)
    setDisplayPropertyId('')
    setMeasurementId('')
    setPropertyId('')
    getProperty(item.displayName)
  }

  const onclickHandlePropertyId = (item) => {
    setPropertyId(item)
    setDisplayPropertyId(item.property.replace('properties/', ''))
    setMeasurementId('')
    getMeasurementId(item.property)
  }
  const resetDataMetaAds = () => {
    setOpenPopupMetaAdsSetting(true)
    setMetaID('')
  }

  const resetDataCMP = () => {
    setIsDisableInput(false)
    setMetaID('')
    setConsentModeId('')
    setEnableConsentMode(false)
    setCmpAdstorage(false)
    setCmpFunctionStorage(false)
    setCmpPersonalStorage(false)
    setCmpAdsDataStorage(false)
    setCmpUrlPassthroughStorage(false)
    setCmpWaitForUpdate(500)

    setCmpDataLayerEvent('')
    setCmpDataLayerVariable('')
    setAnalyticName('')
    setCmpAnalyticStorage(false)
    setAdStorageName('')
    setFunctionalName('')
    setPersonalName('')
    setSecurityName('')
  }

  useEffect(() => {
    document.title = 'Integrations | Tag Turbo'
    setIsLoadingPage(true)
    const getChannelList = async () => {
      const response = await channelList(token, workspaceId)
      if (response?.status === 200 && response?.data?.status) {
        setChannels(response.data.status)
      } else {
        console.error(response.data)
      }
    }
    getChannelList()
    getRoleByAccountId()
    getRoleByWorkspaceId()
  }, [])

  const resetDataGoogleAds = () => {
    setOpenPopupGoogleAdsSetting(true)
    setEmailGGAds('')
  }

  useMemo(() => {
    if (!openPopupIntegrationsSetting) return
    const getGa4 = async () => {
      const response = await ga4Info(token, workspaceId)
      if (channels['ga4'] === 'active') {
        setEmailGA(response?.data?.auth_data.email)
        setMeasurementId(response?.data?.integration_info?.measurement_id)
        setDataGa4Info(response?.data)
        setAnalyticsAccount(
          response?.data?.integration_info?.account_display_name,
        )
        setMeasurementId(response?.data?.integration_info?.measurement_id)
        var resProperty = response?.data?.integration_info?.property_id.replace(
          'properties/',
          '',
        )
        setPropertyId(resProperty)

        if (response?.data?.integration_info) {
          if (
            typeof response?.data?.auto_pageview === 'boolean' &&
            response?.data?.auto_pageview === false
          ) {
            setIsAutoTrack(false)
          } else {
            setIsAutoTrack(true)
          }
        } else {
          setIsAutoTrack(true)
        }
      } else if (channels['ga4'] === 'Permission Denied') {
        setEmailGA(response.data.auth_data.email)
        setMeasurementId(response.data.integration_info.measurement_id)
        setDataGa4Info(response.data)
        setAnalyticsAccount(response.data.integration_info.account_display_name)
        setMeasurementId(response.data.integration_info.measurement_id)
        var resProperty = response.data.integration_info.property_id.replace(
          'properties/',
          '',
        )
        setPropertyId(resProperty)
        if (response?.data?.integration_info) {
          if (
            typeof response?.data?.auto_pageview === 'boolean' &&
            response?.data?.auto_pageview === false
          ) {
            setIsAutoTrack(false)
          } else {
            setIsAutoTrack(true)
          }
        } else {
          setIsAutoTrack(true)
        }
      } else {
        resetData()
      }
      if (response?.status === 500) {
        console.error(response.data)
      }
    }
    getGa4()
  }, [openPopupIntegrationsSetting])

  useMemo(() => {
    if (!openPopupGoogleAdsSetting) return
    const getGGAds = async () => {
      resetDataGoogleAds()

      const response = await ggAdsInfo(token, workspaceId)
      if (channels['google_ads'] === 'active') {
        setConversionId(response.data.integration_info.conversion_id)
      } else if (channels['google_ads'] === 'Permission Denied') {
        setConversionId(response.data.integration_info.conversion_id)
      } else {
        resetDataGoogleAds()
      }
      if (response?.status === 500) {
        console.error(response.data)
      }
    }

    getGGAds()
  }, [openPopupGoogleAdsSetting])

  useMemo(() => {
    if (!openPopupMetaAdsSetting) return
    const getMetaAds = async () => {
      resetDataMetaAds()

      const response = await metaAdsInfo(token, workspaceId)
      if (channels['meta_pixel'] === 'active') {
        setMetaID(response.data.integration_info.pixel_id)
      } else if (channels['meta_pixel'] === 'Permission Denied') {
        setMetaID(response.data.integration_info.pixel_id)
      } else {
        resetDataMetaAds()
      }
      if (response?.status === 500) {
        console.error(response.data)
      }
    }

    getMetaAds()
  }, [openPopupMetaAdsSetting])

  useMemo(() => {
    if (!openPopupConsentModeSetting) return
    const getCMP = async () => {
      resetDataCMP()

      const { data, status } = await cmpInfo(token, workspaceId, 'cmp')
      if (status === 200 && data.integration_info) {
        const { integration_info } = data
        setIsDisableInput(true)
        setConsentModeId(integration_info.cmp_key)
        setCmpDataLayerEvent(integration_info?.cmp_datalayer_event || '')
        setCmpDataLayerVariable(integration_info?.cmp_datalayer_variable || '')
        setEnableConsentMode(integration_info.consent_mode)

        setAnalyticName(
          integration_info.consent_detail[0].name === 'analytics_storage'
            ? ''
            : integration_info.consent_detail[0].name,
        )
        setCmpAnalyticStorage(integration_info.consent_detail[0].value)

        setAdStorageName(
          integration_info.consent_detail[1].name === 'ad_storage'
            ? ''
            : integration_info.consent_detail[1].name,
        )
        setCmpAdstorage(integration_info.consent_detail[1].value)

        setFunctionalName(
          integration_info.consent_detail[4].name === 'functionality_storage'
            ? ''
            : integration_info.consent_detail[4].name,
        )
        setCmpFunctionStorage(integration_info.consent_detail[4].value)

        setPersonalName(
          integration_info.consent_detail[5].name === 'personalization_storage'
            ? ''
            : integration_info.consent_detail[5].name,
        )
        setCmpPersonalStorage(integration_info.consent_detail[5].value)

        setSecurityName(
          integration_info.consent_detail[6].name === 'security_storage'
            ? ''
            : integration_info.consent_detail[6].name,
        )

        setCmpAdsDataStorage(integration_info.consent_detail[7].value)
        setCmpUrlPassthroughStorage(integration_info.consent_detail[8].value)
        setCmpWaitForUpdate(integration_info.consent_detail[9].value)
      }
    }

    getCMP()
  }, [openPopupConsentModeSetting])

  useMemo(() => {
    if (!openPopupCookiePlusSetting) return
    const getCMP = async () => {
      setConsentMode('basic')
      resetDataCMP()
      const { data, status } = await cmpInfo(token, workspaceId, 'cookieplus')
      if (status === 200 && data.integration_info) {
        const { integration_info } = data
        setIsDisableInput(true)
        setConsentMode(integration_info.consent_mode_type)
        setConsentModeId(integration_info.cmp_key)
        setEnableConsentMode(integration_info.consent_mode)

        setCmpAnalyticStorage(integration_info.consent_detail[0].value)

        setCmpAdstorage(integration_info.consent_detail[1].value)

        setCmpFunctionStorage(integration_info.consent_detail[4].value)

        setCmpPersonalStorage(integration_info.consent_detail[5].value)

        setCmpAdsDataStorage(integration_info.consent_detail[7].value)
        setCmpUrlPassthroughStorage(integration_info.consent_detail[8].value)
        setCmpWaitForUpdate(integration_info.consent_detail[9].value)
      }
    }

    getCMP()
  }, [openPopupCookiePlusSetting])

  useMemo(() => {
    if (!openPopupTiktokAdsSetting) return
    const getTiktokAds = async () => {
      // resetDataTiktok();

      const response = await tiktokAdsInfo(token, workspaceId)
      if (channels['tiktok'] === 'active') {
        setTiktokId(response.data.integration_info.pixel_id)
      } else if (channels['tiktok'] === 'Permission Denied') {
        setTiktokId(response.data.integration_info.pixel_id)
      } else {
        resetDataTiktok()
      }
      if (response?.status === 500) {
        console.error(response.data)
      }
    }

    getTiktokAds()
  }, [openPopupTiktokAdsSetting])

  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setPlanInfo(data.plan_of_account_detail)
    }
  }
  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setIsLoadingPage(false)
      setActiveAccountRole(data.data.role)
      if (data.data.role !== 'None') {
        getPlanInfo()
      }
    }
  }
  useEffect(() => {
    checkCurrentPlan()
  }, [])

  const checkCurrentPlan = async () => {
    const { data, status } = await getCurrentPlan(accessToken, accountId)
    if (status === 200) {
      setIsPlanDetail(data?.plan_detail)
    }
  }

  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setIsLoadingPage(false)
      setActiveWorkspaceRole(data.data.role)
    }
  }
  const handleValidatePropertyId = (msg, status) => {
    setValidatePropertyId({ msg, status })
  }
  const handleValidateMeasurementId = (msg, status) => {
    setValidateMeasurementId({ msg, status })
  }

  const saveIntegrations = async () => {
    setIsLoading(true)
    let obj = {
      integration_type: 'ga4',
      auth_data: {
        refresh_token: userInfoGA.refresh_token,
        access_token: userInfoGA.access_token,
      },
      integration_info: {
        account_display_name: analyticsAccount.displayName,
        account_id: analyticsAccount.account,
        property_display_name: propertyId.displayName,
        property_id: propertyId.property,
        measurement_display_name: selectedMeasurementId.displayName,
        measurement_id: selectedMeasurementId.webStreamData.measurementId,
      },
      auto_pageview: isAutoTrack,
    }

    const response = await saveGa4(token, workspaceId, obj)
    if (response?.status === 500) {
      console.error(response.data)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else if (
      response.status === 'PLAN_LIMIT_EXCEED' &&
      response.code === 400
    ) {
      setOpenPopupQuotaLimitReached(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else {
      refreshPage()
      handleAlert('Your Google Analytics 4 has synced successfully!', true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    }
  }

  const saveIntegrationsGGAds = async () => {
    setIsLoading(true)
    let obj = {
      integration_type: 'google_ads',
      integration_info: {
        conversion_id: conversionId.replace(/-/g, ''),
      },
      auto_pageview: true,
    }
    const response = await saveGGAds(token, workspaceId, obj)
    if (response?.status === 200) {
      refreshPage()
      setOpenPopupSyncTryAgain(false)
      handleAlert('Your Google Ads has synced successfully!', true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else if (
      response.status === 'PLAN_LIMIT_EXCEED' &&
      response.code === 400
    ) {
      setOpenPopupQuotaLimitReached(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else {
      setOpenPopupSyncTryAgain(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    }
  }
  const saveIntegrationsMetaAds = async () => {
    setIsLoading(true)
    let obj = {
      integration_type: 'meta_pixel',
      integration_info: {
        pixel_id: metaID,
      },
      auto_pageview: true,
    }
    const response = await saveMetaAds(token, workspaceId, obj)
    if (response?.status === 200) {
      refreshPage()
      setOpenPopupSyncTryAgain(false)
      handleAlert('Your Meta Ads has synced successfully!', true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else if (
      response.status === 'PLAN_LIMIT_EXCEED' &&
      response.code === 400
    ) {
      setOpenPopupQuotaLimitReached(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else {
      setOpenPopupSyncTryAgain(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    }
  }

  const saveIntegrationsCMP = async (type) => {
    setIsLoading(true)
    let obj = {
      integration_type: type,
      integration_info: {
        cmp_key: consentModeId,
        cmp_datalayer_event: cmpDataLayerEvent,
        cmp_datalayer_variable: cmpDataLayerVariable,
        consent_mode: enableConsentMode,
        consent_mode_type: consentMode,
        consent_detail: [
          {
            name: analyticName || 'analytics_storage',
            value: cmpAnalyticStorage,
          },
          {
            name: adStorageName || 'ad_storage',
            value: cmpAdstorage,
          },
          {
            name: 'ad_personalization',
            value: cmpAdstorage,
          },
          {
            name: 'ad_user_data',
            value: cmpAdstorage,
          },
          {
            name: functionalName || 'functionality_storage',
            value: cmpFunctionStorage,
          },
          {
            name: personalName || 'personalization_storage',
            value: cmpPersonalStorage,
          },
          {
            name: securityName || 'security_storage',
            value: true,
          },
          {
            name: 'ads_data_redaction',
            value: cmpAdsDataStorage,
          },
          {
            name: 'url_passthrough',
            value: cmpUrlPassthroughStorage,
          },
          {
            name: 'wait_for_update',
            value: cmpWaitForUpdate,
          },
        ],
      },
    }

    const response = await saveCMP(token, workspaceId, obj)
    if (response?.status === 200) {
      setOpenPopupCookiePlusSetting(false)
      setOpenPopupConsentModeSetting(false)
      refreshPage()
      setOpenPopupSyncTryAgain(false)
      handleAlert('Your CookiePlus has synced successfully!', true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else if (
      response.status === 'PLAN_LIMIT_EXCEED' &&
      response.code === 400
    ) {
      setOpenPopupQuotaLimitReached(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else {
      setOpenPopupSyncTryAgain(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    }
  }

  const saveIntegrationsTiktokAds = async () => {
    setIsLoading(true)
    let userInput = tiktokId
    let trimmedInput = userInput.replace(/^\s+|\s+$/g, '')
    let obj = {
      integration_type: 'tiktok',
      integration_info: {
        pixel_id: trimmedInput,
      },
      auto_pageview: true,
    }
    const response = await saveTikTokAds(token, workspaceId, obj)
    if (response?.status === 200) {
      refreshPage()
      setOpenPopupSyncTryAgain(false)
      handleAlert('Your TikTok Ads has synced successfully!', true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else if (
      response.status === 'PLAN_LIMIT_EXCEED' &&
      response.code === 400
    ) {
      setOpenPopupQuotaLimitReached(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else {
      setOpenPopupSyncTryAgain(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    }
  }
  const unsyncGa = async () => {
    setIsLoading(true)
    const response = await unsyncGa4(token, workspaceId)
    if (response?.status === 200) {
      setOpenPopupConfirmUnsync(false)
      refreshPage()
      handleAlert('Your Google Analytics 4 has unsynced successfully!', true)
      setMeasurementId('')
      setPropertyId('')
      setOpenPopupUnsyncTryAgain(false)
      setDisplayPropertyId('')
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else if (
      response.status === 'PLAN_LIMIT_EXCEED' &&
      response.code === 400
    ) {
      setOpenPopupQuotaLimitReached(true)
      setOpenPopupConfirmUnsync(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else {
      console.error(response.data)
      setOpenPopupUnsyncTryAgain(true)
      setOpenPopupConfirmUnsync(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    }
  }
  const unsyncGoogleAds = async () => {
    setIsLoading(true)
    const response = await unsyncGGAds(token, workspaceId)
    if (response?.status === 200) {
      setConversionId('')
      setOpenPopupConfirmUnsyncGGAds(false)
      refreshPage()
      handleAlert('Your Google Ads has unsynced successfully!', true)
      setOpenPopupUnsyncTryAgain(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else if (
      response.status === 'PLAN_LIMIT_EXCEED' &&
      response.code === 400
    ) {
      setOpenPopupQuotaLimitReached(true)
      setOpenPopupConfirmUnsyncGGAds(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else {
      console.error('unsyncGoogleAds : ', response.data)
      setOpenPopupUnsyncTryAgain(true)
      setOpenPopupConfirmUnsyncGGAds(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    }
  }
  const unsyncMetaAds = async () => {
    setIsLoading(true)
    const response = await unsyncPixelAds(token, workspaceId)
    if (response?.status === 200) {
      setMetaID('')
      setOpenPopupConfirmUnsyncMetaAds(false)
      refreshPage()
      handleAlert('Your Meta Ads has unsynced successfully!', true)
      setOpenPopupUnsyncTryAgain(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else if (
      response.status === 'PLAN_LIMIT_EXCEED' &&
      response.code === 400
    ) {
      setOpenPopupQuotaLimitReached(true)
      setOpenPopupConfirmUnsyncMetaAds(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else {
      console.error('unsync Meta Ads : ', response.data)
      setOpenPopupUnsyncTryAgain(true)
      setOpenPopupConfirmUnsyncMetaAds(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    }
  }
  const unsyncCMP = async (type) => {
    setIsLoading(true)
    const response = await unsyncConsentMode(token, workspaceId, type)
    if (response?.status === 200) {
      setOpenPopupConfirmUnsyncCMP(false)
      setOpenPopupCookiePlusSetting(false)
      setOpenPopupConsentModeSetting(false)
      refreshPage()
      handleAlert(`Your ${integrationsType} has unsynced successfully!`, true)
      setOpenPopupUnsyncTryAgain(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else if (
      response.status === 'PLAN_LIMIT_EXCEED' &&
      response.code === 400
    ) {
      setOpenPopupQuotaLimitReached(true)
      setOpenPopupConfirmUnsyncCMP(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else {
      setOpenPopupUnsyncTryAgain(true)
      setOpenPopupConfirmUnsyncCMP(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    }
  }
  const unsyncDataTiktokAds = async () => {
    setIsLoading(true)
    const response = await unsyncTiktokAds(token, workspaceId)
    if (response?.status === 200) {
      setTiktokId('')
      setOpenPopupConfirmUnsyncTiktokAds(false)
      refreshPage()
      handleAlert('Your TikTok Ads has unsynced successfully!', true)
      setOpenPopupUnsyncTryAgain(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else if (
      response.status === 'PLAN_LIMIT_EXCEED' &&
      response.code === 400
    ) {
      setOpenPopupQuotaLimitReached(true)
      setOpenPopupConfirmUnsyncTiktokAds(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    } else {
      console.error('unsync TikTok Ads : ', response.data)
      setOpenPopupUnsyncTryAgain(true)
      setOpenPopupConfirmUnsyncTiktokAds(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    }
  }
  const authGA = useGoogleLogin({
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/analytics.readonly',
    include_granted_scopes: true,
    state: 'pass-through value',
    redirect_uri: 'postmessage',
    ux_mode: 'popup',
    onSuccess: (codeResponse) => {
      getToken(codeResponse.code)
    },
  })

  const getToken = async (code) => {
    const { data } = await authGoogleToken(accessToken, accountId, code)

    setEmailGA(data.user_info.email)
    getAccountSummaries(data.user_info)
  }

  const getAccountSummaries = async (user_info) => {
    const { data } = await accountSummaries(
      accessToken,
      workspaceId,
      user_info.access_token,
      user_info.refresh_token,
    )
    setUserInfoGA(user_info)
    setAccountList(data.account_summaries_list)
  }
  const getProperty = async (accountName) => {
    const selectedAccountGA = accountList.filter(
      (item) => item.displayName === accountName,
    )
    if (selectedAccountGA[0]) {
      setPropertyIdList(selectedAccountGA[0].propertySummaries)
    }
  }

  const getMeasurementId = async (propertyId) => {
    const { data } = await measurementIdGA4(
      accessToken,
      workspaceId,
      userInfoGA.access_token,
      userInfoGA.refresh_token,
      propertyId,
    )
    setMeasurementIdList(data.measurement_list)
  }

  const handleChangeAdStorage = (e) => {
    setCmpAdstorage(e.target.checked)
  }

  const handleAlert = (msg, status) => {
    setShowAlert({
      msg,
      status,
      isShow: true,
    })

    setTimeout(() => {
      setShowAlert({
        msg,
        status,
        isShow: false,
      })
    }, 10000)
  }

  const resetData = () => {
    setOpenPopupIntegrationsSetting(true)
    setEmailGA('')
    setAnalyticsAccount('')
    setPropertyId('')
    setDataGa4Info('')
    handleValidatePropertyId('', true)
    handleValidateMeasurementId('', true)
    setIsAutoTrack(true)
  }

  const resetDataTiktok = () => {
    setOpenPopupTiktokAdsSetting(true)
    setTiktokId('')
  }

  const handleInputChange = (value) => {
    let inputValue = value.replace(/[^\d]/g, '')

    if (inputValue.length <= 11) {
      setConversionId(inputValue)
    }
  }
  const handleInputMetaChange = (value) => {
    let inputValue = value.replace(/[^\d]/g, '')

    setMetaID(inputValue)
  }

  const checkRetrySync = () => {
    if (integrationsType === 'ga4') {
      saveIntegrations()
    } else if (integrationsType === 'google_ads') {
      saveIntegrationsGGAds()
    } else if (integrationsType === 'meta_pixel') {
      saveIntegrationsMetaAds()
    }
  }

  const checkRetryUnsync = () => {
    if (integrationsType === 'ga4') {
      unsyncGa()
    } else if (integrationsType === 'google_ads') {
      unsyncGoogleAds()
    } else if (integrationsType === 'meta_pixel') {
      unsyncMetaAds()
    }
  }
  const handleConsentMode = (e) => {
    setConsentMode(e.target.value)
  }
  return (
    <TagBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarComponent
                isPublish={true}
                resetList={isReset}
                callBackFn={''}
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
              />
              <TopbarFreeNotiComponent planInfo={planInfo} />
              <TopbarNotiComponent />

              <MenuComponent
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
                planInfo={planInfo}
              />
              {showAlert.isShow && (
                <AlertComponent
                  messageAlert={showAlert.msg}
                  status={showAlert.status}
                  alertType={showAlert.status ? 'alert-success' : 'alert-error'}
                />
              )}
              <div className="content-menu content-scroll ">
                <div className="container-md animate fadeIn one p-4 min-h-82">
                  <div className="content">
                    <div className="d-flex justify-content-between item-center mb-3">
                      <div className="col-6">
                        <h4 className="m-0 text-20 text-semibold">
                          Integrations
                        </h4>
                      </div>
                    </div>

                    <div className="row">
                      {/* Integrations Google Analytics 4 */}
                      <div className="col-4">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between item-center mb-3">
                              <div>
                                <img
                                  src="/assets/img/ga4.png"
                                  alt="tagturbo"
                                  style={{ height: '26px' }}
                                />
                              </div>
                              <div>
                                {channels['ga4'] === 'active' ? (
                                  <span className="badge badge-green">
                                    <RiCheckboxCircleFill /> Synced
                                  </span>
                                ) : channels['ga4'] === 'inactive' ? (
                                  ''
                                ) : (
                                  <span className="badge badge-md-Yellow">
                                    <MdWarning /> Integration Expired
                                  </span>
                                )}
                              </div>
                            </div>
                            <div>
                              <p className="integrate-name">
                                Google Analytics 4
                              </p>
                              <p className="integrate-desc">
                                Track data to Google Analytics
                              </p>
                            </div>
                            {isPlanDetail.ga4_integration ? (
                              <button
                                type="button"
                                className="settings-but -cursor"
                                onClick={() =>
                                  channels['ga4'] === 'active'
                                    ? (setOpenPopupIntegrationsSetting(true),
                                      setIntegrationsType('ga4'))
                                    : resetData()
                                }
                              >
                                Settings <MdNavigateNext />
                              </button>
                            ) : (
                              <Fragment />
                            )}
                          </div>
                        </div>
                      </div>
                      {/* End Integrations Google Analytics 4 */}

                      {/* Integrations Google Ads */}
                      <div className="col-4">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between item-center mb-3">
                              <div>
                                <img
                                  src="/assets/img/google_ads.png"
                                  style={{ height: '26px' }}
                                  alt="tagturbo"
                                />
                              </div>
                              <div>
                                {channels['google_ads'] === 'active' ? (
                                  <span className="badge badge-green">
                                    <RiCheckboxCircleFill /> Synced
                                  </span>
                                ) : channels['google_ads'] === 'inactive' ? (
                                  ''
                                ) : (
                                  <span className="badge badge-md-Yellow">
                                    <MdWarning /> Integration Expired
                                  </span>
                                )}
                              </div>
                            </div>
                            <div>
                              <p className="integrate-name">Google Ads</p>
                              <p className="integrate-desc">
                                Track data to Google Ads
                              </p>
                            </div>

                            {isPlanDetail.google_ads_integration ? (
                              <button
                                type="button"
                                className="settings-but -cursor"
                                onClick={() =>
                                  channels['google_ads'] === 'active'
                                    ? (setOpenPopupGoogleAdsSetting(true),
                                      setIntegrationsType('google_ads'))
                                    : resetDataGoogleAds()
                                }
                              >
                                Settings <MdNavigateNext />
                              </button>
                            ) : (
                              <Fragment />
                            )}
                          </div>
                        </div>
                      </div>
                      {/* End Integrations Google Ads */}

                      {/* Integrations Meta Ads */}
                      <div className="col-4">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between item-center mb-3">
                              <div>
                                <img
                                  src="/assets/img/meta_pixel.png"
                                  style={{ height: '26px' }}
                                  alt="tagturbo"
                                />
                              </div>
                              <div>
                                {channels['meta_pixel'] === 'active' ? (
                                  <span className="badge badge-green">
                                    <RiCheckboxCircleFill /> Synced
                                  </span>
                                ) : channels['meta_pixel'] === 'inactive' ? (
                                  ''
                                ) : (
                                  <span className="badge badge-md-Yellow">
                                    <MdWarning /> Integration Expired
                                  </span>
                                )}
                              </div>
                            </div>
                            <div>
                              <p className="integrate-name">Meta Ads</p>
                              <p className="integrate-desc">
                                Track data to Meta Ads
                              </p>
                            </div>

                            {/* --Meta Ads-- */}
                            {isPlanDetail.meta_integration ? (
                              <button
                                type="button"
                                className="settings-but -cursor"
                                onClick={() =>
                                  channels['meta_pixel'] === 'active'
                                    ? (setOpenPopupMetaAdsSetting(true),
                                      setIntegrationsType('meta_pixel'))
                                    : resetDataMetaAds()
                                }
                              >
                                Settings <MdNavigateNext />
                              </button>
                            ) : (
                              <Fragment>
                                <p className="text-grey-500 my-2 pt-1 text-12">
                                  <MdInfo /> Available on{' '}
                                  <b>Basic, Pro, Ultimate,</b> and{' '}
                                  <b>Enterprise plans </b>
                                </p>
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* End Integrations Meta Ads */}
                    </div>
                    <div className="row mt-4">
                      {/* Integrations TikTok Ads */}
                      <div className="col-4">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between item-center mb-3">
                              <div>
                                <img
                                  src="/assets/img/tiktok.png"
                                  style={{ height: '26px' }}
                                  alt="tagturbo"
                                />
                              </div>
                              <div>
                                {channels['tiktok'] === 'active' ? (
                                  <span className="badge badge-green">
                                    <RiCheckboxCircleFill /> Synced
                                  </span>
                                ) : channels['tiktok'] === 'inactive' ? (
                                  ''
                                ) : (
                                  <span className="badge badge-md-Yellow">
                                    <MdWarning /> Integration Expired
                                  </span>
                                )}
                              </div>
                            </div>
                            <div>
                              <p className="integrate-name">TikTok Ads</p>
                              <p className="integrate-desc">
                                Track data to TikTok Ads
                              </p>
                            </div>

                            {/* --TikTok Ads-- */}
                            {isPlanDetail.tiktok_integration ? (
                              <button
                                type="button"
                                className="settings-but -cursor"
                                onClick={() =>
                                  channels['tiktok'] === 'active'
                                    ? (setOpenPopupTiktokAdsSetting(true),
                                      setIntegrationsType('tiktok'))
                                    : resetDataTiktok()
                                }
                              >
                                Settings
                                <MdNavigateNext />
                              </button>
                            ) : (
                              <Fragment>
                                <p className="text-grey-500 my-2 pt-1 text-12">
                                  <MdInfo /> Available on <b>Pro, Ultimate,</b>{' '}
                                  and <b>Enterprise plans </b>
                                </p>
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* End Integrations TikTok Ads */}

                      {/* Integrations CookiePlus */}
                      <div className="col-4">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between item-center mb-3">
                              <div>
                                <img
                                  src="/assets/img/cookieplus.png"
                                  style={{ height: '26px' }}
                                  alt="tagturbo"
                                />
                              </div>
                              <div>
                                {channels['cookieplus'] === 'active' ? (
                                  <span className="badge badge-green">
                                    <RiCheckboxCircleFill /> Synced
                                  </span>
                                ) : channels['cookieplus'] === 'inactive' ? (
                                  ''
                                ) : (
                                  <span className="badge badge-md-Yellow">
                                    <MdWarning /> Integration Expired
                                  </span>
                                )}
                              </div>
                            </div>
                            <div>
                              <p className="integrate-name">CookiePlus</p>
                              <p className="integrate-desc">
                                Easily set up cookie banner with CookiePlus
                              </p>
                            </div>
                            {isPlanDetail.cookie_plus_integration ? (
                              <button
                                type="button"
                                className="settings-but -cursor"
                                onClick={() => (
                                  setOpenPopupCookiePlusSetting(true),
                                  setIntegrationsType('CookiePlus')
                                )}
                              >
                                Settings <MdNavigateNext />
                              </button>
                            ) : (
                              <Fragment />
                            )}
                          </div>
                        </div>
                      </div>
                      {/* End Integrations CookiePlus */}

                      {/* Integrations Cookie Consent */}
                      {/* <div className="col-4">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between item-center mb-3">
                              <div>
                                <img
                                  src="/assets/img/consentmode.png"
                                  style={{ height: '26px' }}
                                  alt="tagturbo"
                                />
                              </div>
                              <div>
                                {channels['cmp'] === 'active' ? (
                                  <span className="badge badge-green">
                                    <RiCheckboxCircleFill /> Synced
                                  </span>
                                ) : channels['cmp'] === 'inactive' ? (
                                  ''
                                ) : (
                                  <span className="badge badge-md-Yellow">
                                    <MdWarning /> Integration Expired
                                  </span>
                                )}
                              </div>
                            </div>
                            <div>
                              <p className="integrate-name">
                                Cookie Consent Management Platform
                              </p>
                              <p className="integrate-desc">
                                Manage your tags and Consent Mode settings
                              </p>
                            </div>
                            {isPlanDetail.other_cmp_integration ? (
                              <button
                                type="button"
                                className="settings-but -cursor"
                                onClick={() => (
                                  setOpenPopupConsentModeSetting(true),
                                  setIntegrationsType(
                                    'Cookie Consent Management Platform',
                                  )
                                )}
                              >
                                Settings <MdNavigateNext />
                              </button>
                            ) : (
                              <Fragment />
                            )}
                          </div>
                        </div>
                      </div> */}
                      {/* End Integrations CookiePlus */}
                    </div>
                  </div>

                  <div className="my-3">
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </Fragment>
          )}
          {/* Popup Integrations Setting */}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupIntegrationsSetting}
            contentLabel="modal"
            style={customStyles}
            onRequestClose={() => setOpenPopupIntegrationsSetting(false)}
            className={`modal-box animate  one ${
              openPopupIntegrationsSetting ? 'fadeInRight' : 'fadeOutRight'
            }`}
          >
            <TagBox>
              <div className="modal-dialog modal-md p-3">
                <div className="modal-content p-1">
                  <div className="modal-header">
                    <div>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => setOpenPopupIntegrationsSetting(false)}
                      ></button>
                      <span className="mx-3 text-20 text-semibold">
                        Google Analytics 4 Integration
                      </span>
                    </div>
                    <div>
                      {channels['ga4'] === 'active' ? (
                        <span className="badge badge-green">
                          <RiCheckboxCircleFill /> Synced
                        </span>
                      ) : channels['ga4'] === 'inactive' ? (
                        ''
                      ) : (
                        <span className="badge badge-md-Yellow">
                          <MdWarning /> Integration Expired
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="modal-body border p-3 mt-3 rounded">
                    <div className="d-flex">
                      <img
                        src="/assets/img/ga4.png"
                        style={{ height: '26px' }}
                        alt="tagturbo"
                      />
                      <p className="text-18 text-semibold mx-1 mb-0">
                        Google Analytics 4
                      </p>
                    </div>
                    <p className="text-14 mt-3 text-grey">
                      With the integration, you can easily pass data from Tag
                      Turbo to Google Analytics 4, <br /> allowing you to
                      leverage the attribution data to uncover in-depth insights
                      into how users interact with your website in Google
                      Analytics 4.
                    </p>

                    {emailGA || dataGa4Info ? (
                      <Fragment>
                        <div className="my-4 text-14">
                          <span className="email-status">
                            <MdCheckCircle className="text-18 mx-1 text-success" />
                            Connected with {emailGA}
                          </span>
                        </div>
                        <p className="text-semibold mt-3">
                          Analytics Account *
                        </p>
                        {dataGa4Info ? (
                          <input
                            type="text"
                            className="form-control  w-25 "
                            id="Name"
                            value={analyticsAccount}
                            disabled
                          />
                        ) : (
                          <Dropdown className="mt-3">
                            <Dropdown.Toggle className="dropdown-white w-280px d-flex justify-content-between">
                              <span className="text-14">
                                {analyticsAccount.displayName ||
                                  'Please select account'}
                              </span>{' '}
                              <span>
                                <MdArrowDropDown />
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="w-280px">
                              {accountList.length > 0 &&
                                accountList.map((item, i) => (
                                  <Dropdown.Item
                                    key={i}
                                    onClick={() => onclickHandleAccount(item)}
                                  >
                                    <p className="text-14 w-table mb-0">
                                      {item.displayName}
                                    </p>
                                  </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                        <p className="text-semibold mt-3">Property ID *</p>
                        {dataGa4Info ? (
                          <input
                            type="text"
                            className="form-control  w-25 "
                            id="Name"
                            value={propertyId}
                            disabled
                          />
                        ) : (
                          <Dropdown className="mt-3">
                            <Dropdown.Toggle
                              disabled={analyticsAccount ? false : true}
                              className={`dropdown-white w-280px d-flex justify-content-between ${
                                analyticsAccount ? '' : 'btn-drop-disabled'
                              }`}
                            >
                              <p className="text-14 mb-0">
                                {displayPropertyId || 'Please select property'}
                              </p>{' '}
                              <span>
                                <MdArrowDropDown />
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="w-280px">
                              {propertyIdList.length > 0 &&
                                propertyIdList.map((item, i) => (
                                  <Dropdown.Item
                                    key={i}
                                    onClick={() =>
                                      onclickHandlePropertyId(item)
                                    }
                                  >
                                    <p className="mb-0 text-14 w-table">
                                      {item.displayName}
                                    </p>
                                    <p className="mb-0 text-14 w-table">
                                      {item.property}
                                    </p>
                                  </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                        <p className="text-semibold mt-3">Measurement ID *</p>
                        {dataGa4Info ? (
                          <input
                            type="text"
                            className="form-control  w-25 "
                            id="Name"
                            value={measurementId}
                            disabled
                          />
                        ) : (
                          <Dropdown className="mt-3">
                            <Dropdown.Toggle
                              disabled={propertyId ? false : true}
                              className={`dropdown-white w-280px d-flex justify-content-between ${
                                propertyId ? '' : 'btn-drop-disabled'
                              }`}
                            >
                              <span className="text-14 drop-box w-table">
                                {measurementId || 'Please select Measurement '}
                              </span>{' '}
                              <span>
                                <MdArrowDropDown />
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="w-280px">
                              {measurementIdList.length > 0 &&
                                measurementIdList.map((item, i) => (
                                  <Dropdown.Item
                                    key={i}
                                    onClick={() => (
                                      setMeasurementId(
                                        item.webStreamData?.measurementId,
                                      ),
                                      setSelectedMeasurementId(item)
                                    )}
                                  >
                                    <p className="mb-0 text-14 w-table">
                                      {item.displayName}
                                    </p>
                                    <p className="mb-0 text-14 drop-box w-table">
                                      {item.webStreamData?.measurementId}
                                    </p>
                                  </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                        <div className="my-0 d-baseline">
                          <input
                            type="checkbox"
                            disabled={
                              channels['ga4'] === 'active' ||
                              channels['ga4'] === 'Permission Denied'
                                ? true
                                : false
                            }
                            id="autoTrack"
                            name="AutoTrack"
                            checked={isAutoTrack}
                            value={isAutoTrack}
                            className="mx-2 check-box-16"
                            onChange={() => setIsAutoTrack(!isAutoTrack)}
                          />
                          <span htmlFor="AutoTrack" className="text-14 pt-4">
                            I would like Tag Turbo to automatically tracked and
                            sent Pageview event whenever someone visits
                            <br />
                            my website where Tag Turbo tracking code is
                            installed.
                          </span>
                          <br />
                        </div>
                        {activeWorkspaceRole === 'Admin' && (
                          <div>
                            {channels['ga4'] === 'active' ||
                            channels['ga4'] === 'Permission Denied' ? (
                              <button
                                type="button"
                                className="btn w-lg btn-danger waves-effect waves-light mt-3"
                                style={{ padding: '8px 14px', width: '140px' }}
                                onClick={() =>
                                  isPlanDetail.ga4_integration
                                    ? setOpenPopupConfirmUnsync(true)
                                    : setOpenPopupQuotaLimitReached(true)
                                }
                              >
                                Unsync
                              </button>
                            ) : (
                              <Fragment>
                                <button
                                  type="button"
                                  className="btn w-lg btn-primary waves-effect waves-light mt-3"
                                  style={{
                                    padding: '8px 14px',
                                    width: '140px',
                                  }}
                                  onClick={() => saveIntegrations()}
                                  disabled={
                                    measurementId ? isLoading || false : true
                                  }
                                >
                                  {isLoading && (
                                    <span
                                      className="spinner-border flex-shrink-0 mx-2"
                                      role="status"
                                    ></span>
                                  )}
                                  Sync
                                </button>
                              </Fragment>
                            )}
                          </div>
                        )}{' '}
                      </Fragment>
                    ) : (
                      <button
                        onClick={() => authGA()}
                        type="button"
                        className="btn badge-blue text-14 text-semibold px-2"
                        disabled={activeWorkspaceRole !== 'Admin'}
                      >
                        <img
                          src="/assets/img/logogoogle.svg"
                          style={{ height: '26px', marginRight: '6px' }}
                          alt="tagturbo"
                        />{' '}
                        Sign in with Google
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </TagBox>
          </Modal>

          {/* Popup Integrations Google Ads Setting */}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupGoogleAdsSetting}
            contentLabel="modal"
            style={customStyles}
            onRequestClose={() => setOpenPopupGoogleAdsSetting(false)}
            className={`modal-box animate  one ${
              openPopupGoogleAdsSetting ? 'fadeInRight' : 'fadeOutRight'
            }`}
          >
            <TagBox>
              <div className="modal-dialog modal-md p-3">
                <div className="modal-content p-1">
                  <div className="modal-header">
                    <div>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => (
                          setOpenPopupGoogleAdsSetting(false),
                          setConversionId('')
                        )}
                      ></button>
                      <span className="mx-3 text-20 text-semibold">
                        Google Ads Integration
                      </span>
                    </div>
                    <div>
                      {channels['google_ads'] === 'active' ? (
                        <span className="badge badge-green">
                          <RiCheckboxCircleFill /> Synced
                        </span>
                      ) : channels['google_ads'] === 'inactive' ? (
                        ''
                      ) : (
                        <span className="badge badge-md-Yellow">
                          <MdWarning /> Integration Expired
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="modal-body border p-3 mt-3 rounded">
                    <div className="d-flex">
                      <img
                        src="/assets/img/google_ads.png"
                        style={{ height: '26px' }}
                        alt="tagturbo"
                      />
                      <p className="text-18 text-semibold mx-1 mb-0">
                        Google Ads
                      </p>
                    </div>
                    <p className="text-14 mt-3 text-grey">
                      With the integration, you can easily pass data from Tag
                      Turbo to Google Ads, allowing you to leverage <br /> the
                      attribution data to uncover in-depth insights into how
                      users interact with your website in Google Ads.
                    </p>

                    {/* {emailGGAds ? ( */}
                    <Fragment>
                      {/* <div className="my-4 text-14">
                        <span className="email-status">
                          <MdCheckCircle className="text-18 mx-1 text-success" />
                          Connected with {emailGGAds}
                        </span>
                      </div> */}
                      <p className="text-semibold mt-3">
                        Google Ads Conversion ID *
                      </p>
                      {channels['google_ads'] === 'active' ||
                      (channels['google_ads'] === 'Permission Denied' &&
                        emailGGAds) ? (
                        <input
                          type="text"
                          className="form-control  w-25 "
                          id="Name"
                          value={'AW - ' + conversionId}
                          disabled
                        />
                      ) : (
                        <Fragment>
                          <span className="fixed-position text-gray">
                            AW -{' '}
                          </span>
                          <input
                            className="form-control w-25 ph-gray fixed-conversion-id"
                            value={conversionId}
                            placeholder="XXXXXXXXX"
                            onChange={(e) => handleInputChange(e.target.value)}
                            disabled={activeWorkspaceRole !== 'Admin'}
                            maxLength={11}
                          />
                        </Fragment>
                      )}
                      {activeWorkspaceRole === 'Admin' && (
                        <div>
                          {channels['google_ads'] === 'active' ||
                          channels['google_ads'] === 'Permission Denied' ? (
                            <button
                              type="button"
                              className="btn w-lg btn-danger waves-effect waves-light mt-3"
                              style={{ padding: '8px 14px', width: '140px' }}
                              onClick={() =>
                                isPlanDetail.google_ads_integration
                                  ? setOpenPopupConfirmUnsyncGGAds(true)
                                  : setOpenPopupQuotaLimitReached(true)
                              }
                            >
                              Unsync
                            </button>
                          ) : (
                            <Fragment>
                              <button
                                type="button"
                                className="btn w-lg btn-primary waves-effect waves-light mt-3"
                                style={{
                                  padding: '8px 14px',
                                  width: '140px',
                                }}
                                onClick={() => saveIntegrationsGGAds()}
                                disabled={
                                  !conversionId ||
                                  conversionId.length < 9 ||
                                  isLoading
                                }
                              >
                                {isLoading && (
                                  <span
                                    className="spinner-border flex-shrink-0 mx-2"
                                    role="status"
                                  ></span>
                                )}
                                Sync
                              </button>
                            </Fragment>
                          )}
                        </div>
                      )}{' '}
                    </Fragment>
                  </div>
                </div>
              </div>
            </TagBox>
          </Modal>

          {/* Popup Integrations meta Ads Setting */}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupMetaAdsSetting}
            contentLabel="modal"
            style={customStyles}
            onRequestClose={() => setOpenPopupMetaAdsSetting(false)}
            className={`modal-box animate  one ${
              openPopupMetaAdsSetting ? 'fadeInRight' : 'fadeOutRight'
            }`}
          >
            <TagBox>
              <div className="modal-dialog modal-md p-3">
                <div className="modal-content p-1">
                  <div className="modal-header">
                    <div>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => (
                          setOpenPopupMetaAdsSetting(false), setConversionId('')
                        )}
                      ></button>
                      <span className="mx-3 text-20 text-semibold">
                        Meta Ads Integration
                      </span>
                    </div>
                    <div>
                      {channels['meta_pixel'] === 'active' ? (
                        <span className="badge badge-green">
                          <RiCheckboxCircleFill /> Synced
                        </span>
                      ) : channels['meta_pixel'] === 'inactive' ? (
                        ''
                      ) : (
                        <span className="badge badge-md-Yellow">
                          <MdWarning /> Integration Expired
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="modal-body border p-3 mt-3 rounded">
                    <div className="d-flex">
                      <img
                        src="/assets/img/meta_pixel.png"
                        style={{ height: '26px' }}
                      />
                      <p className="text-18 text-semibold mx-1 mb-0">
                        Meta Ads
                      </p>
                    </div>
                    <p className="text-14 mt-3 text-grey">
                      With the integration, you can easily pass data from Tag
                      Turbo to Meta Ads, allowing you to leverage the
                      attribution
                      <br />
                      data to uncover in-depth insights into how users interact
                      with your website in Meta Ads.
                    </p>

                    <Fragment>
                      <p className="text-semibold mt-3 text-14 mb-0">
                        Dataset ID (Meta Pixel ID)*
                      </p>
                      <p className="text-14 mt-2 text-grey">
                        When you successfully integrated with Meta Ads, PageView
                        event will be automatically tracked and sent whenever
                        <br />
                        someone visits your website where Tag Turbo tracking
                        code is installed.
                      </p>
                      {channels['meta_pixel'] === 'active' ||
                      (channels['meta_pixel'] === 'Permission Denied' &&
                        metaID) ? (
                        <input
                          type="text"
                          className="form-control  w-25 "
                          id="metaID"
                          value={metaID}
                          disabled
                        />
                      ) : (
                        <Fragment>
                          <input
                            className="form-control w-25 ph-gray"
                            value={metaID}
                            placeholder="XXXXXXXXX"
                            onChange={(e) =>
                              handleInputMetaChange(e.target.value)
                            }
                            disabled={activeWorkspaceRole !== 'Admin'}
                            maxLength="16"
                          />
                        </Fragment>
                      )}
                      {activeWorkspaceRole === 'Admin' && (
                        <div>
                          {channels['meta_pixel'] === 'active' ||
                          channels['meta_pixel'] === 'Permission Denied' ? (
                            <button
                              type="button"
                              className="btn w-lg btn-danger waves-effect waves-light mt-3"
                              style={{ padding: '8px 14px', width: '140px' }}
                              onClick={() =>
                                isPlanDetail.meta_integration
                                  ? setOpenPopupConfirmUnsyncMetaAds(true)
                                  : setOpenPopupQuotaLimitReached(true)
                              }
                            >
                              Unsync
                            </button>
                          ) : (
                            <Fragment>
                              <button
                                type="button"
                                className="btn w-lg btn-primary waves-effect waves-light mt-3"
                                style={{
                                  padding: '8px 14px',
                                  width: '140px',
                                }}
                                onClick={() => saveIntegrationsMetaAds()}
                                disabled={
                                  metaID && metaID.length > 0
                                    ? isLoading || false
                                    : true
                                }
                              >
                                {isLoading && (
                                  <span
                                    className="spinner-border flex-shrink-0 mx-2"
                                    role="status"
                                  ></span>
                                )}
                                Sync
                              </button>
                            </Fragment>
                          )}
                        </div>
                      )}{' '}
                    </Fragment>
                    {/* ) : (
                      <button
                        onClick={() => authGGAds()}
                        type="button"
                        className="btn badge-blue text-14 text-semibold px-2"
                      >
                        <img
                          src="/assets/img/logogoogle.svg"
                          style={{ height: "26px", marginRight: "6px" }}
                        />{" "}
                        Sign in with Google
                      </button>
                    )} */}
                  </div>
                </div>
              </div>
            </TagBox>
          </Modal>

          {/* Popup Integrations Cookieplus Setting */}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupCookiePlusSetting}
            contentLabel="modal"
            style={customStyles}
            onRequestClose={() => setOpenPopupCookiePlusSetting(false)}
            className={`modal-box animate  one ${
              openPopupCookiePlusSetting ? 'fadeInRight' : 'fadeOutRight'
            }`}
          >
            <TagBox>
              <div className="modal-dialog modal-md p-3">
                <div className="modal-content p-1">
                  <div className="modal-header">
                    <div>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => (
                          setOpenPopupCookiePlusSetting(false),
                          setCookiePlusId(''),
                          setConsentMode('basic')
                        )}
                      ></button>
                      <span className="mx-3 text-20 text-semibold">
                        CookiePlus Integration
                      </span>
                    </div>
                    <div>
                      {channels['cookieplus'] === 'active' ? (
                        <span className="badge badge-green">
                          <RiCheckboxCircleFill /> Synced
                        </span>
                      ) : channels['cookieplus'] === 'inactive' ? (
                        ''
                      ) : (
                        <span className="badge badge-md-Yellow">
                          <MdWarning /> Integration Expired
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="modal-body border p-3 mt-3 rounded">
                    <div className="d-flex">
                      <img
                        src="/assets/img/cookieplus.png"
                        style={{ height: '26px' }}
                      />{' '}
                      <p className="text-18 text-semibold mx-1 mb-0">
                        CookiePlus
                      </p>
                    </div>
                    <p className="text-14 mt-3 text-grey">
                      Solution for PDPA compliance, integrate with CookiePlus to
                      display cookie banner on your website and
                      <br />
                      easily configure your Google Consent Mode v2 settings.
                    </p>
                    <Fragment>
                      <p className="text-semibold mt-3 text-14 mb-0">
                        CookiePlus Consent ID
                      </p>
                      {channels['cookieplus'] === 'active' ||
                      (channels['cookieplus'] === 'Permission Denied' &&
                        consentModeId) ? (
                        <input
                          type="text"
                          className="form-control  w-25 mt-2"
                          id="cookiePlusID"
                          value={consentModeId}
                          disabled
                        />
                      ) : (
                        <Fragment>
                          <input
                            className="form-control w-25 ph-gray mt-2"
                            value={consentModeId}
                            onChange={(e) => setConsentModeId(e.target.value)}
                            disabled={activeWorkspaceRole !== 'Admin'}
                            maxLength="36"
                          />
                        </Fragment>
                      )}
                      <p className="text-semibold mt-4 text-14 mb-0">
                        <input
                          className="form-check-input mr-2"
                          type="checkbox"
                          checked={enableConsentMode}
                          onChange={() =>
                            setEnableConsentMode(!enableConsentMode)
                          }
                          disabled={isDisableInput}
                        />{' '}
                        Enable Google Consent Mode
                      </p>
                      <div className="form-check mt-2">
                        <label
                          className="form-check-label text-14 text-grey"
                          htmlFor="flexCheckChecked"
                        >
                          If enabled, Google Consent Mode infos are pushed in to
                          the dataLayer. As soon as it is enabled all <br />
                          categories will be pushed with ‘denied’, if no option
                          is checked.
                        </label>
                      </div>
                      {enableConsentMode && (
                        <div className="mt-4">
                          <RadioBox>
                            <div
                              className={`border p-3 w-75 rounded ${consentMode === 'basic' ? 'activeBorder' : ''}`}
                            >
                              <div>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="ConsentModeBasic"
                                  id="ConsentModeBasic"
                                  value="basic"
                                  onClick={handleConsentMode}
                                  checked={consentMode === 'basic'}
                                  disabled={isDisableInput}
                                />
                                <label className="form-check-label mx-3">
                                  <p className="text-14 text-semibold mb-0">
                                    Basic consent mode <br />
                                    <span className="text-14 text-grey text-regular ">
                                      <p className="mt-2 mb-0">
                                        Basic Consent Mode offers a simplified
                                        consent management solution for website
                                        owners. <br /> It automatically blocks
                                        Tags until users grant consent via
                                        CookiePlus banner.
                                      </p>
                                    </span>
                                  </p>
                                </label>
                              </div>
                            </div>
                            <div
                              className={`border p-3 w-75 rounded mt-3 ${consentMode === 'advance' ? 'activeBorder' : ''}`}
                            >
                              <div>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="ConsentModeAdvanced"
                                  id="ConsentModeAdvanced"
                                  value="advance"
                                  onClick={handleConsentMode}
                                  checked={consentMode === 'advance'}
                                  disabled={isDisableInput}
                                />
                                <label className="form-check-label mx-3">
                                  <p className="text-14 text-semibold mb-0">
                                    Advanced consent mode <br />
                                    <span className="text-14 text-grey text-regular">
                                      <p className="mt-2 mb-0">
                                        Advanced Consent Mode offers a more
                                        refined approach to consent management.
                                        Tags load when a <br />
                                        user opens the website and only
                                        non-personal data is processed by
                                        Google. Tags will send the full <br />
                                        measurement data when a user grants
                                        consent. With Advanced Consent Mode,
                                        website owners could <br />
                                        collect more information from
                                        unconsented users.
                                      </p>
                                    </span>
                                  </p>
                                </label>
                              </div>
                            </div>
                          </RadioBox>
                          <hr />
                        </div>
                      )}
                      {enableConsentMode && (
                        <div className="cm-box animate-5 heightUp">
                          <p className="text-semibold mt-4 text-14 mb-0">
                            Consent Mode
                          </p>
                          <p className="text-14 text-grey">
                            Define when a Consent Mode category shall be
                            granted. If nothing is checked for a category, this
                            category will default to 'denied'. If you need a
                            Consent Mode category to be granted per default,
                            check all toggles for this particular category.
                          </p>
                          <div className="border p-3 mt-3 rounded">
                            <table
                              className="tb-consent"
                              style={{ width: '60%' }}
                            >
                              <thead>
                                <tr>
                                  <th className="text-14">
                                    Consent Mode category
                                  </th>
                                  <th className="text-14 text-center">
                                    Default as:
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="fix-tr">analytics_storage</td>
                                  <td className="text-center">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        className={`form-check-input `}
                                        checked={cmpAnalyticStorage}
                                        onChange={(e) =>
                                          setCmpAnalyticStorage(
                                            e.target.checked,
                                          )
                                        }
                                        disabled={isDisableInput}
                                      />
                                      <span
                                        className={`
                                        ${cmpAnalyticStorage ? '' : 'force-disable'}
                                        ${
                                          isDisableInput
                                            ? ' slider-disabled slider cursor-no-drop'
                                            : 'slider-md slider'
                                        } slider round`}
                                      ></span>
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fix-tr">ad_storage</td>
                                  <td className="text-center">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        className={`form-check-input `}
                                        checked={cmpAdstorage}
                                        onChange={(e) =>
                                          handleChangeAdStorage(e)
                                        }
                                        disabled={isDisableInput}
                                      />
                                      <span
                                        className={`${cmpAdstorage ? '' : 'force-disable'}
                                          ${
                                            isDisableInput
                                              ? ' slider-disabled slider cursor-no-drop'
                                              : 'slider-md slider'
                                          } slider round`}
                                      ></span>
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fix-tr">ad_personalization</td>
                                  <td className="text-center">
                                    <div className="switch">
                                      <input
                                        type="checkbox"
                                        className={`form-check-input `}
                                        checked={cmpAdstorage}
                                        disabled
                                      />
                                      <span
                                        className={`${cmpAdstorage ? '' : 'slider-disabled-fix'} slider-disabled slider cursor-no-drop slider round`}
                                      ></span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fix-tr">ad_user_data</td>
                                  <td className="text-center">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        className={`form-check-input `}
                                        checked={cmpAdstorage}
                                        disabled
                                      />
                                      <span
                                        className={`${cmpAdstorage ? '' : 'slider-disabled-fix'} slider-disabled slider cursor-no-drop slider round`}
                                      ></span>
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fix-tr">
                                    functionality_storage
                                  </td>
                                  <td className="text-center">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        className={`form-check-input `}
                                        checked={cmpFunctionStorage}
                                        onChange={(e) =>
                                          setCmpFunctionStorage(
                                            e.target.checked,
                                          )
                                        }
                                        disabled={isDisableInput}
                                      />
                                      <span
                                        className={`${cmpFunctionStorage ? '' : 'force-disable'} ${
                                          isDisableInput
                                            ? ' slider-disabled slider cursor-no-drop'
                                            : 'slider-md slider'
                                        } slider round`}
                                      ></span>
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fix-tr">
                                    personalization_storage
                                  </td>
                                  <td className="text-center">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        className={`form-check-input `}
                                        checked={cmpPersonalStorage}
                                        onChange={(e) =>
                                          setCmpPersonalStorage(
                                            e.target.checked,
                                          )
                                        }
                                        disabled={isDisableInput}
                                      />
                                      <span
                                        className={`${cmpPersonalStorage ? '' : 'force-disable'} ${
                                          isDisableInput
                                            ? ' slider-disabled slider cursor-no-drop'
                                            : 'slider-md slider'
                                        } slider round`}
                                      ></span>
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fix-tr">security_storage</td>
                                  <td className="text-center">
                                    <div className="switch">
                                      <input
                                        type="checkbox"
                                        className={`form-check-input `}
                                        checked
                                        disabled
                                      />
                                      <span
                                        className={`slider-disabled slider cursor-no-drop slider round`}
                                      ></span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p className="text-semibold mt-4 text-14 mb-0">
                            ads_data_redaction
                          </p>
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={cmpAdsDataStorage}
                              onChange={() =>
                                setCmpAdsDataStorage(!cmpAdsDataStorage)
                              }
                              disabled={isDisableInput}
                            />
                            <label
                              className="form-check-label text-14 text-grey"
                              htmlFor="flexCheckChecked"
                            >
                              When ads_data_redaction is true and ad_storage is
                              denied, ad click identifiers sent in network
                              requests by Google Ads and Floodlight tags will be
                              redacted. Network requests will also be sent
                              through a cookieless domain.
                            </label>
                          </div>
                          <p className="text-semibold mt-4 text-14 mb-0">
                            url_passthrough
                          </p>
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={cmpUrlPassthroughStorage}
                              onChange={() =>
                                setCmpUrlPassthroughStorage(
                                  !cmpUrlPassthroughStorage,
                                )
                              }
                              disabled={isDisableInput}
                            />
                            <label
                              className="form-check-label text-14 text-grey"
                              htmlFor="flexCheckChecked"
                            >
                              gtag.js will check the URL for advertising
                              parameters (gclid, dclid, gclsrc, _gl), and adds
                              them to all internal link URLs the user might be
                              clicking through.
                            </label>
                          </div>
                          <p className="text-semibold mt-4 text-14 mb-0">
                            wait_for_update in ms
                          </p>
                          <input
                            className="form-control w-25 ph-gray mt-2"
                            value={cmpWaitForUpdate}
                            onChange={(e) =>
                              setCmpWaitForUpdate(
                                /[0-9]/.test(e.target.value)
                                  ? e.target.value
                                  : '',
                              )
                            }
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault()
                              }
                            }}
                            disabled={isDisableInput}
                          />
                          <p className="text-14 text-grey mt-2">
                            With beautiful cookie banner integration you usually
                            can leave it at 500ms, for more infos see
                            {` `}
                            <a
                              href="https://developers.google.com/tag-platform/security/guides/consent?hl=en&consentmode=advanced#tracking_consent_state"
                              target="_blank"
                            >
                              google’s documentation.
                            </a>
                          </p>
                        </div>
                      )}
                      {activeWorkspaceRole === 'Admin' && (
                        <div>
                          {channels['cookieplus'] === 'active' ||
                          channels['cookieplus'] === 'Permission Denied' ? (
                            <button
                              type="button"
                              className="btn w-lg btn-danger waves-effect waves-light mt-3"
                              style={{ padding: '8px 14px', width: '140px' }}
                              onClick={() => setOpenPopupConfirmUnsyncCMP(true)}
                            >
                              Unsync
                            </button>
                          ) : (
                            <Fragment>
                              <button
                                type="button"
                                className="btn w-lg btn-primary waves-effect waves-light mt-3"
                                style={{
                                  padding: '8px 14px',
                                  width: '140px',
                                }}
                                onClick={() =>
                                  saveIntegrationsCMP('cookieplus')
                                }
                                disabled={
                                  consentModeId && consentModeId.length > 0
                                    ? isLoading || false
                                    : true
                                }
                              >
                                {isLoading && (
                                  <span
                                    className="spinner-border flex-shrink-0 mx-2"
                                    role="status"
                                  ></span>
                                )}
                                Sync
                              </button>
                            </Fragment>
                          )}
                        </div>
                      )}{' '}
                    </Fragment>
                  </div>
                </div>
              </div>
            </TagBox>
          </Modal>

          {/* Popup Integrations Consent mode Setting */}
          {/* <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupConsentModeSetting}
            contentLabel="modal"
            style={customStyles}
            onRequestClose={() => setOpenPopupConsentModeSetting(false)}
            className={`modal-box animate  one ${
              openPopupConsentModeSetting ? 'fadeInRight' : 'fadeOutRight'
            }`}
          >
            <TagBox>
              <div className="modal-dialog modal-md p-3">
                <div className="modal-content p-1">
                  <div className="modal-header">
                    <div>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => (
                          setOpenPopupConsentModeSetting(false),
                          setConsentModeId('')
                        )}
                      ></button>
                      <span className="mx-3 text-20 text-semibold">
                        Cookie Consent Integration
                      </span>
                    </div>
                    <div>
                      {channels['cmp'] === 'active' ? (
                        <span className="badge badge-green">
                          <RiCheckboxCircleFill /> Synced
                        </span>
                      ) : channels['cmp'] === 'inactive' ? (
                        ''
                      ) : (
                        <span className="badge badge-md-Yellow">
                          <MdWarning /> Integration Expired
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="modal-body border p-3 mt-3 rounded">
                    <div className="d-flex">
                      <img
                        src="/assets/img/consentmode.png"
                        style={{ height: '26px' }}
                      />{' '}
                      <p className="text-18 text-semibold mx-1 mb-0">
                        Cookie Consent Management Platform
                      </p>
                    </div>
                    <p className="text-14 mt-3 text-grey">
                      Solution for PDPA compliance, integrate with CookiePlus to
                      display cookie banner on your website and
                      <br />
                      easily configure your Google Consent Mode v2 settings.
                    </p>

                    <Fragment>
                      <p className="text-semibold mt-3 text-14 mb-0">
                        Which Consent Management Platform do you use?
                      </p>
                      {channels['cmp'] === 'active' ||
                      (channels['cmp'] === 'Permission Denied' &&
                        consentModeId) ? (
                        <input
                          type="text"
                          className="form-control  w-25 mt-2"
                          value={consentModeId}
                          disabled
                        />
                      ) : (
                        <Fragment>
                          <input
                            className="form-control w-25 ph-gray mt-2"
                            value={consentModeId}
                            onChange={(e) => setConsentModeId(e.target.value)}
                            disabled={activeWorkspaceRole !== 'Admin'}
                            maxLength="36"
                          />
                        </Fragment>
                      )}
                      <p className="text-semibold mt-4 text-14 mb-0">
                        Enable Google Consent Mode
                      </p>
                      <div className="form-check mt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={enableConsentMode}
                          onChange={() =>
                            setEnableConsentMode(!enableConsentMode)
                          }
                          disabled={isDisableInput}
                        />
                        <label
                          className="form-check-label text-14 text-grey"
                          htmlFor="flexCheckChecked"
                        >
                          If enabled, Google Consent Mode infos are pushed in to
                          the dataLayer. As soon as it is enabled all <br />
                          categories will be pushed with ‘denied’, if no option
                          is checked.
                        </label>
                      </div>
                      {enableConsentMode && (
                        <div className="cm-box animate-5 heightUp">
                          <p className="text-semibold mt-4 text-14 mb-0">
                            Consent Mode
                          </p>
                          <p className="text-14 text-grey">
                            Define when a Consent Mode category shall be
                            granted. If nothing is checked for a category, this
                            category will default to 'denied'. If you need a
                            Consent Mode category to be granted per default,
                            check all toggles for this particular category.
                          </p>

                          <p className="text-semibold mt-4 text-14 mb-0">
                            DataLayer Event Name
                          </p>
                          <input
                            className="form-control w-25 ph-gray mt-2"
                            value={cmpDataLayerEvent}
                            onChange={(e) =>
                              setCmpDataLayerEvent(e.target.value)
                            }
                            disabled={activeWorkspaceRole !== 'Admin'}
                          />
                          <p className="text-semibold mt-4 text-14 mb-0">
                            DataLayer Consent Variable Name
                          </p>
                          <input
                            className="form-control w-25 ph-gray mt-2"
                            value={cmpDataLayerVariable}
                            onChange={(e) =>
                              setCmpDataLayerVariable(e.target.value)
                            }
                            disabled={activeWorkspaceRole !== 'Admin'}
                          />

                          <div className="border p-3 mt-4 rounded">
                            <table
                              className="tb-consent"
                              style={{ width: '60%' }}
                            >
                              <thead>
                                <tr>
                                  <th className="text-14">
                                    Consent Mode category
                                  </th>
                                  <th className="text-14 text-center">
                                    Default as:
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="fix-tr">
                                    analytics_storage <br />
                                    <input
                                      className="form-control w-75 mt-1"
                                      value={analyticName}
                                      onChange={(e) =>
                                        setAnalyticName(e.target.value)
                                      }
                                      disabled={isDisableInput}
                                    />
                                  </td>
                                  <td className="text-center ">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        className={`form-check-input `}
                                        checked={cmpAnalyticStorage}
                                        onChange={(e) =>
                                          setCmpAnalyticStorage(
                                            e.target.checked,
                                          )
                                        }
                                        disabled={isDisableInput}
                                      />
                                      <span
                                        className={`${cmpAnalyticStorage ? '' : 'force-disable'} ${
                                          isDisableInput
                                            ? ' slider-disabled slider cursor-no-drop'
                                            : 'slider-md slider'
                                        } slider round`}
                                      ></span>
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fix-tr">
                                    ad_storage <br />
                                    <input
                                      className="form-control w-75 mt-1"
                                      value={adStorageName}
                                      onChange={(e) =>
                                        setAdStorageName(e.target.value)
                                      }
                                      disabled={isDisableInput}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        className={`form-check-input `}
                                        checked={cmpAdstorage}
                                        onChange={(e) =>
                                          handleChangeAdStorage(e)
                                        }
                                        disabled={isDisableInput}
                                      />
                                      <span
                                        className={`${cmpAdstorage ? '' : 'force-disable'} ${
                                          isDisableInput
                                            ? ' slider-disabled slider cursor-no-drop'
                                            : 'slider-md slider'
                                        } slider round`}
                                      ></span>
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fix-tr">ad_personalization</td>
                                  <td className="text-center">
                                    <div className="switch">
                                      <input
                                        type="checkbox"
                                        className={`form-check-input `}
                                        checked={cmpAdstorage}
                                        disabled
                                      />
                                      <span
                                        className={`${cmpAdstorage ? '' : 'slider-disabled-fix'} slider-disabled slider cursor-no-drop slider round`}
                                      ></span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fix-tr">ad_user_data</td>
                                  <td className="text-center">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        className={`form-check-input `}
                                        checked={cmpAdstorage}
                                        disabled
                                      />
                                      <span
                                        className={`${cmpAdstorage ? '' : 'slider-disabled-fix'} slider-disabled slider cursor-no-drop slider round`}
                                      ></span>
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fix-tr">
                                    functionality_storage <br />
                                    <input
                                      className="form-control w-75 mt-1"
                                      value={functionalName}
                                      onChange={(e) =>
                                        setFunctionalName(e.target.value)
                                      }
                                      disabled={isDisableInput}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        className={`form-check-input `}
                                        checked={cmpFunctionStorage}
                                        onChange={(e) =>
                                          setCmpFunctionStorage(
                                            e.target.checked,
                                          )
                                        }
                                        disabled={isDisableInput}
                                      />
                                      <span
                                        className={`${cmpFunctionStorage ? '' : 'force-disable'} ${
                                          isDisableInput
                                            ? ' slider-disabled slider cursor-no-drop'
                                            : 'slider-md slider'
                                        } slider round`}
                                      ></span>
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fix-tr">
                                    personalization_storage <br />
                                    <input
                                      className="form-control w-75 mt-1"
                                      value={personalName}
                                      onChange={(e) =>
                                        setPersonalName(e.target.value)
                                      }
                                      disabled={isDisableInput}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        className={`form-check-input `}
                                        checked={cmpPersonalStorage}
                                        onChange={(e) =>
                                          setCmpPersonalStorage(
                                            e.target.checked,
                                          )
                                        }
                                        disabled={isDisableInput}
                                      />
                                      <span
                                        className={`${cmpPersonalStorage ? '' : 'force-disable'} ${
                                          isDisableInput
                                            ? ' slider-disabled slider cursor-no-drop'
                                            : 'slider-md slider'
                                        } slider round`}
                                      ></span>
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fix-tr">security_storage</td>
                                  <td className="text-center">
                                    <div className="switch">
                                      <input
                                        type="checkbox"
                                        className={`form-check-input `}
                                        checked
                                        disabled
                                      />
                                      <span
                                        className={`slider-disabled slider cursor-no-drop slider round`}
                                      ></span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p className="text-semibold mt-4 text-14 mb-0">
                            ads_data_radaction
                          </p>
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={cmpAdsDataStorage}
                              onChange={() =>
                                setCmpAdsDataStorage(!cmpAdsDataStorage)
                              }
                              disabled={isDisableInput}
                            />
                            <label
                              className="form-check-label text-14 text-grey"
                              htmlFor="flexCheckChecked"
                            >
                              When ads_data_redaction is true and ad_storage is
                              denied, ad click identifiers sent in network
                              requests by Google Ads and Floodlight tags will be
                              redacted. Network requests will also be sent
                              through a cookieless domain.
                            </label>
                          </div>
                          <p className="text-semibold mt-4 text-14 mb-0">
                            url_passthrough
                          </p>
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={cmpUrlPassthroughStorage}
                              onChange={() =>
                                setCmpUrlPassthroughStorage(
                                  !cmpUrlPassthroughStorage,
                                )
                              }
                              disabled={isDisableInput}
                            />
                            <label
                              className="form-check-label text-14 text-grey"
                              htmlFor="flexCheckChecked"
                            >
                              gtag.js will check the URL for advertising
                              parameters (gclid, dclid, gclsrc, _gl), and adds
                              them to all internal link URLs the user might be
                              clicking through.
                            </label>
                          </div>
                          <p className="text-semibold mt-4 text-14 mb-0">
                            wait_for_update in ms
                          </p>
                          <input
                            className="form-control w-25 ph-gray mt-2"
                            value={cmpWaitForUpdate}
                            onChange={(e) =>
                              setCmpWaitForUpdate(
                                /[0-9]/.test(e.target.value)
                                  ? e.target.value
                                  : '',
                              )
                            }
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault()
                              }
                            }}
                            disabled={isDisableInput}
                          />
                          <p className="text-14 text-grey mt-2">
                            With beautiful cookie banner integration you usually
                            can leave it at 500ms, for more infos see
                            {` `}
                            <a
                              href="https://developers.google.com/tag-platform/security/guides/consent?hl=en&consentmode=advanced#tracking_consent_state"
                              target="_blank"
                            >
                              google’s documentation.
                            </a>
                          </p>
                        </div>
                      )}
                      {activeWorkspaceRole === 'Admin' && (
                        <div>
                          {channels['cmp'] === 'active' ||
                          channels['cmp'] === 'Permission Denied' ? (
                            <button
                              type="button"
                              className="btn w-lg btn-danger waves-effect waves-light mt-3"
                              style={{ padding: '8px 14px', width: '140px' }}
                              onClick={() => setOpenPopupConfirmUnsyncCMP(true)}
                            >
                              Unsync
                            </button>
                          ) : (
                            <Fragment>
                              <button
                                type="button"
                                className="btn w-lg btn-primary waves-effect waves-light mt-3"
                                style={{
                                  padding: '8px 14px',
                                  width: '140px',
                                }}
                                onClick={() => saveIntegrationsCMP('cmp')}
                                disabled={
                                  consentModeId &&
                                  consentModeId.length > 0 &&
                                  cmpDataLayerEvent &&
                                  cmpDataLayerVariable
                                    ? false
                                    : true
                                }
                              >
                                Sync
                              </button>
                            </Fragment>
                          )}
                        </div>
                      )}{' '}
                    </Fragment>
                  </div>
                </div>
              </div>
            </TagBox>
          </Modal> */}

          {/* Popup Integrations tiktok Ads Setting */}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupTiktokAdsSetting}
            contentLabel="modal"
            style={customStyles}
            onRequestClose={() => openPopupTiktokAdsSetting(false)}
            className={`modal-box animate  one ${
              openPopupTiktokAdsSetting ? 'fadeInRight' : 'fadeOutRight'
            }`}
          >
            <TagBox>
              <div className="modal-dialog modal-md p-3">
                <div className="modal-content p-1">
                  <div className="modal-header">
                    <div>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => (
                          setOpenPopupTiktokAdsSetting(false), setTiktokId('')
                        )}
                      ></button>
                      <span className="mx-3 text-20 text-semibold">
                        TikTok Ads Integration
                      </span>
                    </div>
                    <div>
                      {channels['tiktok'] === 'active' ? (
                        <span className="badge badge-green">
                          <RiCheckboxCircleFill /> Synced
                        </span>
                      ) : channels['tiktok'] === 'inactive' ? (
                        ''
                      ) : (
                        <span className="badge badge-md-Yellow">
                          <MdWarning /> Integration Expired
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="modal-body border p-3 mt-3 rounded">
                    <div className="d-flex">
                      <img
                        src="/assets/img/tiktok.png"
                        style={{ height: '26px' }}
                      />
                      <p className="text-18 text-semibold mx-1 mb-0">
                        TikTok Ads
                      </p>
                    </div>
                    <p className="text-14 mt-3 text-grey">
                      With the integration, you can easily pass data from Tag
                      Turbo to TikTok, allowing you to leverage the attribution
                      data to
                      <br /> uncover in-depth insights into how users interact
                      with your website.
                    </p>

                    <Fragment>
                      <p className="text-semibold mt-3 text-14 mb-0">
                        TikTok Pixel ID*
                      </p>
                      <p className="text-14 mt-2 text-grey">
                        When you successfully integrated with TikTok, Pageview
                        event will be automatically tracked and sent whenever
                        <br /> someone visits your website where Tag Turbo
                        tracking code is installed.
                      </p>
                      {channels['tiktok'] === 'active' ||
                      (channels['tiktok'] === 'Permission Denied' &&
                        tiktokId) ? (
                        <input
                          type="text"
                          className="form-control  w-25 "
                          id="tiktokId"
                          value={tiktokId}
                          disabled
                        />
                      ) : (
                        <Fragment>
                          <input
                            className="form-control w-25 ph-gray"
                            value={tiktokId}
                            placeholder="XXXXXXXXXXXXXXXX"
                            onChange={(e) => setTiktokId(e.target.value)}
                            onKeyPress={(event) => {
                              if (
                                !/[\s0-9A-Za-z;&*%!+{}@#=?$()-_+=!^]/.test(
                                  event.key,
                                )
                              ) {
                                event.preventDefault()
                              }
                            }}
                            disabled={activeWorkspaceRole !== 'Admin'}
                          />
                        </Fragment>
                      )}
                      {activeWorkspaceRole === 'Admin' && (
                        <div>
                          {channels['tiktok'] === 'active' ||
                          channels['tiktok'] === 'Permission Denied' ? (
                            <button
                              type="button"
                              className="btn w-lg btn-danger waves-effect waves-light mt-3"
                              style={{ padding: '8px 14px', width: '140px' }}
                              onClick={() =>
                                isPlanDetail.tiktok_integration
                                  ? setOpenPopupConfirmUnsyncTiktokAds(true)
                                  : setOpenPopupQuotaLimitReached(true)
                              }
                            >
                              Unsync
                            </button>
                          ) : (
                            <Fragment>
                              <button
                                type="button"
                                className="btn w-lg btn-primary waves-effect waves-light mt-3"
                                style={{
                                  padding: '8px 14px',
                                  width: '140px',
                                }}
                                onClick={() => saveIntegrationsTiktokAds()}
                                disabled={
                                  tiktokId && tiktokId.length > 0
                                    ? isLoading || false
                                    : true
                                }
                              >
                                {isLoading && (
                                  <span
                                    className="spinner-border flex-shrink-0 mx-2"
                                    role="status"
                                  ></span>
                                )}
                                Sync
                              </button>
                            </Fragment>
                          )}
                        </div>
                      )}{' '}
                    </Fragment>
                    {/* ) : (
                      <button
                        onClick={() => authGGAds()}
                        type="button"
                        className="btn badge-blue text-14 text-semibold px-2"
                      >
                        <img
                          src="/assets/img/logogoogle.svg"
                          style={{ height: "26px", marginRight: "6px" }}
                        />{" "}
                        Sign in with Google
                      </button>
                    )} */}
                  </div>
                </div>
              </div>
            </TagBox>
          </Modal>

          {/* Popup Confirm Unsync*/}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupConfirmUnsync}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupConfirmUnsync(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white"></div>
                </div>
                <div className="modal-body p-4 mt-3  text-center">
                  <img
                    className="mb-2"
                    src="/assets/img/warning.png"
                    alt="tagturbo"
                  />
                  <p className="text-20" style={{ color: '#ED3451' }}>
                    <strong>
                      Are you sure to unsync from <br /> Google Analytics 4?
                    </strong>
                  </p>
                  <p className="text-14 px-4 text-left">
                    1. Tag data will stop sending to Google Analytics 4 <br />
                    2. Dashboard will not be displayed
                  </p>
                  <p className="text-14 " style={{ color: '#ED3451' }}>
                    <b>Note:</b> This action cannot be undone, <br />
                    you have to sync again{' '}
                  </p>
                </div>
                <div className="modal-footer justify-content-center px-4 pb-3 mb-4">
                  <div
                    className="btn "
                    style={{ width: '140px', marginRight: '15px' }}
                    onClick={() => setOpenPopupConfirmUnsync(false)}
                  >
                    Cancel
                  </div>
                  <button
                    className="btn btn-danger"
                    style={{ width: '140px' }}
                    onClick={() => unsyncGa()}
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <span
                        className="spinner-border flex-shrink-0 mx-2"
                        role="status"
                      ></span>
                    )}
                    Unsync
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Popup Confirm Unsync GG Ads*/}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupConfirmUnsyncGGAds}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupConfirmUnsyncGGAds(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white"></div>
                </div>
                <div className="modal-body p-4 mt-3  text-center">
                  <img
                    className="mb-2"
                    src="/assets/img/warning.png"
                    alt="tagturbo"
                  />
                  <p className="text-20" style={{ color: '#ED3451' }}>
                    <strong>Are you sure to unsync from Google Ads?</strong>
                  </p>
                  <p className="text-14 px-4">
                    Tag data will stop sending to Google Ads
                  </p>
                  <p className="text-14 " style={{ color: '#ED3451' }}>
                    <b>Note:</b> This action cannot be undone, <br />
                    you have to sync again{' '}
                  </p>
                </div>
                <div className="modal-footer justify-content-center px-4 pb-3 mb-4">
                  <div
                    className="btn "
                    style={{ width: '140px', marginRight: '15px' }}
                    onClick={() => setOpenPopupConfirmUnsyncGGAds(false)}
                  >
                    Cancel
                  </div>
                  <button
                    className="btn btn-danger"
                    style={{ width: '140px' }}
                    onClick={() => unsyncGoogleAds()}
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <span
                        className="spinner-border flex-shrink-0 mx-2"
                        role="status"
                      ></span>
                    )}
                    Unsync
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Popup Confirm Unsync GG Ads*/}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupConfirmUnsyncGGAds}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupConfirmUnsyncGGAds(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white"></div>
                </div>
                <div className="modal-body p-4 mt-3  text-center">
                  <img className="mb-2" src="/assets/img/warning.png" />
                  <p className="text-20" style={{ color: '#ED3451' }}>
                    <strong>Are you sure to unsync from Google Ads?</strong>
                  </p>
                  <p className="text-14 px-4">
                    Tag data will stop sending to Google Ads
                  </p>
                  <p className="text-14 " style={{ color: '#ED3451' }}>
                    <b>Note:</b> This action cannot be undone, <br />
                    you have to sync again{' '}
                  </p>
                </div>
                <div className="modal-footer justify-content-center px-4 pb-3 mb-4">
                  <div
                    className="btn "
                    style={{ width: '140px', marginRight: '15px' }}
                    onClick={() => setOpenPopupConfirmUnsyncGGAds(false)}
                  >
                    Cancel
                  </div>
                  <button
                    className="btn btn-danger"
                    style={{ width: '140px' }}
                    onClick={() => unsyncGoogleAds()}
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <span
                        className="spinner-border flex-shrink-0 mx-2"
                        role="status"
                      ></span>
                    )}
                    Unsync
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Popup Confirm Unsync meta Ads*/}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupConfirmUnsyncMetaAds}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupConfirmUnsyncMetaAds(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white"></div>
                </div>
                <div className="modal-body p-4 mt-3  text-center">
                  <img className="mb-2" src="/assets/img/warning.png" />
                  <p className="text-20" style={{ color: '#ED3451' }}>
                    <strong>Are you sure to unsync from Meta Ads?</strong>
                  </p>
                  <p className="text-14 px-4">
                    Tag data will stop sending to Meta Ads
                  </p>
                  <p className="text-14 " style={{ color: '#ED3451' }}>
                    <b>Note:</b> This action cannot be undone, <br />
                    you have to sync again{' '}
                  </p>
                </div>
                <div className="modal-footer justify-content-center px-4 pb-3 mb-4">
                  <div
                    className="btn "
                    style={{ width: '140px', marginRight: '15px' }}
                    onClick={() => setOpenPopupConfirmUnsyncMetaAds(false)}
                  >
                    Cancel
                  </div>
                  <button
                    className="btn btn-danger"
                    style={{ width: '140px' }}
                    onClick={() => unsyncMetaAds()}
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <span
                        className="spinner-border flex-shrink-0 mx-2"
                        role="status"
                      ></span>
                    )}
                    Unsync
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Popup Confirm Unsync TikTok Ads*/}

          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupConfirmUnsyncTiktokAds}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupConfirmUnsyncTiktokAds(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white"></div>
                </div>
                <div className="modal-body p-4 mt-3  text-center">
                  <img className="mb-2" src="/assets/img/warning.png" />
                  <p className="text-20" style={{ color: '#ED3451' }}>
                    <strong>Are you sure to unsync from TikTok Ads?</strong>
                  </p>
                  <p className="text-14 px-4">
                    Tag data will stop sending to TikTok Ads
                  </p>
                  <p className="text-14 " style={{ color: '#ED3451' }}>
                    <b>Note:</b> This action cannot be undone, <br />
                    you have to sync again{' '}
                  </p>
                </div>
                <div className="modal-footer justify-content-center px-4 pb-3 mb-4">
                  <div
                    className="btn "
                    style={{ width: '140px', marginRight: '15px' }}
                    onClick={() => setOpenPopupConfirmUnsyncTiktokAds(false)}
                  >
                    Cancel
                  </div>
                  <button
                    className="btn btn-danger"
                    style={{ width: '140px' }}
                    onClick={() => unsyncDataTiktokAds()}
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <span
                        className="spinner-border flex-shrink-0 mx-2"
                        role="status"
                      ></span>
                    )}
                    Unsync
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          {/* Popup Confirm Unsync Cookieplus*/}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupConfirmUnsyncCMP}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupConfirmUnsyncCMP(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white"></div>
                </div>
                <div className="modal-body p-4 mt-3  text-center">
                  <img className="mb-2" src="/assets/img/warning.png" />
                  <p className="text-20" style={{ color: '#ED3451' }}>
                    <strong>
                      Are you sure to unsync from <br /> {integrationsType}?
                    </strong>
                  </p>
                  <p className="text-14 px-4">
                    CookiePlus banner and Consent Mode v2 <br />
                    Configuration will be removed from your <br />
                    website
                  </p>
                  <p className="text-14 " style={{ color: '#ED3451' }}>
                    <b>Note:</b> This action cannot be undone, <br />
                    you have to sync again{' '}
                  </p>
                </div>
                <div className="modal-footer justify-content-center px-4 pb-3 mb-4">
                  <div
                    className="btn "
                    style={{ width: '140px', marginRight: '15px' }}
                    onClick={() => setOpenPopupConfirmUnsyncCMP(false)}
                  >
                    Cancel
                  </div>
                  <button
                    className="btn btn-danger"
                    style={{ width: '140px' }}
                    onClick={() =>
                      unsyncCMP(
                        openPopupCookiePlusSetting ? 'cookieplus' : 'cmp',
                      )
                    }
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <span
                        className="spinner-border flex-shrink-0 mx-2"
                        role="status"
                      ></span>
                    )}
                    Unsync
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          {/* Popup Retry Sync*/}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupSyncTryAgain}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupSyncTryAgain(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white">
                    <p className="py-0 mb-0 text-20"></p>
                  </div>
                </div>
                <div className="d-flex justify-content-end px-2">
                  <div>
                    <MdClose
                      className="cursor"
                      onClick={() => setOpenPopupSyncTryAgain(false)}
                    />
                  </div>
                </div>
                <div className="modal-body p-4 mt-3 text-center">
                  <img
                    className="mb-2"
                    src="/assets/img/warning.png"
                    alt="tagturbo"
                  />

                  <p className="text-20 text-danger">Error</p>
                  <p className="text-14">
                    Something went wrong. Check to see if <br /> your connection
                    is secure and try again.
                  </p>
                </div>
                <div className="modal-footer justify-content-center px-5 pb-5">
                  <div
                    className="btn cursor w-50"
                    onClick={() => setOpenPopupSyncTryAgain(false)}
                  >
                    Cancel
                  </div>
                  <div
                    className="btn btn-danger w-50"
                    onClick={() => checkRetrySync()}
                  >
                    Retry
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupUnsyncTryAgain}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupUnsyncTryAgain(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white">
                    <p className="py-0 mb-0 text-20"></p>
                  </div>
                </div>
                <div className="d-flex justify-content-end px-2">
                  <div>
                    <MdClose
                      className="cursor"
                      onClick={() => setOpenPopupUnsyncTryAgain(false)}
                    />
                  </div>
                </div>
                <div className="modal-body p-4 mt-3 text-center">
                  <img
                    className="mb-2"
                    src="/assets/img/warning.png"
                    alt="tagturbo"
                  />

                  <p className="text-20 text-danger">Error</p>
                  <p className="text-14">
                    Something went wrong. Check to see if <br /> your connection
                    is secure and try again.
                  </p>
                </div>
                <div className="modal-footer justify-content-center px-5 pb-5">
                  <div
                    className="btn cursor w-50"
                    onClick={() => setOpenPopupUnsyncTryAgain(false)}
                  >
                    Cancel
                  </div>
                  <div
                    className="btn btn-danger w-50"
                    onClick={() => checkRetryUnsync()}
                  >
                    Retry
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {/* //QuotaLimitReached */}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupQuotaLimitReached}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupQuotaLimitReached(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white">
                    <p className="py-0 mb-0 text-20"></p>
                  </div>
                </div>

                <div className="modal-body p-3 mt-3 text-center">
                  <img className="mb-2" src="/assets/img/warning.png" />

                  <p className="text-18 text-semibold text-danger">
                    Event Quota Limit Reached
                  </p>
                  <p className="text-14">
                    Based on your current plan, you have reached the event{' '}
                    <br /> limit allowed for your account plan. Please upgrade
                    to a <br /> new plan or contact our support.{' '}
                  </p>
                </div>
                <div className="modal-footer justify-content-center px-5 pb-4">
                  <div
                    className="btn btn-danger text-semibold text-14 w-140 btn-h-44"
                    onClick={() => setOpenPopupQuotaLimitReached(false)}
                  >
                    Close
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </TagBox>
  )
}
export default IntegrationComponent
