import styled from 'styled-components'

const TagBox = styled.div`
  .box-card {
    height: 406px;
    display: grid;
  }
  .btn-text-primary {
    color: #0047ff;
  }
  .text-upper:first-letter {
    text-transform: uppercase;
  }
  .alert-primary {
    --bs-alert-color: #ffffff;
    --bs-alert-bg: #0047ff;
    --bs-alert-border-color: #0047ff;
  }
  .text-dark-gray {
    color: #212529ba;
  }
  .-pointer:hover {
    color: #0047ff;
  }
  .table > :not(caption) > * > * {
    padding: 1rem 1.7rem;
  }
  .spacesing {
    padding-right: 20px;
    width: 382px;
  }
  .spacesing-left {
    padding-left: 20px;
    border-left: 1px solid #c2c2c2;
  }
  .content-menu {
    width: 100%;
    padding-left: 256px;
  }
  .content-box {
    height: 100%;
  }
  .content-scroll {
    // margin-top: 100px;
    height: 87vh;
    overflow-y: auto;
  }
  .-pointer {
    cursor: pointer;
  }
  .btn {
    margin: 0.125em;
  }
  .boxSetting {
    right: 2%;
    width: 200px;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
  }

  .dropdown-item-setting:hover {
    background: #c1c1c129;
  }
  .badge {
    margin-left: 0px;
    min-width: 55px;
    text-align: center;
    text-transform: capitalize;
  }
  .badge-grey {
    background-color: #acb8cb;
    color: #fff;
  }
  .badge-default {
    background-color: #f2f4f7;
    color: #344054;
  }
  .badge-green {
    background-color: #ecfdf3;
    color: #037a48;
  }
  .badge-blue {
    background-color: #0047ff;
    color: #fff;
    height: 41px;
  }
  .badge-primary {
    background-color: #eaf0ff;
    color: #0047ff;
  }
  .badge-yellow {
    background-color: #fec84b;
    color: #344054;
  }
  .badge-white {
    border: solid 1px #d0d5dd;
    background-color: #fff;
    color: #344054;
  }
  .table {
    svg {
      color: #98a2b3;
      cursor: pointer;
    }
  }

  .close:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
  }
  .alert-box {
    position: absolute;
    width: 30%;
    z-index: 999999;
    padding: 0px;
    top: 45px;
    right: 20px;
  }
  .input-group-text {
    padding: 10px 5px;
    line-height: 1.5;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #d0d5dd;
    border-right: 0;
  }
  .boxsearch {
    right: 193px;
    width: 362px;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
    z-index: 9;
  }
  .text-gray {
    color: #d0d5dd;
  }
  .ml-20 {
    margin-left: 20px;
  }
  .tag-label {
    padding: 2px 10px;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #f5f5f5;
    margin-right: 10px;
  }
  .search-label {
    border-radius: 16px;
    background: #d0d5dd;
    padding: 2px 10px;
    margin-left: 13px;
    margin-top: -1px;
    color: #667085;
    font-size: 12px;
    font-weight: 600;
  }
  .searchInput {
    border-color: #d0d5dd;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    padding-right: 30px;
  }
  .searchInput::placeholder {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #d0d5dd;
  }
  .searchInput:focus {
    border: 2px solid #96b3ff !important;
    outline: none;
    box-shadow: none;
    margin-left: 0px;
    border-radius: 5px !important;
  }

  .icon-refresh-search {
    position: absolute;
    right: 10px;
    top: 14px;
    z-index: 9;
    color: #666666;
  }
  .vertical-line {
    width: 1px;
    background-color: #d6dded;
    height: 25px;
    margin-top: 10px;
    z-index: 9999;
  }
  .input-group-prepend {
    width: auto;
  }
`
const TrackingBox = styled.div`
  .text-gray {
    color: #667085 !important;
  }

  .tracking-alert {
    position: absolute;
    display: flex;
    align-items: center;
    width: 86%;
    justify-content: center;
  }
  .badge-copy {
    background: #344054;
  }
  .tracking-alert {
    position: absolute;
    display: flex;
    align-items: center;
    width: 86%;
    justify-content: center;
    height: 140px;
  }
`
const ModalBox = styled.div`
  .w-table {
    max-width: 345px !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const ModalTagBox = styled.div`
  .nav-link {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: rgb(13, 110, 253) !important;
    border-bottom: 2px solid #0047ff;
    border-bottom-color: #0047ff !important;
    background-color: #f9fafb !important;
    border-color: transparent;
  }
  .nav-link:hover {
    isolation: isolate;
    border-color: transparent;
    cursor: pointer;
  }
  .nav-tabs {
    background-color: #f9fafb !important;
  }
  .zero-tab {
    display: grid;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    height: 120px;
  }
  .firstBox {
    width: 300px;
  }
  .secondBox {
    width: 100%;
  }
  .w-200 {
    width: 200px;
  }
`
export { TagBox, TrackingBox, ModalBox, ModalTagBox }
