import React from 'react'
import { FooterBox } from './style'
const { REACT_APP_GTP_VERSION } = process.env

const FooterComponent = () => {
  return (
    <FooterBox>
      <div className="d-flex justify-content-md-center footer-box">
        <div className="footer-text m-2 text-10">
          Copyright © 2024 by Predictive Co., Ltd. All Right Reserved.
          <a
            className="text-10"
            target="_blank"
            href="https://tagturbo.com/privacy-notice/"
          >
            Privacy Notice
          </a>
          <a
            className="text-10"
            target="_blank"
            href="https://tagturbo.com/terms/"
          >
            Terms and conditions
          </a>
          {/* <span className="text-10  my-2">
            <span className="mx-2"> |</span> Version {REACT_APP_GTP_VERSION}
          </span> */}
        </div>
      </div>
    </FooterBox>
  )
}

export default FooterComponent
