import React, { Fragment, useState, useEffect } from 'react'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'
import TopbarNotiComponent from '../layout/topbarNotification'

import { WorkspaceSettingsBox } from './style'
import FooterComponent from '../layout/footer'
import TopbarSettingsComponent from '../layout/topbarworkspacesettings'
import MenuSettingComponent from '../layout/menusetting'
import { MdError, MdClose } from 'react-icons/md'
import AlertComponent from '../layout/alert'
import { roleByAccountId, roleByWorkspaceId } from '../../apis/account/api'
import {
  editWorkspaceSetting,
  workspaceDetail,
  deleteWorkspace,
} from '../../apis/workspace/api'
import LoadingComponent from '../layout/loading'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'
import { planDetail } from '../../apis/plan/api'

const WorkspaceSettingsComponent = () => {
  const [openPopupConfirmDelete, setOpenPopupConfirmDelete] = useState(false)
  const { accountId } = useParams()
  const { workspaceId } = useParams()
  const [workspaceName, setWorkspaceName] = useState('')
  const [websiteURL, setWebsiteURL] = useState('')
  const [industry, setIndustry] = useState('')
  const accessToken = localStorage.getItem('token')
  const [isClickSave, setIsClickSave] = useState(false)
  const [validateWorkspaceName, setValidateWorkspaceName] = useState(true)
  const [activeAccountRole, setActiveAccountRole] = useState('')
  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')
  const [accountDetail, setAccountDetail] = useState('')
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [planInfo, setPlanInfo] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)

  const customStylesSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '436px',
    },
  }

  useEffect(() => {
    document.title = 'Workspace Settings | Tag Turbo'
    setIsLoadingPage(true)
    getWorkspace()
  }, [])
  useEffect(() => {
    getRoleByAccountId()
    getRoleByWorkspaceId()
  }, [])

  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setIsLoadingPage(false)
      setPlanInfo(data.plan_of_account_detail)
    } else {
      setIsLoadingPage(false)
    }
  }
  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setIsLoadingPage(false)
      setActiveAccountRole(data.data.role)
      if (data.data.role !== 'None') {
        getPlanInfo()
      }
    } else {
      setIsLoadingPage(false)
    }
  }
  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setIsLoadingPage(false)
      setActiveWorkspaceRole(data.data.role)
    } else {
      setIsLoadingPage(false)
    }
  }

  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })
  const handleAlert = (msg, status) => {
    setShowAlert({
      msg,
      status,
      isShow: true,
    })

    setTimeout(() => {
      setShowAlert({
        msg,
        status,
        isShow: false,
      })
    }, 10000)
  }
  const editWorkspace = async () => {
    setIsDisabled(true)
    if (!workspaceName) {
      setValidateWorkspaceName(false)
    } else {
      setValidateWorkspaceName(true)
      setIsClickSave(true)
      let obj = {
        name: workspaceName,
      }
      const { data, status } = await editWorkspaceSetting(
        accessToken,
        workspaceId,
        obj,
      )

      if (status === 200) {
        handleAlert('Workspace Setting has been updated successfully', true)
        setIsClickSave(false)
      } else {
        handleAlert('Workspace Setting  has been updated false', false)
        setIsClickSave(false)
      }
    }
  }

  const getWorkspace = async (token) => {
    const { data, status } = await workspaceDetail(accessToken, workspaceId)
    if (status === 200) {
      setIsLoadingPage(false)
      setWorkspaceName(data.workspace_detail.name)
      setWebsiteURL(data.workspace_detail.domain)
      setIndustry(data.workspace_detail.industry_name || 'Others')
    } else {
      setIsLoadingPage(false)
    }
  }

  const closeWorkspace = async () => {
    const { data, status } = await deleteWorkspace(
      accessToken,
      accountId,
      workspaceId,
    )
    if (status === 200) {
      window.location.href = '/home'
      let str =
        ' ‘ ' +
        workspaceName +
        ' ’ ' +
        ' workspace has been closed successfully.'
      localStorage.setItem('isHandleAlert', str)

      setOpenPopupConfirmDelete(false)
    } else {
      handleAlert('close Workspace false', false)
    }
  }

  const checkSpecialsChar = (str) => {
    let res
    const specials = /^[\u0E00-\u0E7Fa-zA-Z0-9\.\s\-_,]+$/gi
    if (str.match(specials)) {
      setWorkspaceName(str)
      setValidateWorkspaceName(true)
      res = true
    }
    return res
  }

  return (
    <WorkspaceSettingsBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          <TopbarSettingsComponent accountDetailFn={setAccountDetail} />
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarFreeNotiComponent planInfo={planInfo} />
              <TopbarNotiComponent />

              <MenuSettingComponent
                accountId={accountId}
                id={workspaceId}
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
              />
              {showAlert.isShow && (
                <AlertComponent
                  messageAlert={showAlert.msg}
                  status={showAlert.status}
                  alertType={showAlert.status ? 'alert-success' : 'alert-error'}
                />
              )}
              <div className="content-menu content-scroll ">
                <div className="container-md animate fadeIn one p-4 min-h-82">
                  <div className="content">
                    <div className="d-flex justify-content-between item-center mb-4">
                      <div className="col-6">
                        <h4 className="m-0 text-20 text-semibold">
                          Workspace Settings
                        </h4>
                      </div>
                    </div>

                    <div className="card p-2 border box-shadow-center bg-white mt-2">
                      <div className="card-body">
                        <p className="text-18 text-semibold">Basic Settings</p>
                        <p className="text-14 mb-1">Workspace ID</p>
                        <p className="text-14">{workspaceId}</p>
                        <p className="text-14 mb-1">Workspace Name</p>
                        <input
                          type="text"
                          maxLength={30}
                          className={`form-control w-25 ${!validateWorkspaceName ? 'invalidate-box' : ''
                            }`}
                          id="workspace_name"
                          value={workspaceName}
                          onChange={(e) => (
                            e.target.value.length === 0
                              ? setWorkspaceName(e.target.value)
                              : checkSpecialsChar(e.target.value),
                            setIsDisabled(false)
                          )}
                        />
                        {!validateWorkspaceName && (
                          <label className="form-label invalidate-text text-14">
                            Workspace is required.
                          </label>
                        )}
                        <br />
                        <p className="text-14 mb-1">Website URL</p>
                        <input
                          type="text"
                          className="form-control w-25"
                          id="website_name"
                          value={websiteURL}
                          disabled
                        />
                        <br />
                        <p className="text-14 mb-1">Business Industry *</p>
                        <input
                          type="text"
                          className="form-control w-25 mb-3"
                          id="website_name"
                          value={industry}
                          disabled
                        />

                        <p className="text-18 text-semibold my-4">
                          Danger Zone
                        </p>
                        <div className="p-3 mb-2 dangerzone text-emphasis-danger d-flex">
                          <div className="mx-3">
                            <MdError />
                          </div>
                          <div>
                            {' '}
                            <p className="text-14 mb-1 text-semibold">
                              Close Workspace
                            </p>
                            <p className="text-14 mb-1">
                              Closing your workspace means this workspace will
                              be removed from you and other workspace users.
                              After you confirm to close, all data within the
                              workspace will be permanently deleted and
                              unrecoverable.
                              <span
                                className="text-14 mb-1 cursor text-decoration-underline mx-2"
                                onClick={() => setOpenPopupConfirmDelete(true)}
                              >
                                Close Workspace
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-4">



                      <button type="button"
                        onClick={() => editWorkspace()}
                        className="btn btn-primary  btn-load px-5"
                        disabled={isDisabled ? true : isClickSave}>
                        <span className="flex-grow-1  d-flex align-items-center justify-content-center">
                          {isClickSave && <span className="spinner-border flex-shrink-0 mx-2" role="status"></span>}
                          Save
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="my-3">
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          {/* Popup ConfirmDelete*/}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupConfirmDelete}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupConfirmDelete(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white">
                    <p className="py-0 mb-0 text-20"></p>
                  </div>
                </div>
                <div className="d-flex justify-content-end px-2">
                  <div>
                    <MdClose
                      className="cursor"
                      onClick={() => setOpenPopupConfirmDelete(false)}
                    />
                  </div>
                </div>
                <div className="modal-body p-4 mt-3 text-center">
                  <img className="mb-2" src="/assets/img/warning.png" />
                  <p className="text-20 text-danger">Close Workspace</p>
                  <p className="text-14">
                    Are you sure you want to close your workspace? Please note
                    that this action is permanent and cannot be undone.
                  </p>
                </div>
                <div className="modal-footer justify-content-center px-5 pb-5">
                  <div
                    className="btn w-50 cursor"
                    onClick={() => setOpenPopupConfirmDelete(false)}
                  >
                    Cancel
                  </div>
                  <div
                    className="btn btn-danger w-50"
                    onClick={() => closeWorkspace()}
                  >
                    Close Workspace
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </WorkspaceSettingsBox>
  )
}

export default WorkspaceSettingsComponent
