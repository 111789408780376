import React, { Fragment, useState, useEffect } from 'react'
import { VerifyBox, ModalBox } from './style'
import FooterComponent from '../layout/footer'
import { useOtpInput } from 'react-otp-input-hook'
import { parseJwt, redirectLogin } from '../../helper/helper'
import { otpVerify, otpResend } from '../../apis/login/api'
import Modal from 'react-modal'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReCaptchaComponent from '../login/recaptcha'
import OTPBox from './otpbox.js'
const { REACT_APP_CAPTCHA } = process.env
var round = 0

const VerifyComponent = () => {
  const [otp, setOtp] = useState('')
  const [refCode, setRefCode] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [validateOtp, setValidateOtp] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [loading, setLoading] = useState(false)
  const [verifyToken, setVerifyToken] = useState('')
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0)
  const [isClear, setIsClear] = useState(false)
  const [openPopupExpired, setOpenPopupExpired] = useState(false)

  const customStylesSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '450px',
    },
  }
  useEffect(() => {
    document.title = 'Verify | Tag Turbo'
    const token = localStorage.getItem('token')
    if (token) {
      setAccessToken(token)
      setRefCode(parseJwt(token))
      refreshRecaptcha()
      document
        .getElementById('viewport')
        .setAttribute(
          'content',
          'initial-scale=0.9, minimum-scale=0.9,width=450',
        )
    } else {
      redirectLogin()
    }
    ; (function () {
      function onTidioChatApiReady() {
        window.tidioChatApi.hide()
        window.tidioChatApi.on('close', function () {
          window.tidioChatApi.hide()
        })
      }

      if (window.tidioChatApi) {
        window.tidioChatApi.on('ready', onTidioChatApiReady)
      } else {
        document.addEventListener('tidioChat-ready', onTidioChatApiReady)
      }
    })()
  }, [])

  useEffect(() => {
    if (otp && otp.length === 6) {
      document.addEventListener('keypress', function (event) {
        if (event.key === 'Enter') {
          onSubmit()
        }
      })
    }
  }, [otp])

  useEffect(() => {
    setVerifyToken(verifyToken)
  }, [verifyToken])

  const keepTokenFn = (token) => {
    setVerifyToken(token)
  }

  const refreshRecaptcha = () => {
    const interval = setInterval(async () => {
      round = round === 0 ? 1 : 0
      setRefreshReCaptcha(round)
    }, 60000)
    return () => clearInterval(interval)
  }

  const handleResend = async () => {
    setIsClear(!isClear)
    setValidateOtp({
      msg: '',
      status: true,
    })
    round = round === 0 ? 1 : 0
    setRefreshReCaptcha(round)
    let obj = {
      token: accessToken,
      recaptcha_token: verifyToken,
    }
    const { data, status } = await otpResend(obj)
    if (status === 200 && data) {
      const token = data.token
      setAccessToken(token)
      setRefCode(parseJwt(token))
    } else {
      // round = round === 0 ? 1 : 0;
      // setRefreshReCaptcha(round);
      window.location.reload()
    }
  }

  const onSubmit = async () => {
    setLoading(true)
    if (!otp) {
      handleValidateOTP('The code is required.', false)
      setLoading(false)
    } else {
      if (otp.length > 1 && otp.length < 6) {
        handleValidateOTP('The code is incorrect.', false)
        setLoading(false)
      } else {
        let obj = {
          token: accessToken,
          otp: otp,
          project: 'GTP',
          recaptcha_token: verifyToken,
        }
        const { data, status } = await otpVerify(obj)
        if (status === 200 && data) {
          setLoading(false)
          const token = data.token
          setAccessToken(token)
          localStorage.setItem('token', data.token)

          const accept = data.user.accept_term
          if (accept) {
            window.location.href = '/home'
          } else {
            window.location.href = '/termservice'
          }
        } else {
          setLoading(false)
          round = round === 0 ? 1 : 0
          setRefreshReCaptcha(round)
          handleValidateOTP('The code is incorrect.', false)
          if (data.response.data.message === 'TokenExpiredError: jwt expired') {
            setOpenPopupExpired(true)
          }
        }
      }
    }
  }

  const censoringEmail = (email) => {
    email = email.split('@')
    const emailName = email[0].substring(0, 3).padEnd(email[0].length, '*')
    let emailProvider = email[1].split('.')
    emailProvider[0] = emailProvider[0].replaceAll(/./g, '*')
    emailProvider = emailProvider.join('.')
    return emailName + ' ' + emailProvider
  }

  const handleChange = (value) => {
    handleValidateOTP('', true)
    setOtp(value)
  }

  const handleValidateOTP = (msg, status) => {
    setValidateOtp({ msg, status })
  }
  return (
    <VerifyBox>
      <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_CAPTCHA}>
        <div className="page full-page">
          <div className="content-box">
            <div className="container-fluid animate fadeIn one h-100">
              <div className="row justify-content-md-center h-100">
                <div className="col-lg-6 text-center p-0 d-none d-lg-block">
                  <img
                    src="/assets/img/BG_login.png"
                    className="w-100 p-0"
                    alt="Tag Turbo"
                  />
                </div>
                <div className="col-lg-6 text-center p-0 content h-100 d-flex-center">
                  <div id="verify" className="min-h-100 max-w-718">
                    <div className="p-5 min-h-95">
                      <h2 className="mt-4">
                        <img
                          src="/assets/img/logo-Tagtb.svg"
                          className="img-180"
                        />
                      </h2>
                      <div className="text-left mt-5">
                        <p>
                          <b>Verification Code</b>
                        </p>
                        <p className="text-14">
                          Please check your email{' '}
                          <span className="text-primary text-14">
                            {refCode.username &&
                              censoringEmail(refCode.username)}
                          </span>{' '}
                          for a six-digit code <br /> and enter it below to
                          login.
                        </p>
                      </div>
                      <div id="verify" className="text-center my-5">
                        <OTPBox
                          isValid={validateOtp.status}
                          onChange={handleChange}
                          clear={isClear}
                          setIsClear={setIsClear}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              onSubmit()
                            }
                          }}
                        />
                        {!validateOtp.status && (
                          <Fragment>
                            <p className="text-danger mt-2 mb-0 text-14">
                              {validateOtp.msg}{' '}
                            </p>
                          </Fragment>
                        )}
                        <p className="mt-1 text-14 gray-400">
                          Ref code ({refCode.ref_code})
                        </p>
                      </div>
                      <div className="text-center mb-3">
                        <p className="my-5 text-14">
                          It may take a minute to receive your code Haven’t
                          received? <br />{' '}
                          <a
                            className="text-primary cursor"
                            onClick={() => handleResend()}
                          >
                            Resend a new code
                          </a>
                        </p>
                      </div>
                      <div className="text-center">


                        <button type="button"
                          onClick={() => onSubmit()}
                          className="btn btn-primary w-50 text-16 text-semibold btn-load"
                          disabled={loading}>
                          <span className="flex-grow-1  d-flex align-items-center justify-content-center">
                            {loading && <span className="spinner-border flex-shrink-0 mx-2" role="status"></span>}
                            Verify
                          </span>
                        </button>
                      </div>
                    </div>
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReCaptchaComponent
          keepTokenFn={keepTokenFn}
          refreshToken={refreshReCaptcha}
        />
      </GoogleReCaptchaProvider>
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupExpired}
        contentLabel="modal"
        style={customStylesSM}
        onRequestClose={() => setOpenPopupExpired(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <ModalBox>
          <div className="modal-dialog modal-md ">
            <div className="modal-content">
              <div className="modal-header bg-primary">
                <div className="p-1 text-white"></div>
              </div>
              <div className="modal-body p-3 mt-3">
                <div className="text-center">
                  <img className="mb-2" src="/assets/img/expired.svg" />
                </div>
                <p className="text-18 text-semibold text-primary text-center">
                  Whoops, Your session has expired!
                </p>

                <p className="text-14 text-center mb-2">
                  Your session has expired due to your inactivity. <br />
                  No worry, simply Sign in again.
                </p>
              </div>
              <div className="modal-footer justify-content-center px-4 pb-3">
                <Fragment>
                  <div
                    className="btn btn-primary text-14"
                    onClick={() => (window.location.href = '/login')}
                  >
                    Sign in
                  </div>
                </Fragment>
              </div>
            </div>
          </div>
        </ModalBox>
      </Modal>
    </VerifyBox>
  )
}

export default VerifyComponent
