import axios from 'axios'
import { redirectLogin, redirectPermission } from '../../helper/helper'
const { REACT_APP_API_TAG } = process.env

const subscriptionDetail = async (token, account_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/plan/subscription-detail`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const updateAutoRenewal = async (token, account_id, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/plan/auto-renewal`
    const response = await axios.patch(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const cancelPlan = async (token, account_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/plan/cancel-plan`
    const response = await axios.post(URL, {}, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const resubscribePlan = async (token, account_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/plan/resubscribe-plan`
    const response = await axios.post(URL, {}, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const usageDetail = async (token, account_id, period) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/plan/usage/${period}`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const periodDetail = async (token, account_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/plan/period`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const checkOneDollar = async (token, account_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/payment/check-one-dollar`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const checkChangePlan = async (token, account_id, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/payment/check-change-plan?plan_id=${obj.plan_id}&plan_type=${obj.plan_type}`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

export {
  subscriptionDetail,
  updateAutoRenewal,
  cancelPlan,
  resubscribePlan,
  usageDetail,
  periodDetail,
  checkOneDollar,
  checkChangePlan,
}
