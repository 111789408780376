import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Home, CreateBox } from './style'
import FooterComponent from '../layout/footer'
import TopbarComponent from '../layout/topbar'
import { createAccount } from '../../apis/home/api'
import Select, { components } from 'react-select'
import {
  MdOutlineEast,
  MdClose,
  MdAutoAwesome,
  MdArrowRightAlt,
} from 'react-icons/md'
import {
  allCountry,
  allCurrency,
  checkAlreadyFreePlan,
  checkAlreadyFreeTrial,
  createAccountTrial,
} from '../../apis/account/api'
import { allPlan, chargeDetail } from '../../apis/plan/api'
import { numberWithCommas } from '../../helper/helper'
import PaymentComponent from './payment'
import Modal from 'react-modal'
import LoadingComponent from '../layout/loading'
// import introJs from 'intro.js'

const CreateAccountComponent = () => {
  const [accessToken, setAccessToken] = useState('')
  const [accountName, setAccountName] = useState('')
  const [country, setCountry] = useState('')
  const [currency, setCurrency] = useState('')
  const [selectedPlan, setSelectedPlan] = useState('')
  const [checkout, setCheckout] = useState('')
  const [checkPayment, setCheckPayment] = useState('')
  const [checkFreeTrial, setCheckFreeTrial] = useState('')
  const [checkFreePlan, setCheckFreePlan] = useState('')
  const [openOpenPopupNonFreeTrial, setOpenPopupNonFreeTrial] = useState(false)
  const [preriod, setPreriod] = useState(false)
  const [languagesTh, setLanguagesTh] = useState(false)

  const [currencyPrice, setCurrencyPrice] = useState('th')

  const [planList, setPlanList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [currencyList, setCurrencyList] = useState([])
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [dafaultCountry, setDafaultCountry] = useState([
    {
      value: 'Thailand',
      label: 'Thailand',
    },
  ])
  const [validateAccount, setValidateAccount] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [isClick, setIsClick] = useState(false)
  const [loading, setLoading] = useState({ isLoad: false, plan: '' })

  const [userTourList, setUserTourList] = useState([])
  // const get_user_tour = localStorage.getItem('user_tour')
  // const obj_get_user_tour = JSON.parse(get_user_tour)

  useEffect(() => {
    document.title = 'CreateAccount | Tag Turbo'
    setIsLoadingPage(true)
    const token = localStorage.getItem('token')
    setAccessToken(token)
    getCountry(token)
    getCurrency(token)
    getPlan(token)
    getCheckAlreadyFreeTrial(token)
    getCheckAlreadyFreePlan(token)

    const languages = navigator.languages
    let filterLang = languages.filter((item) => item === 'th' || item === 'TH')
    if (filterLang.length >= 1) {
      setLanguagesTh(true)
    }
  }, [])
  // useEffect(() => {
  //   if (obj_get_user_tour) {
  //     if (obj_get_user_tour?.status !== 'Done') {
  //       let filterPage = obj_get_user_tour?.ut_page?.filter(
  //         (item) => item.page === document.title,
  //       )
  //       if (filterPage.length > 0) {
  //         if (filterPage[0].step !== '2') {
  //           //เคยเข้าหน้านี้แต่stepยังไม่ครบ
  //           renderUserTour()
  //         }
  //       } else {
  //         //ยังไม่เคยเข้าหน้านี้
  //         renderUserTour()
  //       }
  //     }
  //   } else {
  //     //เข้าแอพครั้งแรก
  //     renderUserTour()
  //   }
  // }, [])

  // const renderUserTour = () => {
  //   setTimeout(() => {
  //     introJs()
  //       .setOptions({
  //         disableOverlay: false,
  //         hidePrev: true,
  //         showStepNumbers: false,
  //         doneLabel: 'Done',
  //         exitOnOverlayClick: false,
  //         showButtons: true,
  //         showBullets: false,
  //         hideNext: false,
  //         highlightClass: 'custom-highlight-class',
  //       })
  //       .onbeforechange(function () {
  //         setTimeout(() => {
  //           var skipButton = document.querySelector('.introjs-skipbutton')
  //           if (skipButton) {
  //             skipButton.addEventListener('click', function () {
  //               let obj_tour = {
  //                 ut_page: [],
  //                 status: 'Done',
  //               }
  //               localStorage.setItem('user_tour', JSON.stringify(obj_tour))
  //             })
  //           }
  //         }, 500)
  //       })
  //       .onbeforeexit(function () {
  //         if (obj_get_user_tour) {
  //           let temp = {
  //             page: document.title,
  //             step: '2',
  //             sub_step: '',
  //           }
  //           obj_get_user_tour.ut_page.push(temp)
  //           localStorage.setItem('user_tour', JSON.stringify(obj_get_user_tour))
  //         } else {
  //           let obj_tour = {
  //             ut_page: [
  //               {
  //                 page: document.title,
  //                 step: '2',
  //                 sub_step: '',
  //               },
  //             ],
  //             status: '',
  //           }
  //           localStorage.setItem('user_tour', JSON.stringify(obj_tour))
  //         }
  //       })
  //       .start()
  //   }, 1000)
  // }

  const customStylesPopupSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '511px',
    },
  }
  const getPlan = async (token) => {
    const { data, status } = await allPlan(token)
    if (status === 200) {
      setPlanList(data.plan_list)
      setIsLoadingPage(false)
    }
  }

  const getCountry = async (token) => {
    const { data, status } = await allCountry(token)
    let country = data.country_list
    country.forEach(function (obj) {
      obj.value = obj.country_name
      obj.label = obj.country_name
      delete obj.country_name
      delete obj.id
    })
    if (status === 200) {
      setCountryList(country)
    }
  }

  const getCurrency = async (token) => {
    const { data, status } = await allCurrency(token)
    let currency = data.currency_list
    currency.forEach(function (obj) {
      obj.value = obj.symbol
      obj.currency = obj.id
      obj.label = (
        <div className="select-flag">
          <img
            alt="tagturbo"
            className="-flag"
            src={`assets/img/Flag-${obj.id.toUpperCase()}.png`}
          />{' '}
          {obj.id.toUpperCase()}
        </div>
      )
      delete obj.id
      delete obj.status
      delete obj.symbol
      delete obj.country
    })

    if (status === 200) {
      setCurrency(currency)
      setCurrencyList(currency)
    }
  }

  const onCreateAccount = async (plan_id) => {

    let isValidAccount = false

    if (!accountName) {
      handleValidate('Account Name is required.', false)
    } else {
      if (checkSpecialsChar(accountName)) {
        isValidAccount = true
      } else {
        handleValidate('Account Name is invalid.', false)
      }
    }
    if (isValidAccount) {
      setIsClick(true)
      // let obj = {
      //   plan_id: plan_id,
      //   plan_type: preriod ? "year" : "month",
      //   currency:
      //     currency?.currency || currencyList[languagesTh ? 0 : 1].currency,
      //   country: country || "Thailand",
      //   payment_event_type:"manual_renewal_charge_account_assign_plan"

      // };
      // let token = accessToken;
      // const { data, status } = await chargeDetail(token, obj);
      // if (data && status === 200) {
      //   setCheckout(true);
      //   setCheckPayment(data.data);
      // } else {
      //   setIsClick(false);
      // }
      setCheckout(true)
      setCheckPayment('')
    }
  }
  const onCreateAccountFree = async (item) => {
    let isValidAccount = false

    if (!accountName) {
      handleValidate('Account Name is required.', false)

    } else {

      if (checkSpecialsChar(accountName)) {
        isValidAccount = true
      } else {
        handleValidate('Account Name is invalid.', false)
      }
    }
    if (isValidAccount) {
      setIsClick(true)
      setCheckout(true)
      setCheckPayment('')
      setSelectedPlan(item)
    }
  }

  const onCreateAccountFreeTrial = async (plan) => {
    let isValidAccount = false
    if (!accountName) {
      setTimeout(() => {
        setLoading({
          isLoad: false,
          plan: '',
        })
      }, 1000);

      handleValidate('Account Name is required.', false)
    } else {
      setTimeout(() => {
        setLoading({
          isLoad: false,
          plan: '',
        })
      }, 1000);
      if (checkSpecialsChar(accountName)) {
        isValidAccount = true
      } else {
        handleValidate('Account Name is Valid.', false)
      }
    }
    if (isValidAccount) {
      setIsClick(true)
      let obj = {
        account_name: accountName,
        plan_id: plan.id,
        country: country || 'Thailand',
      }
      let token = accessToken

      const { data, status } = await createAccountTrial(token, obj)
      if (data && status === 200) {
        localStorage.setItem(
          'isTrialSuccess',
          'Account has been created successfully.',
        )
        window.location.href = '/home'
        localStorage.setItem('next_step', JSON.stringify(true))
        setTimeout(() => {
          setLoading({
            isLoad: false,
            plan: '',
          })
        }, 1000);
      } else {
        setOpenPopupNonFreeTrial(true)
        setTimeout(() => {
          setLoading({
            isLoad: false,
            plan: '',
          })
        }, 1000);
      }
    } else {
      setTimeout(() => {
        setLoading({
          isLoad: false,
          plan: '',
        })
      }, 1000);
    }
  }
  const onChangeCountry = (e) => {
    setCountry(e.value)
  }
  const handleValidate = (msg, status) => {
    setValidateAccount({ msg, status })
  }

  const checkSpecialsChar = (str) => {
    let res
    const specials = /^[\u0E00-\u0E7Fa-zA-Z0-9\.\s\-_,]+$/gi
    if (str.match(specials)) {
      setAccountName(str)
      handleValidate('', true)
      res = true
    } else {
      // handleValidate("Account Name is Valid.", false);
      // res = false;
    }
    return res
  }

  const handlePreriod = (event) => {
    setPreriod(event.target.checked)
  }

  const getCheckAlreadyFreeTrial = async (token) => {
    const { data, status } = await checkAlreadyFreeTrial(token)
    if (status === 200) {
      setCheckFreeTrial(!data.has_trial)
    } else {
      // handleAlert("Failed to update Account Role. Please try again.", false);
    }
  }
  const getCheckAlreadyFreePlan = async (token) => {
    const { data, status } = await checkAlreadyFreePlan(token)
    if (status === 200) {
      setCheckFreePlan(data.has_free_plan)
    }
  }

  const handleOpenChat = () => {
    // window.tidioChatApi.open()
    // window.tidioChatApi.display(true)
    window
      .open(
        'https://meetings.hubspot.com/hataipatt/tag-turbo-support',
        '_blank',
      )
      .focus()
  }

  return (
    <CreateBox>
      <div className="page pull-page-bg-secondary">
        <div className="content-box">
          <TopbarComponent isPublish={false} />
          <div className="content-scroll ">
            {!checkout ? (
              <div className="container-md  animate fadeIn one">
                {isLoadingPage ? (
                  <LoadingComponent />
                ) : (
                  <Fragment>
                    <div className="card p-2 border box-shadow-center bg-white mt-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-4 item-center">
                          <div>
                            <h6>
                              <b>Create Account</b>
                            </h6>{' '}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3">
                            <div
                              data-step="1"
                              data-intro="<div><p class='text-semibold text-14 mb-0'>Account Name</p>
                              <p class='text-12'>We recommend using your company name for easy identification.</p>
                              <p class=' text-semibold text-14 mb-0'>Country</p>
                              <p class='text-12'>Tell us where your business operates to ensure smooth tagging and compliance</p></div>"
                              data-position="right"
                              data-highlight-class="overlayUT"
                              className="step1"
                            >
                              <label className="form-label">Account Name</label>
                              <input
                                type="text"
                                maxLength={30}
                                className={`form-control ${!validateAccount.status
                                  ? 'invalidate-box'
                                  : ''
                                  }`}
                                id="account_name"
                                placeholder="e.g. My Account"
                                value={accountName}
                                onChange={(e) =>
                                  e.target.value.length === 0
                                    ? setAccountName('')
                                    : checkSpecialsChar(e.target.value)
                                }
                              />

                              {!validateAccount.status && (
                                <label className="form-label invalidate-text text-14">
                                  {validateAccount.msg}
                                </label>
                              )}
                              <br />

                              <label className="form-label">Country</label>
                              {dafaultCountry.length > 0 && (
                                <Select
                                  options={countryList}
                                  defaultValue={dafaultCountry[0]}
                                  className="mb-3"
                                  onChange={onChangeCountry}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      data-step="2"
                      data-intro="<div><p class=' text-semibold text-14 mb-1'>Select a plan</p>
                      <p class='text-12'>Unleash the power of Tag Turbo with a <b>free trial!</b> Explore the perfect plan for your business and see how it can transform your workflow. <b>No credit card needed,</b> and keep all your progress - just upgrade anytime to unlock all features!</p>
                     </div>"
                      data-position="top"
                      data-highlight-class="overlayUT"
                    >
                      <div className="card p-2 border box-shadow-center bg-white mt-2">
                        <div className="card-body">
                          <div className="d-flex">
                            <div className="col-8 justify-content-between mb-4 item-center">
                              <div>
                                <p className="mb-1">
                                  <b className="text-20">
                                    Choose a plan to get started
                                  </b>
                                </p>{' '}
                                <span className="text-16">
                                  Please select the plan that suits you best
                                </span>
                              </div>
                            </div>
                            <div className="col-4 justify-content-between item-center d-flex">
                              <Fragment>
                                {currencyList && currencyList.length > 0 && (
                                  <Select
                                    options={currencyList}
                                    defaultValue={
                                      languagesTh
                                        ? currencyList[0]
                                        : currencyList[1]
                                    }
                                    onChange={(e) => (
                                      setCurrency(e),
                                      setLanguagesTh(!languagesTh)
                                    )}
                                  />
                                )}
                              </Fragment>
                              <span
                                className={` ${!preriod ? 'text-semibold' : ''
                                  }`}
                              >
                                Monthly
                              </span>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={(e) => handlePreriod(e)}
                                />
                                <span className="slider round"></span>
                              </label>
                              <span
                                className={` ${preriod ? 'text-semibold' : ''}`}
                              >
                                Yearly
                              </span>

                              <span className="badge-saving">Save 20%</span>
                            </div>
                          </div>
                          <div className="row space-between">
                            {planList.length > 0 &&
                              planList.map((item, i) => {
                                return (
                                  <Fragment>
                                    {item.plan_name !== 'Free' ? (
                                      <Fragment>
                                        <div
                                          className={`col-3   pb-0 px-2`}
                                          key={i}
                                          onClick={() => setSelectedPlan(item)}
                                        >
                                          <div
                                            className={`card plan-box ${item.plan_name === 'Pro'
                                              ? 'plan-box-border'
                                              : 'mt-plan-box border-card-plan'
                                              }`}
                                          >
                                            {item.plan_name === 'Pro' ? (
                                              <div className="card-header text-center bg-most p-2 text-white text-semibold text-uppercase text-12 h-40 d-flex-center">
                                                <MdAutoAwesome className="mx-1" />{' '}
                                                <b> Most Popular</b>
                                              </div>
                                            ) : (
                                              <div
                                                className={`body-plan-box-gray`}
                                              ></div>
                                            )}
                                            <div
                                              className={` card-body text-left  line-height-2 pt-4 ${item.plan_name === 'Pro'
                                                ? 'border-primary'
                                                : ''
                                                }`}
                                            >
                                              <div className="d-flex">
                                                <p
                                                  className={`badge text-center  text-uppercase text-12 ${i === 0
                                                    ? 'badge-default'
                                                    : i === 1
                                                      ? 'badge-blue'
                                                      : i === 2
                                                        ? 'badge-purple'
                                                        : 'badge-gray'
                                                    } `}
                                                >
                                                  {item.plan_name}
                                                </p>
                                              </div>
                                              <b className="text-24">
                                                {item.price_per_year &&
                                                  item.price_per_month
                                                  ? currency?.value ||
                                                  currencyList[
                                                    languagesTh ? 0 : 1
                                                  ]?.value
                                                  : ''}
                                                {preriod
                                                  ? numberWithCommas(
                                                    item.price_per_year?.[
                                                    currency?.currency ||
                                                    currencyList[
                                                      languagesTh ? 0 : 1
                                                    ]?.currency
                                                    ] / 12 || 'Custom',
                                                  )
                                                  : numberWithCommas(
                                                    item.price_per_month?.[
                                                    currency?.currency ||
                                                    currencyList[
                                                      languagesTh ? 0 : 1
                                                    ]?.currency
                                                    ] || 'Custom',
                                                  )}
                                              </b>{' '}
                                              {item.price_per_year ||
                                                item.price_per_month ? (
                                                <span className="text-12">
                                                  <span className="text-uppercase">
                                                    {' '}
                                                    {currency.currency ||
                                                      currencyList[
                                                        languagesTh ? 0 : 1
                                                      ]?.currency}
                                                  </span>{' '}
                                                  / month
                                                </span>
                                              ) : (
                                                ''
                                              )}
                                              {item.price_per_year &&
                                                item.price_per_month ? (
                                                <p className="text-12 text-grey mb-1">
                                                  {' '}
                                                  {preriod ? (
                                                    <Fragment>
                                                      Billed yearly or{' '}
                                                      {numberWithCommas(
                                                        item.price_per_month?.[
                                                        currency?.currency ||
                                                        currencyList[
                                                          languagesTh
                                                            ? 0
                                                            : 1
                                                        ]?.currency
                                                        ],
                                                      )}{' '}
                                                      <span className="text-uppercase">
                                                        {' '}
                                                        {currency.currency ||
                                                          currencyList[
                                                            languagesTh ? 0 : 1
                                                          ]?.currency}
                                                      </span>{' '}
                                                      / month{' '}
                                                    </Fragment>
                                                  ) : (
                                                    'Monthly Price'
                                                  )}{' '}
                                                </p>
                                              ) : (
                                                <p className="text-12 text-grey mb-1">
                                                  For teams, businesses
                                                  <br />
                                                  organizations wanting to
                                                  maximize <br />
                                                  growth and scale fast.
                                                </p>
                                              )}
                                              <div className="mb-2">
                                                {item.price_per_year &&
                                                  item.price_per_month ? (
                                                  <img
                                                    alt="tagturbo"
                                                    src="/assets/img/Check_Blue.png"
                                                    className="mr-3 img-20"
                                                  />
                                                ) : (
                                                  <img
                                                    alt="tagturbo"
                                                    src="/assets/img/Check_Gold.png"
                                                    className="mr-3 img-20"
                                                  />
                                                )}

                                                <span className="text-12">
                                                  {item.visitors === 0
                                                    ? 'Custom'
                                                    : numberWithCommas(
                                                      item.visitors,
                                                    )}{' '}
                                                  monthly quota of event
                                                </span>
                                              </div>
                                              <div className="mb-2 d-inline-flex">
                                                {item.price_per_year &&
                                                  item.price_per_month ? (
                                                  <img
                                                    src="/assets/img/Check_Blue.png"
                                                    className="mr-3 img-20-w-h"
                                                  />
                                                ) : (
                                                  <img
                                                    src="/assets/img/Check_Gold.png"
                                                    className="mr-3 img-20-w-h"
                                                  />
                                                )}
                                                <span className="text-12">
                                                  Tracking channel support :{' '}
                                                  <br />
                                                  {item.ga4_integration &&
                                                    'Google Analytics 4'}
                                                  {item.google_ads_integration &&
                                                    ', Google Ads,'}
                                                  <br />
                                                  {item.meta_integration &&
                                                    ' Meta Ads'}
                                                  {item.tiktok_integration &&
                                                    ', TikTok Ads'}
                                                </span>
                                              </div>
                                              <div className="mb-2">
                                                {item.price_per_year &&
                                                  item.price_per_month ? (
                                                  <img
                                                    alt="tagturbo"
                                                    src="/assets/img/Check_Blue.png"
                                                    className="mr-3 img-20"
                                                  />
                                                ) : (
                                                  <img
                                                    alt="tagturbo"
                                                    src="/assets/img/Check_Gold.png"
                                                    className="mr-3 img-20"
                                                  />
                                                )}
                                                <span className="text-12">
                                                  Google Analytics Dashboard
                                                </span>
                                              </div>
                                              <div className="mb-2">
                                                {item.price_per_year &&
                                                  item.price_per_month ? (
                                                  <img
                                                    alt="tagturbo"
                                                    src="/assets/img/Check_Blue.png"
                                                    className="mr-3 img-20"
                                                  />
                                                ) : (
                                                  <img
                                                    alt="tagturbo"
                                                    src="/assets/img/Check_Gold.png"
                                                    className="mr-3 img-20"
                                                  />
                                                )}
                                                <span className="text-12">
                                                  {item.user_per_account === 0
                                                    ? 'Unlimited'
                                                    : item.user_per_account}{' '}
                                                  Account User
                                                </span>
                                              </div>
                                              <div className="mb-2">
                                                {item.price_per_year &&
                                                  item.price_per_month ? (
                                                  <img
                                                    alt="tagturbo"
                                                    src="/assets/img/Check_Blue.png"
                                                    className="mr-3 img-20"
                                                  />
                                                ) : (
                                                  <img
                                                    alt="tagturbo"
                                                    src="/assets/img/Check_Gold.png"
                                                    className="mr-3 img-20"
                                                  />
                                                )}
                                                <span className="text-12">
                                                  {
                                                    item.workspace_limit_per_account
                                                  }{' '}
                                                  Workspaces
                                                </span>
                                              </div>
                                              <div className="mb-2">
                                                {item.price_per_year &&
                                                  item.price_per_month ? (
                                                  <img
                                                    alt="tagturbo"
                                                    src="/assets/img/Check_Blue.png"
                                                    className="mr-3 img-20"
                                                  />
                                                ) : (
                                                  <img
                                                    alt="tagturbo"
                                                    src="/assets/img/Check_Gold.png"
                                                    className="mr-3 img-20"
                                                  />
                                                )}
                                                <span className="text-12">
                                                  {item.support_over_live_chat
                                                    ? 'Email and Live chat Support'
                                                    : 'Email Support'}
                                                </span>
                                              </div>
                                              <div className="mb-2 d-inline-flex">
                                                {item.price_per_year &&
                                                  item.price_per_month ? (
                                                  <Fragment>
                                                    {item.dedicated_training_session ? (
                                                      <img
                                                        src="/assets/img/Check_Blue.png"
                                                        className="mr-3 img-20-w-h"
                                                      />
                                                    ) : (
                                                      <img
                                                        src="/assets/img/Check_dis.png"
                                                        className="mr-3 img-20-w-h"
                                                      />
                                                    )}
                                                  </Fragment>
                                                ) : (
                                                  <img
                                                    src="/assets/img/Check_Gold.png"
                                                    className="mr-3 img-20-w-h"
                                                  />
                                                )}
                                                <span
                                                  className={`text-12 ${item.dedicated_training_session
                                                    ? ''
                                                    : 'text-grey'
                                                    }`}
                                                >
                                                  Designated Customer Success
                                                  Manager
                                                </span>
                                              </div>
                                              <div className="mb-2">
                                                {item.price_per_year &&
                                                  item.price_per_month ? (
                                                  <Fragment>
                                                    {item.dedicated_training_session ? (
                                                      <img
                                                        alt="tagturbo"
                                                        src="/assets/img/Check_Blue.png"
                                                        className="mr-3 img-20"
                                                      />
                                                    ) : (
                                                      <img
                                                        alt="tagturbo"
                                                        src="/assets/img/Check_dis.png"
                                                        className="mr-3 img-20"
                                                      />
                                                    )}
                                                  </Fragment>
                                                ) : (
                                                  <img
                                                    alt="tagturbo"
                                                    src="/assets/img/Check_Gold.png"
                                                    className="mr-3 img-20"
                                                  />
                                                )}
                                                <span
                                                  className={`text-12 ${item.dedicated_training_session
                                                    ? ''
                                                    : 'text-grey'
                                                    }`}
                                                >
                                                  Dedicated Training Session
                                                </span>
                                              </div>
                                              <div className="plan-box-footer">
                                                <div className="foot-btn">
                                                  {item.price_per_year ||
                                                    item.price_per_month ? (
                                                    checkFreeTrial && (

                                                      <button type="button"
                                                        onClick={() =>
                                                        (onCreateAccountFreeTrial(item), setLoading({
                                                          isLoad: true,
                                                          plan: item.plan_name,
                                                        }))
                                                        }
                                                        id={`free_trial_create_${item.plan_name}`}
                                                        className="btn badge-outline-blue w-100 mb-2 btn-load"
                                                        disabled={isClick}>
                                                        <span className="flex-grow-1  d-flex align-items-center justify-content-center">
                                                          {loading.plan ===
                                                            item.plan_name &&
                                                            loading.isLoad && <span className="spinner-border flex-shrink-0 mx-2" role="status"></span>}
                                                          Start 14 Days Free Trial

                                                        </span>
                                                      </button>
                                                    )
                                                  ) : (
                                                    <button
                                                      id={`create_contact_sales`}
                                                      type="button"
                                                      className={`btn w-100 badge-outline-gold `}
                                                      onClick={() =>
                                                        handleOpenChat()
                                                      }
                                                    >
                                                      Contact Sales{' '}
                                                      <MdArrowRightAlt className="text-20" />
                                                    </button>
                                                  )}{' '}
                                                  {item.price_per_year ||
                                                    item.price_per_month ? (
                                                    <Fragment>
                                                      <button
                                                        id={`paynow_create_${item.plan_name}`}
                                                        type="button"
                                                        onClick={() =>
                                                          onCreateAccount(
                                                            item.id,
                                                          )
                                                        }
                                                        className="btn btn-primary w-100 "
                                                        disabled={isClick}
                                                      >
                                                        Pay Now
                                                      </button>
                                                    </Fragment>
                                                  ) : (
                                                    <Fragment />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        {!checkFreePlan && (
                                          <div className="col-12  mt-3">
                                            <div className="card plan-box plan-box-borde p-4 px-5">
                                              <div className="row">
                                                <div className="col-4 item-center">
                                                  <p className="badge text-center mb-3 text-uppercase text-12 badge-free">
                                                    {item.plan_name}
                                                  </p>
                                                  <p className="text-24 d-flex item-center">
                                                    {' '}
                                                    <b>0</b>
                                                    <span className="text-14 text-uppercase px-2">
                                                      {' '}
                                                      {currency.currency ||
                                                        currencyList[
                                                          languagesTh ? 0 : 1
                                                        ]?.currency}
                                                    </span>
                                                  </p>
                                                  <p className="text-14 text-grey mb-0">
                                                    The quickest and easiest way
                                                    to start tracking <br />
                                                    your website.
                                                  </p>
                                                </div>
                                                <div className="col-5 d-flex item-center">
                                                  <div className="mx-3">
                                                    <div className="mb-3">
                                                      {item.price_per_year &&
                                                        item.price_per_month ? (
                                                        <img
                                                          alt="tagturbo"
                                                          src="/assets/img/Check_Blue.png"
                                                          className="mr-3 img-20"
                                                        />
                                                      ) : (
                                                        <img
                                                          alt="tagturbo"
                                                          src="/assets/img/Check_Gold.png"
                                                          className="mr-3 img-20"
                                                        />
                                                      )}

                                                      <span className="text-12">
                                                        {item.visitors === 0
                                                          ? 'Custom'
                                                          : numberWithCommas(
                                                            item.visitors,
                                                          )}{' '}
                                                        monthly quota of event
                                                      </span>
                                                    </div>

                                                    <div className="mb-3 d-inline-flex">
                                                      {item.price_per_year &&
                                                        item.price_per_month ? (
                                                        <img
                                                          src="/assets/img/Check_Blue.png"
                                                          className="mr-3 img-20-w-h"
                                                        />
                                                      ) : (
                                                        <img
                                                          src="/assets/img/Check_Gold.png"
                                                          className="mr-3 img-20-w-h"
                                                        />
                                                      )}
                                                      <span className="text-12">
                                                        Tracking channel support
                                                        : <br />
                                                        {item.ga4_integration &&
                                                          'Google Analytics 4'}
                                                        {item.google_ads_integration &&
                                                          ', Google Ads'}
                                                        <br />
                                                        {item.meta_integration &&
                                                          ' Meta Ads'}
                                                        {item.tiktok_integration &&
                                                          ', TikTok Ads'}
                                                      </span>
                                                    </div>

                                                    <div className="mb-0">
                                                      {item.price_per_year &&
                                                        item.price_per_month ? (
                                                        <img
                                                          alt="tagturbo"
                                                          src="/assets/img/Check_Blue.png"
                                                          className="mr-3 img-20"
                                                        />
                                                      ) : (
                                                        <img
                                                          alt="tagturbo"
                                                          src="/assets/img/Check_Gold.png"
                                                          className="mr-3 img-20"
                                                        />
                                                      )}
                                                      <span className="text-12">
                                                        Google Analytics
                                                        Dashboard
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="mx-3">
                                                    <div className="mb-3">
                                                      {item.price_per_year &&
                                                        item.price_per_month ? (
                                                        <img
                                                          alt="tagturbo"
                                                          src="/assets/img/Check_Blue.png"
                                                          className="mr-3 img-20"
                                                        />
                                                      ) : (
                                                        <img
                                                          alt="tagturbo"
                                                          src="/assets/img/Check_Gold.png"
                                                          className="mr-3 img-20"
                                                        />
                                                      )}
                                                      <span className="text-12">
                                                        {item.user_per_account ===
                                                          0
                                                          ? 'Unlimited'
                                                          : item.user_per_account}{' '}
                                                        Account User
                                                      </span>
                                                    </div>
                                                    <div className="mb-3">
                                                      {item.price_per_year &&
                                                        item.price_per_month ? (
                                                        <img
                                                          alt="tagturbo"
                                                          src="/assets/img/Check_Blue.png"
                                                          className="mr-3 img-20"
                                                        />
                                                      ) : (
                                                        <img
                                                          alt="tagturbo"
                                                          src="/assets/img/Check_Gold.png"
                                                          className="mr-3 img-20"
                                                        />
                                                      )}
                                                      <span className="text-12">
                                                        {
                                                          item.workspace_limit_per_account
                                                        }{' '}
                                                        Workspace
                                                      </span>
                                                    </div>
                                                    <div className="mb-0">
                                                      {item.price_per_year &&
                                                        item.price_per_month ? (
                                                        <img
                                                          alt="tagturbo"
                                                          src="/assets/img/Check_Blue.png"
                                                          className="mr-3 img-20"
                                                        />
                                                      ) : (
                                                        <img
                                                          alt="tagturbo"
                                                          src="/assets/img/Check_Gold.png"
                                                          className="mr-3 img-20"
                                                        />
                                                      )}
                                                      <span className="text-12">
                                                        {item.support_over_live_chat
                                                          ? 'Email and Live chat Support'
                                                          : 'Email Support'}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-3 d-flex item-center">
                                                  <Fragment>
                                                    <button
                                                      id={`paynow_create_${item.plan_name}`}
                                                      type="button"
                                                      onClick={() =>
                                                        onCreateAccountFree(
                                                          item,
                                                        )
                                                      }
                                                      className="btn btn-primary w-100 "
                                                      disabled={isClick}
                                                    >
                                                      Start for Free
                                                    </button>
                                                  </Fragment>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Fragment>
                                    )}
                                  </Fragment>
                                )
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-4">
                      <button
                        type="button"
                        onClick={() => (window.location.href = '/home')}
                        className="btn btn-outline-secondary"
                      >
                        Cancel
                      </button>
                    </div>
                  </Fragment>
                )}
              </div>
            ) : (
              <PaymentComponent
                checkPayment={checkPayment}
                countryList={countryList}
                currency={
                  currency?.value || currencyList[languagesTh ? 0 : 1].value
                }
                currency_label={
                  currency?.currency ||
                  currencyList[languagesTh ? 0 : 1].currency
                }
                plan_name={selectedPlan.plan_name}
                plan_id={selectedPlan.id}
                preriod={preriod}
                accountName={accountName}
                country={country}
              />
            )}
            <FooterComponent />
          </div>
        </div>
        <Modal
          shouldCloseOnOverlayClick={false}
          closeTimeoutMS={200}
          ariaHideApp={false}
          isOpen={openOpenPopupNonFreeTrial}
          contentLabel="modal"
          style={customStylesPopupSM}
          onRequestClose={() => setOpenPopupNonFreeTrial(false)}
          className={'modal-Publish-box animate-5 fadeIn two'}
        >
          <div className="modal-dialog modal-md ">
            <div className="modal-content">
              <div className="modal-header bg-danger">
                <div className="p-1 text-white">
                  <p className="py-0 mb-0 text-20"></p>
                </div>
              </div>
              <div className="d-flex justify-content-end px-2">
                <div>
                  <MdClose
                    className="cursor text-secondary"
                    onClick={() => setOpenPopupNonFreeTrial(false)}
                  />
                </div>
              </div>
              <div className="modal-body p-4 mt-3 text-center">
                <img
                  className="mb-2"
                  src="/assets/img/warning.png"
                  alt="tagturbo"
                />
                <p className="text-20 text-danger text-semibold">
                  Got Free trial already.
                </p>
                <p className="text-14">
                  You've already registered on free trial. <br /> You can simply
                  login with your account.
                </p>
              </div>
              <div className="modal-footer justify-content-center px-5 pb-5">
                <div
                  onClick={() => (window.location.href = '/home')}
                  className="btn btn-danger w-50 text-white text-14"
                >
                  Back to Homepage
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </CreateBox>
  )
}

export default CreateAccountComponent
