import React, { Fragment, useState, useEffect, useRef } from 'react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import { DashboardBox } from './style'
import FooterComponent from '../layout/footer'
import TopbarComponent from '../layout/topbar'
import MenuComponent from '../layout/menu'
import TopbarNotiComponent from '../layout/topbarNotification'
import { MdOutlineWork } from 'react-icons/md'
import { HiViewGrid } from 'react-icons/hi'
import { BiCalendarStar, BiCalendar } from 'react-icons/bi'
import { useGoogleLogin } from '@react-oauth/google'
import { ga4Info } from '../../apis/integration/api'
import AlertComponent from '../layout/alert'
import { roleByAccountId, roleByWorkspaceId } from '../../apis/account/api'
import LoadingComponent from '../layout/loading'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'
import { planDetail } from '../../apis/plan/api'
import TabMenuOverview from './overview'
import TabMenuAcquisition from './acquisition'
import TabMenuEvent from './event'
import { useParams } from 'react-router-dom'
import * as echarts from 'echarts'

const { REACT_APP_REDIRECT } = process.env

const DashboardComponent = () => {
  const [isReset, setIsReset] = useState(false)
  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })
  const [activeAccountRole, setActiveAccountRole] = useState('')
  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')
  const [menuOverview, setMenuOverview] = useState(true)
  const [menuAcquisition, setMenuAcquisition] = useState(false)
  const [menuEvent, setMenuEvent] = useState(false)
  const [planInfo, setPlanInfo] = useState('')
  const [menuActive, setMenuActive] = useState('Overview')

  const { accountId, workspaceId } = useParams()
  const accessToken = localStorage.getItem('token')
  const [rangeDate, setRangeDate] = useState()

  const [propertyId, setPropertyId] = useState('')

  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [isLoadingEngagement, setIsLoadingEngagement] = useState(false)

  let yesterday = new Date()
  yesterday.setHours(0, 0, 0, 0)
  yesterday.setDate(yesterday.getDate() - 1)
  let prior = new Date().setDate(yesterday.getDate() - 29)
  prior = new Date(prior)
  prior.setHours(0, 0, 0, 0)

  const ggToken = localStorage.getItem('tokenGoogle')
  const fp = useRef(null)

  const [filterStartDate, setFilterStartDate] = useState(formatDate(prior))
  const [filterEndDate, setFilterEndDate] = useState(formatDate(yesterday))

  useEffect(() => {
    document.title = 'Dashboard | Tag Turbo'
    getRoleByAccountId()
    getRoleByWorkspaceId()
    let arrDate = []
    arrDate.push(prior)
    arrDate.push(yesterday)
    setRangeDate(arrDate)
    getGa4()
  }, [])

  const getGa4 = async () => {
    const response = await ga4Info(accessToken, workspaceId)
    if (response?.data?.integration_info?.measurement_id) {
      setPropertyId(
        response.data.integration_info.property_id.replace('properties/', ''),
      )
    }
  }
  function formatDate(inputDate) {
    const date = new Date(inputDate)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    return `${year}-${month}-${day}`
  }
  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setPlanInfo(data.plan_of_account_detail)
    }
  }
  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setActiveAccountRole(data.data.role)
      if (data.data.role !== 'None') {
        getPlanInfo()
      }
    } else {
      setIsLoadingPage(false)
    }
  }
  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setActiveWorkspaceRole(data.data.role)
      setIsLoadingPage(false)
    } else {
      setIsLoadingPage(false)
    }
  }

  const handleMenuTab = (menuId) => {
    setMenuActive(menuId)
    if (menuId === 'Overview') {
      setMenuOverview(true)
      setMenuAcquisition(false)
      setMenuEvent(false)
    } else if (menuId === 'Acquisition') {
      setMenuOverview(false)
      setMenuAcquisition(true)
      setMenuEvent(false)
    } else if (menuId === 'Event') {
      setMenuOverview(false)
      setMenuAcquisition(false)
      setMenuEvent(true)
    }
  }

  const datePickr = (date) => {
    setRangeDate(date)
    const ggToken = localStorage.getItem('tokenGoogle')
    if (date[1]) {
      let now = new Date()
      let startDate = new Date(date[0])
      startDate.setSeconds(now.getSeconds())
      let endDate = new Date(date[1])
      let sDate = startDate //formatDate(startDate)
      let eDate = formatDate(endDate)
      setFilterStartDate(sDate)
      setFilterEndDate(eDate)
    }
  }

  function formatDate(inputDate) {
    const date = new Date(inputDate)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  return (
    <DashboardBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarComponent
                isPublish={true}
                resetList={isReset}
                callBackFn={''}
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
              />
              <TopbarFreeNotiComponent planInfo={planInfo} />

              <TopbarNotiComponent />

              <MenuComponent
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
                planInfo={planInfo}
              />
              {showAlert.isShow && (
                <AlertComponent
                  messageAlert={showAlert.msg}
                  status={showAlert.status}
                  alertType={showAlert.status ? 'alert-success' : 'alert-error'}
                />
              )}
              <div className="content-menu content-scroll ">
                <div className="container-md animate fadeIn one p-4 min-h-82">
                  {/* {!propertyId} */}
                  <div className="content">
                    {!propertyId ? (
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className={`card`}>
                              <div className="card-body p-5">
                                <p className="text-16 text-semibold text-center">
                                  Data Sync is Required for Dashboard Access
                                </p>
                                <p className="text-14 text-center">
                                  To access dashboard data, please ensure
                                  synchronization with your Google Analytics
                                  account.
                                </p>
                                <div className="text-center">
                                  <button
                                    onClick={() =>
                                      (window.location.href = `/integration/${accountId}/${workspaceId}`)
                                    }
                                    className="btn badge-blue text-14 text-semibold px-4 mx-3"
                                  >
                                    Go to Integrations
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Fragment>
                        <div className="d-flex justify-content-between item-center mb-4">
                          <div className="col-6">
                            <h4 className="m-0 text-20 text-semibold">
                              Dashboard
                            </h4>
                          </div>
                          <div className="col-3 text-right">
                            <div className="input-group">
                              <div
                                className="input-group-text "
                                onClick={() => {
                                  fp.current.flatpickr.open()
                                }}
                              >
                                <BiCalendar />
                              </div>
                              <Flatpickr
                                ref={fp}
                                placeholder="YYYY-MM-DD - YYYY-MM-DD"
                                options={{
                                  mode: 'range',
                                  dateFormat: 'Y-m-d',
                                  maxDate: yesterday,
                                }}
                                value={rangeDate}
                                onChange={(date) => datePickr(date)}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div id="Dashboard">
                          <div className="">
                            <ul className="nav nav-tabs" role="tablist">
                              <li className="nav-item">
                                <a
                                  className={`nav-link ${
                                    menuOverview ? 'active' : ''
                                  } `}
                                  data-toggle="tab"
                                  onClick={() => handleMenuTab('Overview')}
                                >
                                  <HiViewGrid className="mx-1" />
                                  Overview
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className={`nav-link ${
                                    menuAcquisition ? 'active' : ''
                                  } `}
                                  data-toggle="tab"
                                  onClick={() => handleMenuTab('Acquisition')}
                                >
                                  <MdOutlineWork className="mx-1" /> Acquisition
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className={`nav-link ${
                                    menuEvent ? 'active' : ''
                                  } `}
                                  data-toggle="tab"
                                  onClick={() => handleMenuTab('Event')}
                                >
                                  <BiCalendarStar className="mx-1" /> Event
                                </a>
                              </li>
                            </ul>

                            <div className="tab-content">
                              <div
                                style={{
                                  display:
                                    menuActive === 'Overview'
                                      ? 'block'
                                      : 'none',
                                }}
                              >
                                <TabMenuOverview
                                  accountId={accountId}
                                  workspaceId={workspaceId}
                                  propertyId={propertyId}
                                  menuOverview={menuOverview}
                                  filterStartDate={filterStartDate}
                                  filterEndDate={filterEndDate}
                                />
                              </div>
                              <div
                                style={{
                                  display:
                                    menuActive === 'Acquisition'
                                      ? 'block'
                                      : 'none',
                                }}
                              >
                                <TabMenuAcquisition
                                  workspaceId={workspaceId}
                                  propertyId={propertyId}
                                  menuAcquisition={menuAcquisition}
                                  filterStartDate={filterStartDate}
                                  filterEndDate={filterEndDate}
                                  menuActive={menuActive}
                                />
                              </div>
                              <div
                                style={{
                                  display:
                                    menuActive === 'Event' ? 'block' : 'none',
                                }}
                              >
                                <TabMenuEvent
                                  workspaceId={workspaceId}
                                  propertyId={propertyId}
                                  menuAcquisition={menuAcquisition}
                                  filterStartDate={filterStartDate}
                                  filterEndDate={filterEndDate}
                                  menuActive={menuActive}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                  <div className="my-3">
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </DashboardBox>
  )
}

export default DashboardComponent
