import React, { Fragment, useState, useEffect, useRef } from 'react'
import { BsThreeDotsVertical } from '@react-icons/all-files/bs/BsThreeDotsVertical'
import Modal from 'react-modal'
import { Link, useParams } from 'react-router-dom'
import {
  formatDateShortMonth,
  numberWithCommas,
  numberWithToFixed,
} from '../../helper/helper'
import { BillingBox, RadioBox, TableBox } from './style'
import TopbarNotiComponent from '../layout/topbarNotification'
import { planDetail } from '../../apis/plan/api'

import FooterComponent from '../layout/footer'
import MenuSettingComponent from '../layout/menusetting'
import {
  MdError,
  MdWarning,
  MdClose,
  MdNavigateNext,
  MdOutlineFileDownload,
} from 'react-icons/md'
import Select, { ActionMeta, OnChangeValue, StylesConfig } from 'react-select'
import TopbarSettingsComponent from '../layout/topbarworkspacesettings'
import { accountById } from '../../apis/home/api'
import {
  billingInfo,
  updateBillingInfo,
  paymentHistory,
  downloadingPaymentHistory,
} from '../../apis/billing/api'
import {
  allCountry,
  editAccountSetting,
  deleteAccount,
  roleByAccountId,
  roleByWorkspaceId,
} from '../../apis/account/api'
import AlertComponent from '../layout/alert'
import DataTable, { createTheme } from 'react-data-table-component'
import LoadingComponent from '../layout/loading'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'

const BillingComponent = () => {
  const [accountDetail, setAccountDetail] = useState('')
  const [openPopupEditBillingInfo, setOpenPopupEditBillingInfo] =
    useState(false)

  const [isClickSave, setIsClickSave] = useState(false)
  const { accountId } = useParams()
  const { workspaceId } = useParams()

  const [accountName, setAccountName] = useState('')

  const [countryList, setCountryList] = useState([])
  const [dafaultCountry, setDafaultCountry] = useState([
    {
      value: 'Thailand',
      label: 'Thailand',
    },
  ])
  const [activeAccountRole, setActiveAccountRole] = useState('')
  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')
  const specialChars = /[||\\<“>"'‘\;&*%!+{}@#=?$^\[\]]/

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [taxNumber, setTaxNumber] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('Thailand')
  const [countryDisplay, setCountryDisplay] = useState('-')
  const [postalCode, setPostalCode] = useState('')

  const [inValidateFirstName, setValidateFirstName] = useState(false)
  const [inValidateLastName, setValidateLastName] = useState(false)
  const [inValidatePhoneNumber, setValidatePhoneNumber] = useState(false)
  const [inValidateAddress, setValidateAddress] = useState(false)
  const [inValidateCity, setValidateCity] = useState(false)
  const [inValidateCountry, setValidateCountry] = useState(false)
  const [inValidatePostalCode, setValidatePostalCode] = useState(false)
  const [billingHistoryList, setBillingHistoryList] = useState()
  const [planInfo, setPlanInfo] = useState('')
  const [isSuspended, setIsSuspended] = useState(false)
  const [isLoadingPage, setIsLoadingPage] = useState(true)

  const [isDisabled, setIsDisabled] = useState(true)

  const customStylesSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '695px',
    },
  }
  const accessToken = localStorage.getItem('token')

  useEffect(() => {
    document.title = 'Billing | Tag Turbo'
    setIsLoadingPage(true)
    getPlanInfo()
    getCountry(accessToken)
    getAccountById(accessToken, accountId)
    getBillingInfo(accessToken, accountId)
    getPaymentHistory(accessToken, accountId)
    getRoleByAccountId()
    if (workspaceId) {
      getRoleByWorkspaceId()
    }
  }, [])
  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setActiveAccountRole(data.data.role)
    }
  }
  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setPlanInfo(data.plan_of_account_detail)
      if (data.plan_of_account_detail.status === 'Suspended') {
        setIsSuspended(true)
      }
    }
  }
  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setActiveWorkspaceRole(data.data.role)
    }
  }

  const getBillingInfo = async () => {
    const { data, status } = await billingInfo(accessToken, accountId)
    if (status === 200) {
      setIsLoadingPage(false)
      const billingInfo = data.data.billingInfo
      setFirstName(billingInfo.first_name)
      setLastName(billingInfo.last_name)
      setPhoneNumber(billingInfo.phone)
      setCompanyName(billingInfo.company ? billingInfo.company : '')
      setTaxNumber(billingInfo.tax_id ? billingInfo.tax_id : '')
      setAddress(billingInfo.address)
      setCity(billingInfo.city)
      setCountry(billingInfo.country)
      setCountryDisplay(billingInfo.country)
      setPostalCode(billingInfo.postal_code)
    }
  }

  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })
  const handleAlert = (msg, status) => {
    setShowAlert({
      msg,
      status,
      isShow: true,
    })

    setTimeout(() => {
      setShowAlert({
        msg,
        status,
        isShow: false,
      })
    }, 10000)
  }

  const onChangeCountry = (e) => {
    setCountry(e.value)
    setIsDisabled(false)
  }

  const getCountry = async (token) => {
    const { data, status } = await allCountry(token)
    let country = data.country_list
    country.forEach(function (obj) {
      obj.value = obj.country_name
      obj.label = obj.country_name
      delete obj.country_name
      delete obj.id
    })
    if (status === 200) {
      setCountryList(country)
      getAccountById()
    }
  }

  const getAccountById = async () => {
    const { data, status } = await accountById(accessToken, accountId)
    if (status === 200) {
      setAccountName(data.data.name)
    }
  }

  const onSaveEdit = async () => {
    setIsDisabled(true)
    let isValidEdit = false
    setIsClickSave(true)
    if (!firstName) {
      setValidateFirstName(true)
      isValidEdit = false
    } else {
      setValidateFirstName(false)
      isValidEdit = true
    }
    if (!lastName) {
      setValidateLastName(true)
      isValidEdit = false
    } else {
      setValidateLastName(false)
      isValidEdit = true
    }
    if (!address) {
      setValidateAddress(true)
      isValidEdit = false
    } else {
      setValidateAddress(false)
      isValidEdit = true
    }
    if (!city) {
      setValidateCity(true)
      isValidEdit = false
    } else {
      setValidateCity(false)
      isValidEdit = true
    }
    if (!phoneNumber) {
      setValidatePhoneNumber(true)
      isValidEdit = false
    } else {
      setValidatePhoneNumber(false)
      isValidEdit = true
    }
    if (!postalCode) {
      setValidatePostalCode(true)
      isValidEdit = false
    } else {
      setValidatePostalCode(false)
      isValidEdit = true
    }
    if (isValidEdit) {
      setIsClickSave(true)
      let obj = {
        first_name: firstName,
        last_name: lastName,
        address: address,
        city: city,
        country: country,
        postal_code: postalCode,
        phone: phoneNumber,
        company: companyName ? companyName : '',
        tax_id: taxNumber ? taxNumber : '',
      }
      const { data, status } = await updateBillingInfo(
        accessToken,
        accountId,
        obj,
      )
      if (status === 200) {
        handleAlert('Billing information has been updated successfully.', true)
        setIsClickSave(true)
        setOpenPopupEditBillingInfo(false)
        getBillingInfo()
      } else {
        handleAlert('Account Setting has been updated false', false)
        setIsClickSave(false)
        setOpenPopupEditBillingInfo(true)
      }
    } else {
      setIsClickSave(false)
    }
  }

  const getPaymentHistory = async () => {
    const { data, status } = await paymentHistory(accessToken, accountId)
    let res = []
    if (
      data &&
      data.transactions &&
      data.transactions.length > 0 &&
      status === 200
    ) {
      let _tempData = {}
      for (let i = 0; i < data.transactions.length; i++) {
        _tempData = {
          billing: <span>{data.transactions[i].transaction_id}</span>,
          date: <span>{data.transactions[i].create_at}</span>,
          price: (
            <span>
              {numberWithCommas(data.transactions[i].payment_detail.amount)}{' '}
              {data.transactions[i].payment_detail.currency.toUpperCase()}
            </span>
          ),
          payment: <span>{data.transactions[i].payment_type}</span>,
          status: (
            <span
              className={`${data.transactions[i].status === 'successful' ? 'text-success' : data.transactions[i].status === 'pending' ? '' : 'text-danger'}`}
            >
              {data.transactions[i].status === 'successful'
                ? 'Paid'
                : data.transactions[i].status === 'pending'
                  ? ''
                  : 'Failed'}
            </span>
          ),
          receipt: (
            <span>
              {data.transactions[i].status === 'pending' ? (
                <Fragment />
              ) : (
                <MdOutlineFileDownload
                  className="text-18 text-primary cursor"
                  onClick={() =>
                    getDownloadingPaymentHistory(
                      data.transactions[i].transaction_id,
                    )
                  }
                />
              )}
            </span>
          ),
        }
        res.push(_tempData)
      }
      setBillingHistoryList(res)
    } else {
      // handleAlert("onReSubscribePlan false", false);
      setBillingHistoryList([])
    }
  }
  const getDownloadingPaymentHistory = async (id) => {
    const { status } = await downloadingPaymentHistory(
      accessToken,
      accountId,
      id,
    )
    if (status !== 200) {
      handleAlert('download false', false)
    }
  }

  const columns = [
    {
      name: 'Billing No.',
      selector: (row) => row.billing,
      sortable: false,
      width: '25%',
    },
    {
      name: 'Date',
      selector: (row) => row.date,
      sortable: false,
      width: '20%',
    },
    {
      name: 'Price',
      selector: (row) => row.price,
      sortable: false,
      width: '15%',
    },
    {
      name: 'Payment Method',
      selector: (row) => row.payment,
      sortable: false,
      width: '20%',
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: false,
      width: '10%',
    },
    {
      name: 'Receipt',
      selector: (row) => row.receipt,
      sortable: false,
    },
  ]
  return (
    <BillingBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          <TopbarSettingsComponent accountDetailFn={setAccountDetail} />
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarFreeNotiComponent planInfo={planInfo} />
              <TopbarNotiComponent
                planInfo={planInfo}
                activeAccountRole={activeAccountRole}
              />
              <MenuSettingComponent
                accountId={accountId}
                id={workspaceId}
                activeAccountRole={activeAccountRole}
                activeWorkspaceRole={activeWorkspaceRole}
              />
              {showAlert.isShow && (
                <AlertComponent
                  messageAlert={showAlert.msg}
                  status={showAlert.status}
                  alertType={showAlert.status ? 'alert-success' : 'alert-error'}
                />
              )}
              <div className="content-menu content-scroll ">
                <div className="container-md animate fadeIn one p-4 min-h-82">
                  <div className="content">
                    <div className="d-flex justify-content-between item-center mb-4">
                      <div className="col-6">
                        <h4 className="m-0 text-20 text-semibold">Billing</h4>
                      </div>
                    </div>

                    <div className="card p-2 border box-shadow-center bg-white mt-2">
                      <div className="card-body">
                        <p className="text-18 text-semibold">
                          Billing Information
                          {!isSuspended && (
                            <a
                              onClick={() => (
                                setOpenPopupEditBillingInfo(true),
                                setIsClickSave(false)
                              )}
                              className="text-primary text-14 text-semibold mx-3 text-decoration-none cursor"
                            >
                              Edit
                            </a>
                          )}
                        </p>
                        <TableBox>
                          <table>
                            <tbody>
                              <tr>
                                <td className="text-semibold">Name</td>
                                <td className="w-table">
                                  {firstName ? firstName : '-'}{' '}
                                  {lastName ? lastName : '-'}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-semibold">Company</td>
                                <td className="w-table">
                                  {companyName ? companyName : '-'}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-semibold">Address</td>
                                <td className="w-table">
                                  {address ? address : '-'}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-semibold">City</td>
                                <td className="w-table">{city ? city : '-'}</td>
                              </tr>
                              <tr>
                                <td className="text-semibold">Country</td>
                                <td>{countryDisplay ? countryDisplay : '-'}</td>
                              </tr>
                              <tr>
                                <td className="text-semibold">Postal Code</td>
                                <td className="w-table">
                                  {postalCode ? postalCode : '-'}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-semibold">Phone</td>
                                <td className="w-table">
                                  {phoneNumber ? phoneNumber : '-'}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-semibold">Tax ID</td>
                                <td className="w-table">
                                  {taxNumber ? taxNumber : '-'}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </TableBox>
                      </div>
                    </div>

                    <div className="card border box-shadow-center bg-white mt-2">
                      <div className="card-body p-0">
                        <p className="text-18 text-semibold p-4 m-0">
                          Billing History
                        </p>
                        <Fragment>
                          {billingHistoryList ? (
                            billingHistoryList.length > 0 ? (
                              <DataTable
                                pagination={true}
                                className="-fadeIn mt-0 none-overflow"
                                center={true}
                                columns={columns}
                                data={billingHistoryList}
                              // customStyles={tableStyles}
                              />
                            ) : (
                              <Fragment></Fragment>
                            )
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </Fragment>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-3">
                  <FooterComponent />
                </div>
              </div>
            </Fragment>
          )}

          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupEditBillingInfo}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupEditBillingInfo(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-primary">
                  <div className="p-1 text-white"></div>
                </div>
                <div className="modal-body p-4 ">
                  <p className="text-18 text-semibold">Billing Information</p>
                  <div className="row">
                    <div className="col-6">
                      <div id="firstName">
                        <label className="form-label">First Name *</label>
                        <input
                          type="text"
                          className={`form-control ${inValidateFirstName ? 'is-invalid' : ''
                            }`}
                          value={firstName}
                          onKeyPress={(event) => {
                            if (specialChars.test(event.key)) {
                              event.preventDefault()
                            }
                          }}
                          onChange={(e) => (
                            setFirstName(
                              !specialChars.test(e.target.value)
                                ? e.target.value
                                : '',
                            ),
                            setValidateFirstName(false),
                            setIsDisabled(false)
                          )}
                        />
                        <div className="col-lg-12 mb-3">
                          {inValidateFirstName && (
                            <span className="text-danger">
                              First name is required.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div id="card_cvv">
                        <label className="form-label">Last Name *</label>
                        <input
                          type="text"
                          className={`form-control ${inValidateLastName ? 'is-invalid' : ''
                            }`}
                          value={lastName}
                          onKeyPress={(event) => {
                            if (specialChars.test(event.key)) {
                              event.preventDefault()
                            }
                          }}
                          onChange={(e) => (
                            setLastName(
                              !specialChars.test(e.target.value)
                                ? e.target.value
                                : '',
                            ),
                            setValidateLastName(false),
                            setIsDisabled(false)
                          )}
                        />
                        <div className="col-lg-12 mb-3">
                          {inValidateLastName && (
                            <span className="text-danger">
                              Last Name is required.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="company_name" className="mt-2">
                    <label className="form-label">Company</label>
                    <input
                      type="text"
                      className={`form-control`}
                      value={companyName}
                      onKeyPress={(event) => {
                        if (specialChars.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      onChange={(e) => (
                        setCompanyName(
                          !specialChars.test(e.target.value)
                            ? e.target.value
                            : '',
                        ),
                        setIsDisabled(false)
                      )}
                    />
                  </div>
                  <div id="address" className="mt-3">
                    <label className="form-label">Address *</label>
                    <input
                      type="text"
                      className={`form-control ${inValidateAddress ? 'is-invalid' : ''
                        }`}
                      value={address}
                      onKeyPress={(event) => {
                        if (specialChars.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      onChange={(e) => (
                        setAddress(
                          !specialChars.test(e.target.value)
                            ? e.target.value
                            : '',
                        ),
                        setValidateAddress(false),
                        setIsDisabled(false)
                      )}
                    />
                    {inValidateAddress && (
                      <span className="text-danger">Address is required.</span>
                    )}
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div id="city">
                        <label className="form-label">City *</label>
                        <input
                          type="text"
                          className={`form-control ${inValidateCity ? 'is-invalid' : ''
                            }`}
                          value={city}
                          onKeyPress={(event) => {
                            if (specialChars.test(event.key)) {
                              event.preventDefault()
                            }
                          }}
                          onChange={(e) => (
                            setCity(
                              !specialChars.test(e.target.value)
                                ? e.target.value
                                : '',
                            ),
                            setValidateCity(false),
                            setIsDisabled(false)
                          )}
                        />
                        <div className="col-lg-12 mb-3">
                          {inValidateCity && (
                            <span className="text-danger">
                              City is required.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div id="country">
                        <label className="form-label">Country *</label>

                        {dafaultCountry.length > 0 && (
                          <Select
                            value={{
                              value: country,
                              label: country,
                            }}
                            options={countryList}
                            className="mb-3"
                            onChange={onChangeCountry}
                          />
                        )}

                        <div className="col-lg-12 mb-3">
                          {inValidateCountry && (
                            <span className="text-danger">
                              Country is required.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div id="postal">
                        <label className="form-label">Postal code *</label>
                        <input
                          type="text"
                          className={`form-control ${inValidatePostalCode ? 'is-invalid' : ''
                            }`}
                          value={postalCode}
                          onKeyPress={(event) => {
                            if (specialChars.test(event.key)) {
                              event.preventDefault()
                            }
                          }}
                          onChange={(e) => (
                            setPostalCode(
                              !specialChars.test(e.target.value)
                                ? e.target.value
                                : '',
                            ),
                            setValidatePostalCode(false),
                            setIsDisabled(false)
                          )}
                        />
                        <div className="col-lg-12 mb-3">
                          {inValidatePostalCode && (
                            <span className="text-danger">
                              Postal is required.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div id="phone">
                        <label className="form-label">Phone *</label>
                        <input
                          type="text"
                          className={`form-control ${inValidatePhoneNumber ? 'is-invalid' : ''
                            }`}
                          value={phoneNumber}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault()
                            }
                          }}
                          onChange={(e) => (
                            setPhoneNumber(
                              /[0-9]/.test(e.target.value)
                                ? e.target.value
                                : '',
                            ),
                            setValidatePhoneNumber(false),
                            setIsDisabled(false)
                          )}
                        />
                        <div className="col-lg-12 mb-3">
                          {inValidatePhoneNumber && (
                            <span className="text-danger">
                              Phone Number is required.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tax_id">
                    <label className="form-label">Tax ID </label>
                    <input
                      type="text"
                      className={`form-control `}
                      value={taxNumber}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      maxLength={country === 'Thailand' ? '13' : '99'}
                      onChange={(e) => (
                        setTaxNumber(e.target.value), setIsDisabled(false)
                      )}
                    />
                  </div>
                </div>
                <div className="modal-footer justify-content-end px-4 pb-3">
                  <div
                    className="text-primary mx-3 cursor"
                    onClick={() => (
                      getBillingInfo(),
                      setOpenPopupEditBillingInfo(false),
                      setIsDisabled(true)
                    )}
                  >
                    Cancel
                  </div>


                  <button type="button"
                    onClick={() => onSaveEdit()}
                    className="btn btn-primary btn-load"
                    disabled={isDisabled ? true : isClickSave}>
                    <span className="flex-grow-1  d-flex align-items-center justify-content-center">
                      {isClickSave && <span className="spinner-border flex-shrink-0 mx-2" role="status"></span>}
                      Save
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </BillingBox>
  )
}

export default BillingComponent
