import React, { Fragment, useState, useEffect, useRef } from 'react'
import { VersionsBox, SubscriptionBox, ModalToggle } from './style'
import FooterComponent from '../layout/footer'
import Select from 'react-select'
import { allPlan } from '../../apis/plan/api'

import { useParams, useNavigate } from 'react-router-dom'
import { numberWithCommas } from '../../helper/helper'
import Modal from 'react-modal'
import { allCurrency, checkAlreadyFreePlan } from '../../apis/account/api'
import { TopbarBox, MenuBox } from '../layout/style'
import {
  MdClose,
  MdHome,
  MdSell,
  MdWork,
  MdManageHistory,
  MdExtension,
  MdArrowRightAlt,
} from 'react-icons/md'
import { subscriptionDetail, checkOneDollar, checkChangePlan } from '../../apis/subscription/api'
import LoadingComponent from '../layout/loading'
const AccountSuspendedComponent = () => {
  const { accountId, workspaceId } = useParams()
  const [openPopupChangePlan, setOpenPopupChangePlan] = useState(false)
  const [currencyList, setCurrencyList] = useState([])
  const [currency, setCurrency] = useState('')
  const [preriod, setPreriod] = useState(false)
  const [planList, setPlanList] = useState([])
  const [subscriptionInfo, setSubscriptionInfo] = useState('')
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const accessToken = localStorage.getItem('token')
  const [planNum, setPlanNum] = useState('1')
  const [accountName, setAccountName] = useState('')
  const [packageTrial, setPackageTrial] = useState(false)
  const [pricePackageTrial, setPricePackageTrial] = useState([])
  const [languagesTh, setLanguagesTh] = useState(false)
  const [isTrial, setIsTrial] = useState(false)
  const [checkFreePlan, setCheckFreePlan] = useState('')
  const [openPopupDowngradeLimit, setOpenPopupDowngradeLimit] =
    useState(false)
  const [loading, setLoading] = useState({ isLoad: false, plan: '' })

  const customStylesPopupXLG = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '1210px',
      maxHeight: ' 700px',
    },
  }
  const customStylesPopupSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '511px',
    },
  }
  const payment_event = localStorage.getItem('payment_event')
  const navigate = useNavigate()
  useEffect(() => {
    document.title = 'Account Suspended | Tag Turbo'
    setIsLoadingPage(true)
    getCurrency(accessToken)
    getPlan(accessToken)
    getSubscriptionInfo(accessToken, accountId);

    const languages = navigator.languages
    let filterLang = languages.filter((item) => item === 'th' || item === 'TH')
    if (filterLang.length >= 1) {
      setLanguagesTh(true)
    }
    getCheckAlreadyFreePlan(accessToken)
  }, [])
  const getCheckAlreadyFreePlan = async (token) => {
    const { data, status } = await checkAlreadyFreePlan(token)
    if (status === 200) {
      setCheckFreePlan(data.has_free_plan)
    }
  }
  const getSubscriptionInfo = async () => {
    const { data, status } = await subscriptionDetail(accessToken, accountId)
    if (status === 200) {
      let planNum = 0 // plan
      if (data.subscriptionDetail.plan === 'Basic') {
        planNum = 1
      } else if (data.subscriptionDetail.plan === 'Pro') {
        planNum = 2
      } else if (data.subscriptionDetail.plan === 'Ultimate') {
        planNum = 3
      }
      setPlanNum(planNum)
      setSubscriptionInfo(data.subscriptionDetail)

      if (data?.subscriptionDetail?.subscription_type === 'trial') {
        const languages = navigator.languages
        let filterLang = languages.filter(
          (item) => item === 'th' || item === 'TH',
        )
        if (filterLang.length >= 1) {
          setCurrency('thb')
        } else {
          setCurrency('usd')
        }
      } else {
        setCurrency(data?.subscriptionDetail?.currency || 'thb')
      }

      setAccountName(data.subscriptionDetail.account_name)
      if (
        data?.subscriptionDetail?.status === 'Suspended' &&
        !data?.subscriptionDetail?.is_active
      ) {
        getCheckOneDollar()
      }
      setIsTrial(data?.subscriptionDetail?.subscription_type === 'trial')
    }
  }

  const getCheckOneDollar = async () => {
    const { data, status } = await checkOneDollar(accessToken, accountId)
    if (status === 200) {
      setPackageTrial(data?.check_one_dollar)
      setPricePackageTrial(data?.price)
    }
  }

  const getCurrency = async (token) => {
    const { data, status } = await allCurrency(token)
    let currency = data.currency_list
    currency.forEach(function (obj) {
      obj.value = obj.symbol
      obj.currency = obj.id
      obj.label = (
        <div className="select-flag">
          <img
            className="-flag"
            src={`/assets/img/Flag-${obj.id.toUpperCase()}.png`}
          />{' '}
          {obj.id.toUpperCase()}
        </div>
      )
      delete obj.id
      delete obj.status
      delete obj.symbol
      delete obj.country
    })
    if (status === 200) {
      setCurrencyList(currency)
    }
  }
  const getPlan = async (token) => {
    const { data, status } = await allPlan(token)
    setIsLoadingPage(false)
    setPlanList(data.plan_list)
    setTimeout(() => {
      setOpenPopupChangePlan(true)
    }, 200)
  }
  const getChargeDetailChangePlan = (plan) => {
    let obj_change_plan = {
      plan_id: plan.id,
      plan_type: preriod ? 'year' : 'month',
      currency: currency?.currency ? currency.currency : currency,
      plan_name: plan.plan_name,
      account_name: accountName,
      package_trial: packageTrial,
      plan_price: preriod ? plan.price_per_year : plan.price_per_month,
    }
    localStorage.setItem('plan_datail', JSON.stringify(obj_change_plan))
    localStorage.setItem('payment_event', 'Change_plan_Suspended')
    window.location.href = `/checkout/${accountId}${workspaceId ? '/' + workspaceId : ''
      }`
    localStorage.removeItem('event_type')
  }
  const handlePreriod = (event) => {
    setPreriod(event.target.checked)
  }
  const handleClosePopup = () => {
    if (payment_event === 'Expired') {
      navigate(-1)
      localStorage.removeItem('payment_event')
    } else {
      window.location.href = '/home'
      localStorage.removeItem('payment_event')
    }
  }
  const handleOpenChat = () => {
    // window.tidioChatApi.open()
    // window.tidioChatApi.display(true)
    window
      .open(
        'https://meetings.hubspot.com/hataipatt/tag-turbo-support',
        '_blank',
      )
      .focus()
  }

  const onCheckChangePlan = async (plan) => {
    setLoading({
      isLoad: true,
      plan: plan.plan_name,
    })
    let obj = {
      plan_id: plan.id,
      plan_type: preriod ? 'year' : 'month',
    }
    const { data, status } = await checkChangePlan(accessToken, accountId, obj)
    if (status === 200) {
      if (data.can_change_plan) {
        getChargeDetailChangePlan(plan)
        setLoading({
          isLoad: false,
          plan: '',
        })
      } else {
        setOpenPopupDowngradeLimit(true)
        setLoading({
          isLoad: false,
          plan: '',
        })
      }
    }
  }

  return (
    <VersionsBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          <TopbarBox>
            <div className="topbar-box">
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-start">
                  <div
                    className="cursor w-logo"
                    onClick={() => (window.location.href = '/home')}
                  >
                    <img src="/assets/img/logo-Tagtb.svg" className="img-180" />
                  </div>

                  <Fragment>
                    <div className="border-end px-2 innerPanel"></div>
                    <div className="d-flex mx-3 justify-content-between rounded innerPanel"></div>
                  </Fragment>
                </div>

                <div className="d-flex item-center">
                  <Fragment>
                    <button
                      type="button"
                      className="btn badge-blue text-14 text-semibold px-4 mx-3"
                    >
                      Publish
                    </button>
                    {/* <MdNotifications className="mx-2 text-20 text-grey" /> */}
                  </Fragment>
                </div>
              </div>
            </div>
          </TopbarBox>
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <MenuBox>
                <div className="sidenav">
                  <div className="d-flex align-items-start flex-column mb-3">
                    <div className="mb-auto p-2 w-100">
                      <div className="mt-0">
                        <a
                          href="#Home"
                          onClick={() => (window.location.href = '/home')}
                        >
                          {' '}
                          <MdHome className="mx-2" /> Home
                        </a>
                        <a>
                          {' '}
                          <MdSell className="mx-2" /> Tags
                        </a>
                        <a>
                          {' '}
                          <MdWork className="mx-2" /> Versions
                        </a>

                        <a>
                          {' '}
                          <MdManageHistory className="mx-2" /> Activity
                        </a>

                        <a>
                          {' '}
                          <MdExtension className="mx-2" /> Integrations
                        </a>
                      </div>
                    </div>
                    {/* <div className="menu-bt">
                      <div className={`card card-bg-light mb-4`}>
                        <div className="card-header card-bg-light d-flex justify-content-between item-center border-bottom-0 text-14">
                          <span className="text-semibold">Used space </span>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="card-body card-bg-light text-14">
                          Your account has used 80% of your quotas. Need more?
                          <div
                            className="progress my-2"
                            style={{ height: "8px" }}
                          >
                            <div
                              className="progress-bar bg-warning"
                              role="progressbar"
                              aria-valuenow="75"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: "75%" }}
                            ></div>
                          </div>
                          <p>
                            Dismiss{" "}
                            <span className="text-warning">Upgrade plan</span>
                          </p>
                        </div>
                      </div>
                      <a>
                        {" "}
                        <MdHeadsetMic className="mx-2" /> Support
                      </a>

                      <a>
                        {" "}
                        <MdSettings className="mx-2" /> Settings
                      </a>
                    </div> */}
                  </div>
                </div>
              </MenuBox>

              <div className="content-menu content-scroll ">
                <div className="container-md animate fadeIn one p-4 min-h-82"></div>

                <FooterComponent />
              </div>
              <Modal
                shouldCloseOnOverlayClick={false}
                closeTimeoutMS={200}
                ariaHideApp={false}
                isOpen={openPopupChangePlan}
                contentLabel="modal"
                style={customStylesPopupXLG}
                onRequestClose={() => setOpenPopupChangePlan(false)}
                className={'modal-Publish-box animate-5 fadeIn two'}
              >
                <SubscriptionBox>
                  <div className="modal-dialog modal-md ">
                    <div className="modal-content">
                      <div className="modal-header bg-primary">
                        <div className="p-1 text-white">
                          <p className="py-0 mb-0 text-20"></p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end px-2">
                        <div>
                          <MdClose
                            className="cursor text-secondary close-icon-30"
                            onClick={() => handleClosePopup()}
                          />
                        </div>
                      </div>

                      <div className="modal-body p-4 text-center">
                        <p className="text-30 text-semibold">
                          {subscriptionInfo.status === 'Suspended'
                            ? 'Your subscription has expired'
                            : 'Choose Plan'}{' '}
                        </p>
                        <p className="text-14">
                          In order to ensure that you can continue using. Please
                          select a plan and complete payment.
                        </p>
                        <div>
                          <div className="card-body">
                            <div className="d-flex justify-content-center mb-3">
                              <div className="col-4 justify-content-between item-center d-flex">
                                {isTrial ? (
                                  <Fragment>
                                    {currencyList &&
                                      currencyList.length > 0 && (
                                        <Select
                                          options={currencyList}
                                          defaultValue={
                                            languagesTh
                                              ? currencyList[0]
                                              : currencyList[1]
                                          }
                                          onChange={(e) => (
                                            setCurrency(e.currency),
                                            setLanguagesTh(!languagesTh)
                                          )}
                                        />
                                      )}
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <div className="flag-box">
                                      <div className="select-flag">
                                        <img
                                          className="-flag"
                                          src={`/assets/img/Flag-${currency.toUpperCase()}.png`}
                                        />{' '}
                                        {currency.toUpperCase()}
                                      </div>
                                    </div>
                                  </Fragment>
                                )}

                                <ModalToggle>
                                  <span
                                    className={`px-2 ${!preriod ? 'text-semibold' : ''
                                      }`}
                                  >
                                    Monthly
                                  </span>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => handlePreriod(e)}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span
                                    className={` px-2 ${preriod ? 'text-semibold' : ''
                                      }`}
                                  >
                                    Yearly
                                  </span>
                                </ModalToggle>
                                <span className="badge-saving">Save 20%</span>
                              </div>
                            </div>
                            <div className="row space-between">
                              {planList.length > 0 &&
                                planList.map((item, i) => {
                                  return (
                                    <Fragment>
                                      {item.plan_name !== 'Free' ? (
                                        <Fragment>
                                          <div
                                            className={`col-3   pb-0 px-2`}
                                            key={i}
                                          // onClick={() => setSelectedPlan(item)}
                                          >
                                            <div
                                              className={`card plan-box ${item.plan_name === 'Pro'
                                                ? 'plan-box-border'
                                                : 'mt-plan-box border-card-plan'
                                                }`}
                                            >
                                              {item.plan_name === 'Pro' ? (
                                                <div className="card-header text-center bg-most p-2 text-white text-semibold text-uppercase text-12">
                                                  Most Popular
                                                </div>
                                              ) : (
                                                <div
                                                  className={`${subscriptionInfo?.plan ===
                                                    item.plan_name
                                                    ? ''
                                                    : 'body-plan-box-gray'
                                                    }`}
                                                ></div>
                                              )}
                                              <div
                                                className={` card-body text-left line-height-2 pt-4 ${item.plan_name === 'Pro'
                                                  ? 'border-primary'
                                                  : ''
                                                  }`}
                                              >
                                                <div className="d-flex ">
                                                  <p
                                                    className={`badge text-center text-uppercase text-12 ${i === 0
                                                      ? 'badge-default'
                                                      : i === 1
                                                        ? 'badge-blue'
                                                        : i === 2
                                                          ? 'badge-purple'
                                                          : 'badge-gray'
                                                      } `}
                                                  >
                                                    {item.plan_name}
                                                  </p>
                                                  {subscriptionInfo?.plan ===
                                                    item.plan_name && (
                                                      <p
                                                        className={`badge badge-outline-green text-center mx-3`}
                                                        style={{
                                                          padding: '3px 9px',
                                                        }}
                                                      >
                                                        Your Current Plan
                                                      </p>
                                                    )}
                                                </div>
                                                <b className="text-24">
                                                  {/* // currency show ฿ or $ /// */}
                                                  {item.price_per_year &&
                                                    item.price_per_month
                                                    ? currency?.value ||
                                                    currencyList[
                                                      currency === 'thb'
                                                        ? 0
                                                        : 1
                                                    ]?.value
                                                    : ''}
                                                  {/* // price currency = thb = 0 /// */}
                                                  {/* // preriod true = year /// */}
                                                  {preriod
                                                    ? numberWithCommas(
                                                      item.price_per_year?.[
                                                      currency
                                                      ] / 12 || 'Custom',
                                                    )
                                                    : packageTrial
                                                      ? item.price_per_month
                                                        ? pricePackageTrial?.[
                                                        currency
                                                        ]
                                                        : ''
                                                      : item.price_per_month
                                                        ? numberWithCommas(
                                                          item
                                                            .price_per_month[
                                                          currency
                                                          ],
                                                        )
                                                        : ''}
                                                </b>{' '}
                                                { }
                                                {item.price_per_year ||
                                                  item.price_per_month ? (
                                                  <span className="text-12">
                                                    <span className="text-uppercase">
                                                      {' '}
                                                      {currency.currency ||
                                                        currencyList[
                                                          currency === 'thb'
                                                            ? 0
                                                            : 1
                                                        ]?.currency}
                                                    </span>{' '}
                                                    {packageTrial
                                                      ? '/ first month'
                                                      : '/ month'}
                                                  </span>
                                                ) : (
                                                  ''
                                                )}
                                                {item.price_per_year &&
                                                  item.price_per_month ? (
                                                  <p className="text-12 text-grey mb-1">
                                                    {preriod ? (
                                                      <Fragment>
                                                        Billed yearly or{' '}
                                                        {numberWithCommas(
                                                          item
                                                            .price_per_month?.[
                                                          currency?.currency ||
                                                          currencyList[
                                                            currency ===
                                                              'thb'
                                                              ? 0
                                                              : 1
                                                          ]?.currency
                                                          ],
                                                        )}{' '}
                                                        <span className="text-uppercase">
                                                          {' '}
                                                          {currency.currency ||
                                                            currencyList[
                                                              currency === 'thb'
                                                                ? 0
                                                                : 1
                                                            ]?.currency}
                                                        </span>{' '}
                                                        / month{' '}
                                                      </Fragment>
                                                    ) : packageTrial ? (
                                                      <span>
                                                        from{' '}
                                                        <del>
                                                          {' '}
                                                          {numberWithCommas(
                                                            item
                                                              ?.price_per_month?.[
                                                            currency?.currency ||
                                                            currencyList[
                                                              currency ===
                                                                'thb'
                                                                ? 0
                                                                : 1
                                                            ]?.currency
                                                            ] || 'Custom',
                                                          )}{' '}
                                                          THB / month
                                                        </del>
                                                      </span>
                                                    ) : (
                                                      'Monthly Price'
                                                    )}{' '}
                                                  </p>
                                                ) : (
                                                  <p className="text-12 text-grey mb-1">
                                                    For teams, businesses
                                                    <br />
                                                    organizations wanting to
                                                    maximize <br />
                                                    growth and scale fast.
                                                  </p>
                                                )}
                                                <div className="mb-2">
                                                  {item.price_per_year &&
                                                    item.price_per_month ? (
                                                    <img
                                                      src="/assets/img/Check_Blue.png"
                                                      className="mr-3 img-20"
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/assets/img/Check_Gold.png"
                                                      className="mr-3 img-20"
                                                    />
                                                  )}

                                                  <span className="text-12">
                                                    {item.visitors === 0
                                                      ? 'Custom'
                                                      : numberWithCommas(
                                                        item.visitors,
                                                      )}{' '}
                                                    monthly quota of event
                                                  </span>
                                                </div>
                                                <div className="mb-2 d-inline-flex">
                                                  {item.price_per_year &&
                                                    item.price_per_month ? (
                                                    <img
                                                      src="/assets/img/Check_Blue.png"
                                                      className="mr-3 img-20-w-h"
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/assets/img/Check_Gold.png"
                                                      className="mr-3 img-20-w-h"
                                                    />
                                                  )}
                                                  <span className="text-12">
                                                    Tracking channel support :{' '}
                                                    <br />
                                                    {item.ga4_integration &&
                                                      'Google Analytics 4'}
                                                    {item.google_ads_integration &&
                                                      ', Google Ads,'}
                                                    <br />
                                                    {item.meta_integration &&
                                                      ' Meta Ads'}
                                                    {item.tiktok_integration &&
                                                      ', TikTok Ads'}
                                                  </span>
                                                </div>
                                                <div className="mb-2">
                                                  {item.price_per_year &&
                                                    item.price_per_month ? (
                                                    <img
                                                      src="/assets/img/Check_Blue.png"
                                                      className="mr-3 img-20"
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/assets/img/Check_Gold.png"
                                                      className="mr-3 img-20"
                                                    />
                                                  )}
                                                  <span className="text-12">
                                                    Google Analytics Dashboard
                                                  </span>
                                                </div>
                                                <div className="mb-2">
                                                  {item.price_per_year &&
                                                    item.price_per_month ? (
                                                    <img
                                                      src="/assets/img/Check_Blue.png"
                                                      className="mr-3 img-20"
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/assets/img/Check_Gold.png"
                                                      className="mr-3 img-20"
                                                    />
                                                  )}
                                                  <span className="text-12">
                                                    {item.user_per_account === 0
                                                      ? 'Unlimited'
                                                      : item.user_per_account}{' '}
                                                    Account User
                                                  </span>
                                                </div>
                                                <div className="mb-2">
                                                  {item.price_per_year &&
                                                    item.price_per_month ? (
                                                    <img
                                                      src="/assets/img/Check_Blue.png"
                                                      className="mr-3 img-20"
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/assets/img/Check_Gold.png"
                                                      className="mr-3 img-20"
                                                    />
                                                  )}
                                                  <span className="text-12">
                                                    {
                                                      item.workspace_limit_per_account
                                                    }{' '}
                                                    Workspaces
                                                  </span>
                                                </div>
                                                <div className="mb-2">
                                                  {item.price_per_year &&
                                                    item.price_per_month ? (
                                                    <img
                                                      src="/assets/img/Check_Blue.png"
                                                      className="mr-3 img-20"
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/assets/img/Check_Gold.png"
                                                      className="mr-3 img-20"
                                                    />
                                                  )}
                                                  <span className="text-12">
                                                    {item.support_over_live_chat
                                                      ? 'Email and Live chat Support'
                                                      : 'Email Support'}
                                                  </span>
                                                </div>
                                                <div className="mb-2 d-inline-flex">
                                                  {item.price_per_year &&
                                                    item.price_per_month ? (
                                                    <Fragment>
                                                      {item.dedicated_training_session ? (
                                                        <img
                                                          src="/assets/img/Check_Blue.png"
                                                          className="mr-3 img-20-w-h"
                                                        />
                                                      ) : (
                                                        <img
                                                          src="/assets/img/Check_dis.png"
                                                          className="mr-3 img-20-w-h"
                                                        />
                                                      )}
                                                    </Fragment>
                                                  ) : (
                                                    <img
                                                      src="/assets/img/Check_Gold.png"
                                                      className="mr-3 img-20-w-h"
                                                    />
                                                  )}
                                                  <span
                                                    className={`text-12 ${item.dedicated_training_session
                                                      ? ''
                                                      : 'text-grey'
                                                      }`}
                                                  >
                                                    Designated Customer Success
                                                    Manager
                                                  </span>
                                                </div>
                                                <div className="mb-2">
                                                  {item.price_per_year &&
                                                    item.price_per_month ? (
                                                    <Fragment>
                                                      {item.dedicated_training_session ? (
                                                        <img
                                                          src="/assets/img/Check_Blue.png"
                                                          className="mr-3 img-20"
                                                        />
                                                      ) : (
                                                        <img
                                                          src="/assets/img/Check_dis.png"
                                                          className="mr-3 img-20"
                                                        />
                                                      )}
                                                    </Fragment>
                                                  ) : (
                                                    <img
                                                      src="/assets/img/Check_Gold.png"
                                                      className="mr-3 img-20"
                                                    />
                                                  )}
                                                  <span
                                                    className={`text-12 ${item.dedicated_training_session
                                                      ? ''
                                                      : 'text-grey'
                                                      }`}
                                                  >
                                                    Dedicated Training Session
                                                  </span>
                                                </div>
                                                <div className="plan-box-footer mt-4">


                                                  <div
                                                    className={`foot-btn ${planNum <= i + 1
                                                      ? ''
                                                      : 'cursor-no-drop'
                                                      }`}
                                                  >
                                                    {item.price_per_year ||
                                                      item.price_per_month ? (
                                                      <Fragment> </Fragment>
                                                    ) : (
                                                      <button
                                                        id={`suspended_contact_sales`}
                                                        type="button"
                                                        className={`btn w-100 badge-outline-gold `}
                                                        onClick={() =>
                                                          handleOpenChat()
                                                        }
                                                      >
                                                        Contact Sales{' '}
                                                        <MdArrowRightAlt className="text-20 " />
                                                      </button>
                                                    )}

                                                    {item.price_per_year ||
                                                      item.price_per_month ? (

                                                      <button
                                                        type="button"
                                                        id={`paynow_suspended_${item.plan_name}`}
                                                        onClick={() =>
                                                          onCheckChangePlan(
                                                            item
                                                          )
                                                        }
                                                        className="btn btn-primary w-100 btn-load"
                                                        disabled={loading.isLoad}
                                                      >
                                                        <span className="flex-grow-1  d-flex align-items-center justify-content-center">
                                                          {loading.plan ===
                                                            item.plan_name &&
                                                            loading.isLoad && (
                                                              <span
                                                                className="spinner-border flex-shrink-0 mx-2"
                                                                role="status"
                                                              ></span>
                                                            )}
                                                          Pay Now
                                                        </span>
                                                      </button>
                                                    ) : (
                                                      <Fragment />
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Fragment>
                                      ) : (
                                        <Fragment>
                                          {!checkFreePlan && (
                                            <div className="col-12  mt-3">
                                              <div className="card plan-box plan-box-borde p-4 px-5">
                                                <div className="row">
                                                  <div className="col-4 item-center text-left">
                                                    <p className="badge text-center mb-3 text-uppercase text-12 badge-free">
                                                      {item.plan_name}
                                                    </p>
                                                    <p className="text-24 d-flex item-center">
                                                      {' '}
                                                      <b>0</b>
                                                      <span className="text-14 text-uppercase px-2">
                                                        {' '}
                                                        {currency.currency ||
                                                          currencyList[
                                                            currency === 'thb'
                                                              ? 0
                                                              : 1
                                                          ]?.currency}
                                                      </span>
                                                    </p>
                                                    <p className="text-14 text-grey mb-0">
                                                      The quickest and easiest
                                                      way to start tracking{' '}
                                                      <br />
                                                      your website.
                                                    </p>
                                                  </div>
                                                  <div className="col-5 d-flex item-center text-left">
                                                    <div className="mx-3">
                                                      <div className="mb-3">
                                                        {item.price_per_year &&
                                                          item.price_per_month ? (
                                                          <img
                                                            alt="tagturbo"
                                                            src="/assets/img/Check_Blue.png"
                                                            className="mr-3 img-20"
                                                          />
                                                        ) : (
                                                          <img
                                                            alt="tagturbo"
                                                            src="/assets/img/Check_Gold.png"
                                                            className="mr-3 img-20"
                                                          />
                                                        )}

                                                        <span className="text-12">
                                                          {item.visitors === 0
                                                            ? 'Custom'
                                                            : numberWithCommas(
                                                              item.visitors,
                                                            )}{' '}
                                                          monthly quota of event
                                                        </span>
                                                      </div>

                                                      <div className="mb-3 d-inline-flex">
                                                        {item.price_per_year &&
                                                          item.price_per_month ? (
                                                          <img
                                                            src="/assets/img/Check_Blue.png"
                                                            className="mr-3 img-20-w-h"
                                                          />
                                                        ) : (
                                                          <img
                                                            src="/assets/img/Check_Gold.png"
                                                            className="mr-3 img-20-w-h"
                                                          />
                                                        )}
                                                        <span className="text-12">
                                                          Tracking channel
                                                          support : <br />
                                                          {item.ga4_integration &&
                                                            'Google Analytics 4'}
                                                          {item.google_ads_integration &&
                                                            ', Google Ads'}
                                                          <br />
                                                          {item.meta_integration &&
                                                            ' Meta Ads'}
                                                          {item.tiktok_integration &&
                                                            ', TikTok Ads'}
                                                        </span>
                                                      </div>

                                                      <div className="mb-0">
                                                        {item.price_per_year &&
                                                          item.price_per_month ? (
                                                          <img
                                                            alt="tagturbo"
                                                            src="/assets/img/Check_Blue.png"
                                                            className="mr-3 img-20"
                                                          />
                                                        ) : (
                                                          <img
                                                            alt="tagturbo"
                                                            src="/assets/img/Check_Gold.png"
                                                            className="mr-3 img-20"
                                                          />
                                                        )}
                                                        <span className="text-12">
                                                          Google Analytics
                                                          Dashboard
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="mx-3">
                                                      <div className="mb-3">
                                                        {item.price_per_year &&
                                                          item.price_per_month ? (
                                                          <img
                                                            alt="tagturbo"
                                                            src="/assets/img/Check_Blue.png"
                                                            className="mr-3 img-20"
                                                          />
                                                        ) : (
                                                          <img
                                                            alt="tagturbo"
                                                            src="/assets/img/Check_Gold.png"
                                                            className="mr-3 img-20"
                                                          />
                                                        )}
                                                        <span className="text-12">
                                                          {item.user_per_account ===
                                                            0
                                                            ? 'Unlimited'
                                                            : item.user_per_account}{' '}
                                                          Account User
                                                        </span>
                                                      </div>
                                                      <div className="mb-3">
                                                        {item.price_per_year &&
                                                          item.price_per_month ? (
                                                          <img
                                                            alt="tagturbo"
                                                            src="/assets/img/Check_Blue.png"
                                                            className="mr-3 img-20"
                                                          />
                                                        ) : (
                                                          <img
                                                            alt="tagturbo"
                                                            src="/assets/img/Check_Gold.png"
                                                            className="mr-3 img-20"
                                                          />
                                                        )}
                                                        <span className="text-12">
                                                          {
                                                            item.workspace_limit_per_account
                                                          }{' '}
                                                          Workspace
                                                        </span>
                                                      </div>
                                                      <div className="mb-0">
                                                        {item.price_per_year &&
                                                          item.price_per_month ? (
                                                          <img
                                                            alt="tagturbo"
                                                            src="/assets/img/Check_Blue.png"
                                                            className="mr-3 img-20"
                                                          />
                                                        ) : (
                                                          <img
                                                            alt="tagturbo"
                                                            src="/assets/img/Check_Gold.png"
                                                            className="mr-3 img-20"
                                                          />
                                                        )}
                                                        <span className="text-12">
                                                          {item.support_over_live_chat
                                                            ? 'Email and Live chat Support'
                                                            : 'Email Support'}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="col-3 d-flex item-center">
                                                    <Fragment>
                                                      <button
                                                        type="button"
                                                        id={`paynow_change_plan_${item.plan_name}`}
                                                        onClick={() =>
                                                          onCheckChangePlan(
                                                            item
                                                          )
                                                        }
                                                        className="btn btn-primary w-100 btn-load"
                                                        disabled={loading.isLoad}
                                                      >
                                                        <span className="flex-grow-1  d-flex align-items-center justify-content-center">
                                                          {loading.plan ===
                                                            item.plan_name &&
                                                            loading.isLoad && (
                                                              <span
                                                                className="spinner-border flex-shrink-0 mx-2"
                                                                role="status"
                                                              ></span>
                                                            )}
                                                          Start for Free
                                                        </span>
                                                      </button>
                                                    </Fragment>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </Fragment>
                                      )}
                                    </Fragment>
                                  )
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SubscriptionBox>
              </Modal>
              <Modal
                shouldCloseOnOverlayClick={false}
                closeTimeoutMS={200}
                ariaHideApp={false}
                isOpen={openPopupDowngradeLimit}
                contentLabel="modal"
                style={customStylesPopupSM}
                onRequestClose={() => setOpenPopupDowngradeLimit(false)}
                className={'modal-Publish-box animate-5 fadeIn two'}
              >
                <div className="modal-dialog modal-md ">
                  <div className="modal-content">
                    <div className="modal-header bg-danger">
                      <div className="p-1 text-white">
                        <p className="py-0 mb-0 text-20"></p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end px-2">
                      <div>
                        <MdClose
                          className="cursor text-secondary"
                          onClick={() => setOpenPopupDowngradeLimit(false)}
                        />
                      </div>
                    </div>
                    <div className="modal-body p-4 text-center">
                      <img className="mb-2" src="/assets/img/warning.png" />
                      <p className="text-20 text-danger text-semibold">
                        Unable to Downgrade: Limit Exceeded
                      </p>
                      <p className="text-14">
                        Your current usage exceeds the limits <br />of the selected plan.

                      </p>

                      <p className="text-14">
                        <b>You've reached one or more of the following limits:</b>
                      </p>



                      <div className=' d-flex-center'>
                        <ul className=' d-flex-start text-14'>
                          <li>Account user limit</li>
                          <li>Number of workspaces</li>
                          <li>Integration channels</li>
                        </ul>
                      </div>
                      <p className="text-14">
                        To continue using all your current features,<br /> please select a plan that accommodates your needs.
                      </p>
                    </div>
                    <div className="modal-footer justify-content-center px-5 pb-5">

                      <div
                        className="btn btn-danger w-50 text-white text-14"
                        onClick={() => setOpenPopupDowngradeLimit(false)}
                      >
                        Select Another Plan
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </Fragment>
          )}
        </div>
      </div>
    </VersionsBox>
  )
}

export default AccountSuspendedComponent
