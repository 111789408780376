import { Fragment, useEffect, useState, useMemo } from 'react'
import LoadingSMComponent from '../layout/loadingSM'
import { getGAReport } from '../../apis/dashboard/api'
import * as echarts from 'echarts'

import {
  numberWithCommas,
  countNumberOfDays,
  periodDateByDay,
  calculatePercentageChange,
  generateTimeWithSeconds,
} from '../../helper/helper'
import LoadingComponent from '../layout/loading'
import {
  MdTrendingUp,
  MdTrendingDown,
  MdWifiChannel,
  MdPersonAddAlt1,
} from 'react-icons/md'
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import ErrorIcon from '@mui/icons-material/Error'
import { TableDashboardBox, TableExpandDashboardBox } from './style'
const TabMenuAcquisition = (props) => {
  const [returningUser, setReturningUser] = useState('-')
  const [newUsersByDate, setNewUsersByDate] = useState(0)
  const accessToken = localStorage.getItem('token')

  const [isLoadingReturningUser, setIsLoadingReturningUser] = useState(false)
  const [isLoadingChart, setIsLoadingChart] = useState(false)
  const [isLoadingEngagement, setIsLoadingEngagement] = useState(false)
  const [isLoadingTopChannel, setIsLoadingTopChannel] = useState(false)
  const [isLoadingTopChannelByNewUser, setIsLoadingTopChannelByNewUser] =
    useState(false)
  const [topChannel, setTopChannel] = useState('-')
  const [prepareTopChannel, setPrepareTopChannel] = useState([])
  const [topChannelByNewUser, setTopChannelByNewUser] = useState([])
  const [prepareTopChannelByNewUser, setPrepareTopChannelByNewUser] = useState(
    [],
  )
  const [trendingUp, setTrendingUp] = useState(true)
  const [userEngagement, setUserEngagement] = useState([])
  const [pageSummary, setPageSummary] = useState([])
  const [campaignSummary, setCampaignSummary] = useState([])
  const [isLoadingPageSummary, setIsLoadingPageSummary] = useState(false)
  const [isLoadingCampaignSummary, setIsLoadingCampaignSummary] =
    useState(false)

  let [yesterday, setYesterday] = useState(props.filterEndDate)
  let [prior, setPrior] = useState(props.filterEndDate)
  const [explanTable, setExplanTable] = useState()

  const getAllChart = () => {
    if (accessToken) {
      getGGUserEngagement(accessToken)
      getGGTotalUser(accessToken)
      getGGTopChannel(accessToken)
      getGGTopChannelByNewUser(accessToken)
      getGGTotalUserByChannel(accessToken)
      getGGNewUserByChannel(accessToken)
      getGGPageSummary(accessToken)
      getGGCampaignSummary(accessToken)
    } else {
      setTimeout(() => {
        setIsLoadingChart(false)
      }, 2000)
    }
  }

  function formatDate(inputDate) {
    const date = new Date(inputDate)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  const getGGUserEngagement = async (
    accessToken,
    sDate = formatDate(props.filterStartDate),
    eDate = props.filterEndDate,
  ) => {
    setIsLoadingEngagement(true)
    try {
      let obj = {
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
        dimensions: [
          { name: 'sessionDefaultChannelGroup' },
          { name: 'sessionSourceMedium' },
        ],
        metrics: [
          { name: 'engagedSessions' },
          { name: 'engagementRate' },
          { name: 'newUsers' },
          { name: 'totalUsers' },
          // { name: "averageSessionDuration" },
          { name: 'averageSessionDuration' },
        ],
      }

      const dataInfoUserEngagement = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )
      setUserEngagement(dataInfoUserEngagement?.data?.rows || [])
      setIsLoadingEngagement(false)
    } catch (error) {
      setIsLoadingEngagement(false)
    }
  }
  const getGGPageSummary = async (
    accessToken,
    sDate = formatDate(props.filterStartDate),
    eDate = props.filterEndDate,
  ) => {
    setIsLoadingPageSummary(true)
    try {
      let obj = {
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
        dimensions: [{ name: 'pagePath' }, { name: 'sessionSourceMedium' }],
        metrics: [
          { name: 'engagedSessions' },
          { name: 'engagementRate' },
          { name: 'newUsers' },
          { name: 'totalUsers' },
          { name: 'averageSessionDuration' },
        ],
      }

      const dataInfoUserPageSummary = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )
      setPageSummary(dataInfoUserPageSummary?.data?.rows || [])
      setIsLoadingPageSummary(false)
    } catch (error) {
      setIsLoadingPageSummary(false)
    }
  }

  const getGGCampaignSummary = async (
    accessToken,
    sDate = formatDate(props.filterStartDate),
    eDate = props.filterEndDate,
  ) => {
    setIsLoadingCampaignSummary(true)
    try {
      let objCampaignOnly = {
        dimensions: [{ name: 'sessionCampaignName' }],
        metrics: [
          { name: 'conversions' },
          { name: 'engagedSessions' },
          { name: 'engagementRate' },
          { name: 'newUsers' },
          { name: 'totalUsers' },
          { name: 'averageSessionDuration' },
        ],
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
      }
      const dataInfoCampaignOnly = await getGAReport(
        objCampaignOnly,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )

      let obj = {
        dimensions: [
          { name: 'sessionCampaignName' },
          { name: 'sessionSourceMedium' },
        ],
        metrics: [
          { name: 'conversions' },
          { name: 'engagedSessions' },
          { name: 'engagementRate' },
          { name: 'newUsers' },
          { name: 'totalUsers' },
          { name: 'averageSessionDuration' },
        ],
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
      }

      const dataInfoCampaignSummary = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )

      let allCampaign = dataInfoCampaignSummary.data.rows.map(
        (item) => item.dimensionValues[0].value,
      )

      allCampaign = removeDuplicates(allCampaign)
      let lastCampaign = []
      for (let i = 0; i < allCampaign.length; i++) {
        let filterCampaign = dataInfoCampaignSummary.data.rows.filter(
          (item) => item.dimensionValues[0].value === allCampaign[i],
        )

        let _temp = {
          campaign: allCampaign[i],
        }

        let _mergeSource = []

        for (var l = 0; l < filterCampaign.length; l++) {
          let _tempSource = {
            sourceName: filterCampaign[l].dimensionValues[1].value,
            sourceValue: filterCampaign[l].metricValues,
          }

          _mergeSource.push(_tempSource)
          _temp.sourceMedium = _mergeSource
          // _temp.conversion =
          //   dataInfoCampaignOnly.data.rows[l].metricValues[0].value;
          // _temp.engagedSessions =
          //   dataInfoCampaignOnly.data.rows[l].metricValues[1].value;
          // _temp.engagementRate =
          //   dataInfoCampaignOnly.data.rows[l].metricValues[2].value;
          // _temp.newUsers =
          //   dataInfoCampaignOnly.data.rows[l].metricValues[3].value;
          // _temp.totalUsers =
          //   dataInfoCampaignOnly.data.rows[l].metricValues[4].value;
          // _temp.averageSessionDuration =
          //   dataInfoCampaignOnly.data.rows[l].metricValues[5].value;

          // calConversion.push(filterCampaign[l].metricValues[0].value);
          // calEngagedSessions.push(filterCampaign[l].metricValues[1].value);
          // calEngagementRate.push(filterCampaign[l].metricValues[2].value);
          // calNewUsers.push(filterCampaign[l].metricValues[3].value);
          // calTotalUsers.push(filterCampaign[l].metricValues[4].value);
          // calUserEngagementDuration.push(
          //   filterCampaign[l].metricValues[5].value
          // );
          // _mergeSource.push(_tempSource);
          // _temp.sourceMedium = _mergeSource;
          // _temp.conversion = sumArrayOfStrings(calConversion);
          // _temp.engagedSessions = sumArrayOfStrings(calEngagedSessions);
          // _temp.engagementRate = sumArrayOfStrings(calEngagementRate);
          // _temp.newUsers = sumArrayOfStrings(calNewUsers);
          // _temp.totalUsers = sumArrayOfStrings(calTotalUsers);
          // _temp.averageSessionDuration = sumArrayOfStrings(
          //   calUserEngagementDuration
          // );
        }
        lastCampaign.push(_temp)
      }

      for (let v = 0; v < lastCampaign.length; v++) {
        lastCampaign[v].conversion =
          dataInfoCampaignOnly.data.rows[v].metricValues[0].value
        lastCampaign[v].engagedSessions =
          dataInfoCampaignOnly.data.rows[v].metricValues[1].value
        lastCampaign[v].engagementRate =
          dataInfoCampaignOnly.data.rows[v].metricValues[2].value
        lastCampaign[v].newUsers =
          dataInfoCampaignOnly.data.rows[v].metricValues[3].value
        lastCampaign[v].totalUsers =
          dataInfoCampaignOnly.data.rows[v].metricValues[4].value
        lastCampaign[v].averageSessionDuration =
          dataInfoCampaignOnly.data.rows[v].metricValues[5].value
      }
      setCampaignSummary(lastCampaign)
      let handleExpland = []
      for (let x = 0; x < lastCampaign.length; x++) {
        let objExpland = {
          rowNo: x,
          rowOpen: false,
        }
        handleExpland.push(objExpland)
      }
      setExplanTable(handleExpland)

      setIsLoadingCampaignSummary(false)
    } catch (error) {
      setIsLoadingCampaignSummary(false)
    }
  }

  const handleExplanTable = (row_no) => {
    explanTable[row_no].rowOpen = !explanTable[row_no].rowOpen
    setExplanTable(explanTable.map((item) => item))
  }

  const removeDuplicates = (arr) => {
    // Use the filter method to keep only the first occurrence of each element
    return arr.filter((value, index, self) => {
      return self.indexOf(value) === index
    })
  }
  const getGGTopChannel = async (
    accessToken,
    sDate = formatDate(props.filterStartDate),
    eDate = props.filterEndDate,
  ) => {
    setIsLoadingTopChannel(true)
    const periodDate = periodDateByDay(
      sDate,
      countNumberOfDays(sDate, eDate).match(/\d+/)[0],
    )
    try {
      let obj = {
        dimensions: [{ name: 'sessionDefaultChannelGroup' }],
        metrics: [{ name: 'activeUsers' }],
        dateRanges: [{ startDate: sDate, endDate: eDate }],
      }
      const { data, status } = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )
      if (status === 200) {
        setTopChannel(data.rows[0])

        let obj = {
          dimensions: [{ name: 'sessionDefaultChannelGroup' }],
          metrics: [{ name: 'activeUsers' }],
          dateRanges: [{ startDate: sDate, endDate: eDate }],
          // dateRanges: [{ startDate: periodDate[0], endDate: periodDate[1] }],
        }
        const prepareStat = await getGAReport(
          obj,
          props.propertyId,
          accessToken,
          props.workspaceId,
        )

        if (prepareStat.status === 200 && prepareStat?.data?.rows.length > 0) {
          setPrepareTopChannel(
            prepareStat?.data?.rows?.filter(
              (item) =>
                item?.dimensionValues[0]?.value ===
                data?.rows[0]?.dimensionValues[0]?.value,
            ),
          )
          setIsLoadingTopChannel(false)
        } else {
          setIsLoadingTopChannel(false)
        }
      } else {
        setIsLoadingTopChannel(false)
      }
    } catch (error) {
      console.log('error getGGTopChannel')
      setIsLoadingTopChannel(false)
    }
  }

  const getGGTopChannelByNewUser = async (
    accessToken,
    sDate = formatDate(props.filterStartDate),
    eDate = props.filterEndDate,
  ) => {
    setIsLoadingTopChannelByNewUser(true)
    const periodDate = periodDateByDay(
      sDate,
      countNumberOfDays(sDate, eDate).match(/\d+/)[0],
    )
    try {
      let obj = {
        dimensions: [{ name: 'firstUserDefaultChannelGroup' }],
        metrics: [{ name: 'newUsers' }],
        dateRanges: [{ startDate: sDate, endDate: eDate }],
      }
      const { data, status } = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )
      if (status === 200) {
        setTopChannelByNewUser(data.rows)
        let obj = {
          dimensions: [{ name: 'firstUserDefaultChannelGroup' }],
          metrics: [{ name: 'newUsers' }],
          // dateRanges: [{ startDate: periodDate[0], endDate: periodDate[1] }],
          dateRanges: [{ startDate: sDate, endDate: eDate }],
        }
        const prepareStat = await getGAReport(
          obj,
          props.propertyId,
          accessToken,
          props.workspaceId,
        )
        if (prepareStat?.data?.rows && prepareStat.status === 200) {
          setPrepareTopChannelByNewUser(
            prepareStat?.data?.rows.filter(
              (item) =>
                item?.dimensionValues[0]?.value ===
                data?.rows[0]?.dimensionValues[0]?.value,
            ),
          )
          setIsLoadingTopChannelByNewUser(false)
        } else {
          setIsLoadingTopChannelByNewUser(false)
        }
      } else {
        setIsLoadingTopChannelByNewUser(false)
      }
    } catch (error) {
      console.log('error getGGTopChannel')
      setIsLoadingTopChannelByNewUser(false)
    }
  }

  const getGGTotalUserByChannel = async (
    accessToken,
    sDate = formatDate(props.filterStartDate),
    eDate = props.filterEndDate,
  ) => {
    setIsLoadingTopChannelByNewUser(true)

    try {
      let obj = {
        dimensions: [{ name: 'sessionDefaultChannelGroup' }],
        metrics: [{ name: 'activeUsers' }],
        dateRanges: [{ startDate: sDate, endDate: eDate }],
      }
      const { data, status } = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )
      if (status === 200) {
        const xAxis = data.rows.map((item) => item.dimensionValues[0].value)
        const yAxis = data.rows.map((item) => item.metricValues[0].value)
        const max = parseInt(yAxis[0]) + parseInt(yAxis[0]) * 0.1
        var chartDom = document.getElementById('totalUserByChannelChart')
        setTimeout(() => {
          if (chartDom && chartDom.clientWidth !== 0) {
            let chart = echarts.init(chartDom, null, {
              width: chartDom.clientWidth,
              height: 350,
            })

            chart.setOption({
              title: {
                text: 'Total Users by Channel',
              },
              color: ['#0047FF'],
              tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                  return `
                            <b>${params[0].axisValue}</b><br />
                            user | <b>${numberWithCommas(params[0].data)}</b>
                            `
                },
              },
              legend: {
                bottom: 0,
                left: 0,
              },
              xAxis: {
                type: 'category',
                data: xAxis,
                axisLabel: {
                  interval: 0,
                  rotate: 30,
                },
              },
              yAxis: {
                type: 'value',
                max: Math.round(max),
              },
              series: [
                {
                  name: 'Users',
                  data: yAxis,
                  type: 'bar',
                },
              ],
            })
          }
        }, 1000)
      }
    } catch (error) {
      console.log('error getGGTotalUserByChannel')
      setIsLoadingTopChannelByNewUser(false)
    }
  }

  const getGGNewUserByChannel = async (
    accessToken,
    sDate = formatDate(props.filterStartDate),
    eDate = props.filterEndDate,
  ) => {
    setIsLoadingTopChannelByNewUser(true)

    try {
      let obj = {
        dimensions: [{ name: 'firstUserDefaultChannelGroup' }],
        metrics: [{ name: 'newUsers' }],
        dateRanges: [{ startDate: sDate, endDate: eDate }],
      }
      const { data, status } = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )
      if (status === 200) {
        const xAxis = data.rows.map((item) => item.dimensionValues[0].value)
        const yAxis = data.rows.map((item) => item.metricValues[0].value)
        const max = parseInt(yAxis[0]) + parseInt(yAxis[0]) * 0.1
        var chartDom = document.getElementById('newUserByChannelChart')
        setTimeout(() => {
          if (chartDom && chartDom.clientWidth !== 0) {
            let chart = echarts.init(chartDom, null, {
              width: chartDom.clientWidth,
              height: 350,
            })

            chart.setOption({
              title: {
                text: 'New User by Channel',
              },
              color: ['#0047FF'],
              tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                  return `
                            <b>${params[0].axisValue}</b><br />
                            user | <b>${numberWithCommas(params[0].data)}</b>
                            `
                },
              },
              legend: {
                bottom: 0,
                left: 0,
              },
              xAxis: {
                type: 'category',
                data: xAxis,
                axisLabel: {
                  interval: 0,
                  rotate: 30,
                },
              },
              yAxis: {
                type: 'value',
                max: Math.round(max),
              },
              series: [
                {
                  name: 'Users',
                  data: yAxis,
                  type: 'bar',
                },
              ],
            })
          }
        }, 1000)
      }
    } catch (error) {
      console.log('error getGGNewUserByChannel')
      setIsLoadingTopChannelByNewUser(false)
    }
  }

  const changeFormatDate = (inputDate) => {
    const year = inputDate.substring(0, 4)
    const month = inputDate.substring(4, 6)
    const day = inputDate.substring(6)
    const outputDate = `${day}-${month}-${year}`
    return outputDate
  }

  function formatDate(inputDate) {
    const date = new Date(inputDate)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  const getGGTotalUser = async (
    accessToken,
    sDate = formatDate(props.filterStartDate),
    eDate = props.filterEndDate,
  ) => {
    // setIsLoadingReturningUser(true);
    setIsLoadingChart(true)
    try {
      let obj = {
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
        dimensions: [
          {
            name: 'newVsReturning',
          },
        ],
        metrics: [
          {
            name: 'totalUsers',
          },
        ],
        metricAggregations: ['TOTAL'],
      }
      const dataInfo = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      ).then((res) => res.data)
      if (dataInfo.rows) {
        setReturningUser(dataInfo.rows[1].metricValues[0].value)
      } else {
        setReturningUser('-')
        setNewUsersByDate(0)
      }

      setIsLoadingReturningUser(false)
      setTimeout(() => {
        setIsLoadingChart(false)
      }, 2000)
    } catch (error) {
      setIsLoadingReturningUser(false)
      setNewUsersByDate(0)
      // if (error.response.status === 403) {
      setIsLoadingChart(false)
      setIsLoadingEngagement(false)
      // return;
      // }
      setTimeout(() => {
        setIsLoadingChart(false)
      }, 2000)
    }
  }

  const handleHover = (event) => {
    // เมื่อโฮเวอร์ <td> เราจะแสดง tooltip
    const tooltip = event.target.querySelector('.tooltip')
    tooltip.style.display = 'block'
  }

  const handleMouseLeave = (event) => {
    // เมื่อไม่โฮเวอร์ <td> และออกจากพื้นที่ของ tooltip เราจะซ่อน tooltip
    const tooltip = event.target.querySelector('.tooltip')
    tooltip.style.display = 'none'
  }

  useMemo(() => {
    if (props.filterStartDate) {
      let yesterday = new Date(props.filterEndDate)
      yesterday.setHours(0, 0, 0, 0)
      let prior = new Date().setDate(yesterday.getDate() - 30)
      prior = new Date(prior)
      prior.setHours(0, 0, 0, 0)

      setYesterday(yesterday)
      setPrior(prior)
      getAllChart()
    }
  }, [props.filterStartDate])

  useMemo(() => {
    if (props.menuActive === 'Acquisition') {
      getAllChart()
    }
  }, [props.menuActive])
  return (
    <div
      id="Acquisition"
      className={`container tab-pane ${props.menuAcquisition ? 'active' : ''} `}
    >
      <br />
      {isLoadingEngagement && <LoadingComponent />}
      <div className={` row mb-3 ${isLoadingEngagement ? 'd-none' : ''}`}>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              {isLoadingTopChannel ? (
                <LoadingSMComponent />
              ) : (
                <Fragment>
                  <p className="d-flex justify-content-between mb-1">
                    <span className="text-14 mb-1 text-semibold ">
                      <MdWifiChannel className="mx-2 text-16" />
                      Top Channel
                    </span>
                    <span className="badge-md badge-md-info">
                      {(topChannel &&
                        topChannel.dimensionValues &&
                        topChannel.dimensionValues[0].value) ||
                        '-'}{' '}
                    </span>
                  </p>

                  <div className="d-flex justify-content-between mt-3 mb-0">
                    <p className="text-36 text-semibold mb-0">
                      {topChannel &&
                        topChannel.metricValues &&
                        numberWithCommas(topChannel?.metricValues[0].value)}
                    </p>

                    <div className="text-right">
                      {topChannel &&
                        topChannel?.metricValues &&
                        prepareTopChannel[0]?.metricValues && (
                          <Fragment>
                            {!calculatePercentageChange(
                              prepareTopChannel[0].metricValues[0].value,
                              topChannel.metricValues[0].value,
                            ).includes('-') ? (
                              <p
                                className={`text-14 text-semibold mb-1 text-primary`}
                              >
                                <MdTrendingUp className="mx-2" />
                                {calculatePercentageChange(
                                  prepareTopChannel[0].metricValues[0].value,
                                  topChannel.metricValues[0].value,
                                )}
                                %
                              </p>
                            ) : (
                              <p
                                className={`text-14 text-semibold mb-1 text-danger`}
                              >
                                <MdTrendingDown className="mx-2" />
                                {calculatePercentageChange(
                                  prepareTopChannel[0].metricValues[0].value,
                                  topChannel.metricValues[0].value,
                                )}
                                %
                              </p>
                            )}
                          </Fragment>
                        )}

                      <p className="text-10 mb-0 mt-2">
                        vs last{' '}
                        {countNumberOfDays(
                          formatDate(props.filterStartDate),
                          props.filterEndDate,
                        )}
                      </p>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="card">
            <div className="card-body">
              {isLoadingTopChannelByNewUser ? (
                <LoadingSMComponent />
              ) : (
                <Fragment>
                  <div className="d-flex justify-content-between ">
                    <div className="w-50 px-1">
                      <p className="d-flex justify-content-between mb-1">
                        <span className="text-14 mb-1 text-semibold ">
                          <MdPersonAddAlt1 className="mx-2 text-18" />
                          Top Channel by New User
                        </span>
                        <span className="badge-md badge-md-info">
                          {topChannelByNewUser?.length > 0
                            ? topChannelByNewUser[0]?.dimensionValues[0].value
                            : '-'}
                        </span>
                      </p>
                      <div className="d-flex justify-content-between mt-3">
                        <p className="text-36 text-semibold mb-0">
                          <img src={`/assets/img/crown.svg`} className="mx-2" />{' '}
                          {topChannelByNewUser?.length > 0
                            ? numberWithCommas(
                                topChannelByNewUser[0].metricValues[0].value,
                              )
                            : '-'}
                        </p>
                        <div className="text-right">
                          {topChannelByNewUser?.length > 0 &&
                            prepareTopChannelByNewUser.length > 0 &&
                            topChannelByNewUser[0].metricValues &&
                            prepareTopChannelByNewUser[0].metricValues && (
                              <Fragment>
                                {!calculatePercentageChange(
                                  prepareTopChannelByNewUser[0].metricValues[0]
                                    .value,
                                  topChannelByNewUser[0].metricValues[0].value,
                                ).includes('-') ? (
                                  <p
                                    className={`text-14 text-semibold mb-1 text-primary`}
                                  >
                                    <MdTrendingUp className="mx-2" />
                                    {calculatePercentageChange(
                                      prepareTopChannelByNewUser[0]
                                        .metricValues[0].value,
                                      topChannelByNewUser[0].metricValues[0]
                                        .value,
                                    )}
                                    %
                                  </p>
                                ) : (
                                  <p
                                    className={`text-14 text-semibold mb-1 text-danger`}
                                  >
                                    <MdTrendingDown className="mx-2" />
                                    {calculatePercentageChange(
                                      prepareTopChannelByNewUser[0]
                                        .metricValues[0].value,
                                      topChannelByNewUser[0].metricValues[0]
                                        .value,
                                    )}
                                    %
                                  </p>
                                )}
                              </Fragment>
                            )}

                          <p className="text-10 mb-0 mt-2">
                            vs last{' '}
                            {countNumberOfDays(
                              formatDate(props.filterStartDate),
                              props.filterEndDate,
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="border-card-center"></div>
                    <div className="w-50 px-1">
                      {topChannelByNewUser?.length > 0 &&
                        topChannelByNewUser.map((item, i) => {
                          return (
                            i !== 0 &&
                            i <= 3 && (
                              <div
                                key={i}
                                className="border-card-bottom py-1 d-flex justify-content-between "
                              >
                                <div className="d-flex justify-content-between ">
                                  <img
                                    src={`/assets/img/crown_wb.svg`}
                                    className="mx-2"
                                    style={{ width: '15px' }}
                                  />
                                  <span
                                    className={`badge-md badge-md-${
                                      i === 1
                                        ? 'green'
                                        : i === 2
                                          ? 'purple'
                                          : 'pink'
                                    }`}
                                  >
                                    {item.dimensionValues[0].value}
                                  </span>
                                </div>

                                <div className="d-flex justify-content-between ">
                                  <span className="text-14 text-semibold">
                                    {numberWithCommas(
                                      item.metricValues[0].value,
                                    )}{' '}
                                  </span>

                                  {prepareTopChannelByNewUser.filter(
                                    (prepareItem) =>
                                      prepareItem.dimensionValues[0].value ===
                                      item.dimensionValues[0].value,
                                  ).length > 0 ? (
                                    <p
                                      className={` text-14 text-semibold mb-1 ${
                                        trendingUp
                                          ? 'text-primary'
                                          : ' text-danger'
                                      }`}
                                    >
                                      <MdTrendingUp className="mx-2" />
                                      10%
                                    </p>
                                  ) : (
                                    <Tooltip title="Comparison Unavailable">
                                      <IconButton className="fixed-icon">
                                        <ErrorIcon
                                          sx={{
                                            fontSize: 18,
                                            color: '#D0D5DD',
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            )
                          )
                        })}
                      {/* <div className="border-card-bottom py-1 d-flex justify-content-between ">
                        <div className="d-flex justify-content-between ">
                          <img
                            src={`/assets/img/crown_wb.svg`}
                            className="mx-2"
                            style={{ width: "15px" }}
                          />
                          <span className="badge-md badge-md-green">
                            Organic
                          </span>
                        </div>

                        <div className="d-flex justify-content-between ">
                          <span className="text-14 text-semibold">
                            12,078{" "}
                          </span>
                          <p
                            className={` text-14 text-semibold mb-1 ${
                              trendingUp ? "text-primary" : " text-danger"
                            }`}
                          >
                            {trendingUp ? (
                              <MdTrendingUp className="mx-2" />
                            ) : (
                              <MdTrendingDown className="mx-2" />
                            )}
                            10%
                          </p>
                        </div>
                      </div>
                      <div className="border-card-bottom py-1 d-flex justify-content-between ">
                        <div className="d-flex justify-content-between ">
                          <img
                            src={`/assets/img/crown_wb.svg`}
                            className="mx-2"
                            style={{ width: "15px" }}
                          />
                          <span className="badge-md badge-md-purple">
                            Affiliates
                          </span>
                        </div>

                        <div className="d-flex justify-content-between ">
                          <span className="text-14 text-semibold">
                            12,078{" "}
                          </span>
                          <p
                            className={` text-14 text-semibold mb-1 ${
                              trendingUp ? "text-primary" : " text-danger"
                            }`}
                          >
                            {trendingUp ? (
                              <MdTrendingUp className="mx-2" />
                            ) : (
                              <MdTrendingDown className="mx-2" />
                            )}
                            10%
                          </p>
                        </div>
                      </div>
                      <div className="py-1 d-flex justify-content-between ">
                        <div className="d-flex justify-content-between ">
                          <img
                            src={`/assets/img/crown_wb.svg`}
                            className="mx-2"
                            style={{ width: "15px" }}
                          />
                          <span className="badge-md badge-md-pink">
                            Paid Search
                          </span>
                        </div>

                        <div className="d-flex justify-content-between ">
                          <span className="text-14 text-semibold">
                            12,078{" "}
                          </span>
                          <p
                            className={` text-14 text-semibold mb-1 ${
                              trendingUp ? "text-primary" : " text-danger"
                            }`}
                          >
                            {trendingUp ? (
                              <MdTrendingUp className="mx-2" />
                            ) : (
                              <MdTrendingDown className="mx-2" />
                            )}
                            10%
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={` row mb-3 ${isLoadingEngagement ? 'd-none' : ''}`}>
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <p className="text-16 text-semibold">Total User by Channel</p>
              <p className="text-12">
                Explore your website users breakdown by channel
              </p>

              <div className="chart" id="totalUserByChannelChart"></div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <p className="text-16 text-semibold">New User by Channel</p>
              <p className="text-12">
                Explore where your website’s new users arrive from, broken down
                by channel.
              </p>

              <div className="chart" id="newUserByChannelChart"></div>
            </div>
          </div>
        </div>
      </div>
      <div className={`card mb-3 ${isLoadingEngagement ? 'd-none' : ''}`}>
        <div className="card-body ">
          <p className="text-16 text-semibold">User Engagement Summary</p>
          <p className="text-12">
            Check out the User Engagement Summary table to see how users engage
            with your website from each channel.
          </p>
          <TableDashboardBox>
            <div className="text-center table-fixed">
              {userEngagement.length === 0 ? (
                <span className="text-no-data">No data found.</span>
              ) : (
                ''
              )}
              <table className="table table-purple table-striped m-0 ">
                <thead className="table-primary">
                  <tr className="align-middle text-14">
                    <th className="text-left">Channel</th>
                    <th>Source / Medium</th>
                    <th>Total User</th>
                    <th>New User</th>
                    <th>Engaged Sessions</th>
                    <th>Engagement Rate</th>
                    <th>
                      Average <br />
                      Session Duration
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userEngagement.length > 0 ? (
                    userEngagement.map((item, i) => {
                      return (
                        <tr key={i} className="text-14">
                          <td className="text-semibold text-left">
                            {item.dimensionValues[0].value}
                          </td>
                          <td>{item.dimensionValues[1].value}</td>
                          <td>
                            {numberWithCommas(item.metricValues[3].value)}
                          </td>
                          <td>
                            {numberWithCommas(item.metricValues[2].value)}
                          </td>
                          <td>
                            {numberWithCommas(item.metricValues[0].value)}
                          </td>
                          <td>
                            {parseFloat(item.metricValues[1].value).toFixed(2)}%
                          </td>
                          <td>
                            {generateTimeWithSeconds(
                              item.metricValues[4].value,
                            ) || '-'}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
            </div>{' '}
          </TableDashboardBox>
        </div>
      </div>
      <div className={`card mb-3 ${isLoadingEngagement ? 'd-none' : ''}`}>
        <div className="card-body ">
          <p className="text-16 text-semibold">Page Summary</p>
          <p className="text-12">
            Check out the Page Summary table to see how users engage with your
            website breakdown by page.
          </p>
          <TableDashboardBox>
            <div className="text-center table-fixed">
              {pageSummary.length === 0 ? (
                <span className="text-no-data">No data found.</span>
              ) : (
                ''
              )}
              <table className="table table-purple table-striped m-0 ">
                <thead className="table-primary">
                  <tr className="align-middle text-14">
                    <th className="text-left ">Page</th>
                    <th>Source / Medium</th>
                    <th>Total User</th>
                    <th>New User</th>
                    <th>Engaged Sessions</th>
                    <th>Engagement Rate</th>
                    <th>
                      Average <br />
                      Session Duration
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pageSummary.length > 0 ? (
                    pageSummary.map((item, i) => {
                      return (
                        <tr key={i} className="text-14">
                          <td
                            con
                            className="text-semibold text-left text-truncate"
                            style={{ maxWidth: '300px' }}
                          >
                            {item.dimensionValues[0].value}
                          </td>
                          <td>{item.dimensionValues[1].value}</td>
                          <td>
                            {numberWithCommas(item.metricValues[3].value)}
                          </td>
                          <td>
                            {numberWithCommas(item.metricValues[2].value)}
                          </td>
                          <td>
                            {numberWithCommas(item.metricValues[0].value)}
                          </td>
                          <td>
                            {parseFloat(item.metricValues[1].value).toFixed(2)}%
                          </td>
                          <td>
                            {generateTimeWithSeconds(
                              item.metricValues[4].value,
                            ) || '-'}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
            </div>{' '}
          </TableDashboardBox>
        </div>
      </div>

      <div className={`card mt-3 ${isLoadingEngagement ? 'd-none' : ''}`}>
        <div className="card-body ">
          <p className="text-16 text-semibold">Campaign Summary</p>
          <p className="text-12">
            {(campaignSummary.length !== 0 && campaignSummary[0]?.campaign) ||
              ''}{' '}
            has the highest engagement from your customers.
          </p>
          <TableExpandDashboardBox>
            <div className="text-center table-fixed">
              {campaignSummary.length === 0 ? (
                <span className="text-no-data">No data found.</span>
              ) : (
                ''
              )}
              <table className="table table-purple table-striped m-0">
                <thead className="table-primary">
                  <tr className="align-middle text-14">
                    <th>Campaign & Source / Medium</th>
                    <th>Conversion</th>
                    <th>Total User</th>
                    <th>New User</th>
                    <th>Engaged Sessions</th>
                    <th>Engagement Rate</th>
                    <th>
                      Average <br />
                      Session Duration
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {campaignSummary.length > 0 &&
                    campaignSummary.map((item, i) => {
                      return (
                        <Fragment key={i}>
                          <tr className={`text-14 `}>
                            <td className="text-semibold text-left">
                              {explanTable[i].rowOpen ? (
                                <AiFillMinusSquare
                                  onClick={() => handleExplanTable(i)}
                                  className="text-primary text-16 mx-2"
                                />
                              ) : (
                                <AiFillPlusSquare
                                  onClick={() => handleExplanTable(i)}
                                  className="text-primary text-16 mx-2"
                                />
                              )}{' '}
                              {item.campaign}
                            </td>

                            <td>{numberWithCommas(item.conversion) || '0'}</td>
                            <td>{numberWithCommas(item.totalUsers) || '0'}</td>
                            <td>{numberWithCommas(item.newUsers) || '0'}</td>
                            <td>
                              {numberWithCommas(item.engagedSessions) || '0'}
                            </td>
                            <td>
                              {parseFloat(item.engagementRate).toFixed(2) ||
                                '0'}
                              %
                            </td>
                            <td>
                              {generateTimeWithSeconds(
                                item.averageSessionDuration,
                              ) || '-'}
                            </td>
                          </tr>

                          {explanTable[i].rowOpen &&
                            item.sourceMedium.length > 0 &&
                            item.sourceMedium.map((items, l) => {
                              return (
                                <tr
                                  className={`text-14 animate-5 fadeInDown one`}
                                  key={l}
                                >
                                  <td className="text-left pl-25em td-sub-table">
                                    {items.sourceName}
                                  </td>
                                  <td className="td-sub-table">
                                    {numberWithCommas(
                                      items.sourceValue[0].value,
                                    )}
                                  </td>
                                  <td className="td-sub-table">
                                    {numberWithCommas(
                                      items.sourceValue[4].value,
                                    )}
                                  </td>
                                  <td className="td-sub-table">
                                    {numberWithCommas(
                                      items.sourceValue[3].value,
                                    )}
                                  </td>
                                  <td className="td-sub-table">
                                    {numberWithCommas(
                                      items.sourceValue[1].value,
                                    )}
                                  </td>
                                  <td className="td-sub-table">
                                    {parseFloat(
                                      items.sourceValue[2].value,
                                    ).toFixed(2)}
                                    %
                                  </td>
                                  <td className="td-sub-table">
                                    {generateTimeWithSeconds(
                                      items.sourceValue[5].value,
                                    ) || '-'}
                                  </td>
                                </tr>
                              )
                            })}
                        </Fragment>
                      )
                    })}
                </tbody>
              </table>
            </div>{' '}
          </TableExpandDashboardBox>
        </div>
      </div>
    </div>
  )
}

export default TabMenuAcquisition
