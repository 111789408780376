/*global chrome*/
import React, { useState, useEffect, useRef } from 'react'
import { Login } from './style'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import FooterComponent from '../layout/footer'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReCaptchaComponent from './recaptcha'

import { login } from '../../apis/login/api'
const { REACT_APP_CAPTCHA } = process.env
var round = 0
const LoginComponent = () => {
  const inputPassword = useRef(null)
  const [showPassword, setShowPassword] = useState(true)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [verifyToken, setVerifyToken] = useState('')
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0)
  const [validateEmail, setValidateEmail] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [validatePassword, setValidatePassword] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [loginFail, setLoginFail] = useState(false)
  const [loading, setLoading] = useState(false)

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const inviteCode = urlParams.get('invite')
  const [isClick, setIsClick] = useState(false)

  useEffect(() => {
    document.title = 'Sign in | Tag Turbo'
    document
      .getElementById('viewport')
      .setAttribute('content', 'initial-scale=0.9, minimum-scale=0.9,width=450')

    const accessToken = localStorage.getItem('token')
    if (accessToken) {
      window.location.href = '/home'
    }
    refreshRecaptcha()
      ; (function () {
        function onTidioChatApiReady() {
          window.tidioChatApi.hide()
          window.tidioChatApi.on('close', function () {
            window.tidioChatApi.hide()
          })
        }

        if (window.tidioChatApi) {
          window.tidioChatApi.on('ready', onTidioChatApiReady)
        } else {
          document.addEventListener('tidioChat-ready', onTidioChatApiReady)
        }
      })()
  }, [])

  useEffect(() => {
    setVerifyToken(verifyToken)
  }, [verifyToken])

  const keepTokenFn = (token) => {
    setVerifyToken(token)
  }

  const refreshRecaptcha = () => {
    const interval = setInterval(async () => {
      round = round === 0 ? 1 : 0
      setRefreshReCaptcha(round)
    }, 60000)
    return () => clearInterval(interval)
  }

  const onSubmit = async () => {
    setLoading(true)
    if (!email) {
      handleValidateEmail('Email Address is required.', false)
    }
    if (!password) {
      handleValidatePassword('Password is required.', false)
    }

    if (email && password) {
      const formatEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g
      if (email.match(formatEmail)) {
        let obj = {
          username: email,
          password: password,
          recaptcha_token: verifyToken,
        }
        const { data, status } = await login(obj)
        if (status === 200 && data) {
          setLoading(false)
          localStorage.setItem('token', data.token)
          localStorage.setItem('ref_code', data.ref_code)
          window.location.href = '/verify'
        } else {
          round = round === 0 ? 1 : 0
          setRefreshReCaptcha(round)
          setLoginFail(true)
          setLoading(false)
        }
      } else {
        setLoading(false)
        handleValidateEmail('Email Address is in the wrong format.', false)
      }
    } else {
      setLoading(false)
    }
  }
  const handleValidateEmail = (msg, status) => {
    setValidateEmail({ msg, status })
  }
  const handleValidatePassword = (msg, status) => {
    setValidatePassword({ msg, status })
  }
  return (
    <Login>
      <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_CAPTCHA}>
        <div className="page full-page">
          <div className="content-box">
            <div className="container-fluid animate fadeIn one h-100">
              <div className="row justify-content-md-center h-100">
                <div className="col-lg-6 text-center p-0 d-none d-lg-block">
                  <img
                    src="/assets/img/BG_login.png"
                    className="w-100 p-0"
                    alt="Tag Turbo"
                  />
                </div>
                <div className="col-lg-6 text-center p-0 content h-100 d-flex-center">
                  <div id="login" className="min-h-100 max-w-718">
                    <div className="p-5 min-h-95">
                      <h2 className="mt-4">
                        <img
                          src="/assets/img/logo-Tagtb.svg"
                          className="img-24"
                          alt="Tag Turbo"
                        />
                      </h2>
                      <p className="text-14">
                        Sign in to continue to Tag Turbo.
                      </p>
                      <form id="form" action="/home" className="text-left mb-3">
                        <p className="mt-5 text-20">
                          <b>Sign in</b>
                          {loginFail && (
                            <p className="text-danger mt-3 text-14">
                              Unable to sign in. Please check your email or
                              password and try again.
                            </p>
                          )}
                        </p>
                        <div className="mb-4">
                          <label className="form-label text-14">
                            Email Address
                          </label>
                          <input
                            type="email"
                            className={`form-control ${!validateEmail.status ? 'invalidate-box' : ''
                              }`}
                            id="email"
                            placeholder="name@example.com"
                            value={email}
                            onChange={(e) => (
                              setEmail(e.target.value),
                              handleValidateEmail('', true),
                              setLoginFail(false)
                            )}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                inputPassword.current.focus()
                              }
                            }}
                          />
                          {!validateEmail.status && (
                            <label className="form-label invalidate-text text-14">
                              {validateEmail.msg}
                            </label>
                          )}
                        </div>
                        <div className="mb-5">
                          <label className="form-label text-14">Password</label>
                          <label
                            id="Forgot_password"
                            className="form-label float-right"
                          >
                            <a
                              onClick={() => (window.location.href = '/forgot')}
                              className="link-secondary cursor text-14"
                            >
                              Forgot Password ?
                            </a>
                          </label>
                          <div className="input-group">
                            <input
                              ref={inputPassword}
                              id="password"
                              type={showPassword ? 'password' : 'text'}
                              className={`form-control border-right-0 ${!validatePassword.status ? 'invalidate-box' : ''
                                }`}
                              value={password}
                              onChange={(e) => (
                                setPassword(e.target.value),
                                handleValidatePassword('', true),
                                setLoginFail(false)
                              )}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  onSubmit()
                                }
                              }}
                            />
                            <span
                              className={`input-group-text border-left-0 bg-transparent ${!validatePassword.status ? 'invalidate-box' : ''
                                }`}
                              id="basic-addon2"
                            >
                              <div
                                className="cursor"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <FaEye className="text-lighter" />
                                ) : (
                                  <FaEyeSlash className="text-lighter" />
                                )}{' '}
                              </div>
                            </span>
                          </div>
                          {!validatePassword.status && (
                            <label className="form-label invalidate-text text-14">
                              {validatePassword.msg}
                            </label>
                          )}
                        </div>
                      </form>

                      <button type="button"
                        onClick={() => onSubmit()}
                        className="btn btn-gradient-authen w-50 text-14 text-semibold btn-load"
                        disabled={loading}>
                        <span className="flex-grow-1  d-flex align-items-center justify-content-center">
                          {loading && <span className="spinner-border flex-shrink-0 mx-2" role="status"></span>}
                          Sign in
                        </span>
                      </button>

                      <div className="mt-4">
                        <p className="text-14">
                          Don’t have an account yet?{' '}
                          <span
                            className="text-primary cursor"
                            onClick={() =>
                              inviteCode
                                ? (window.location.href = `/signup${queryString}`)
                                : (window.location.href = '/signup')
                            }
                          >
                            Sign up
                          </span>
                        </p>
                      </div>
                    </div>
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReCaptchaComponent
          keepTokenFn={keepTokenFn}
          refreshToken={refreshReCaptcha}
        />
      </GoogleReCaptchaProvider>
    </Login>
  )
}

export default LoginComponent
