import React, { useState, useEffect, useRef } from 'react'
import { SignUpBox } from './style'
import { FaEye, FaEyeSlash, FaCheck } from 'react-icons/fa'
import FooterComponent from '../layout/footer'
import { signUp } from '../../apis/login/api'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReCaptchaComponent from '../login/recaptcha'
const { REACT_APP_CAPTCHA } = process.env
var round = 0

const SignUpComponent = () => {
  const [showNewPassword, setShowNewPassword] = useState(true)
  const [showCfPassword, setShowCfPassword] = useState(true)
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('th')
  const [dialCode, setDialCode] = useState('66')
  const inputEmail = useRef(null)
  const inputPassword = useRef(null)
  const inputCFPassword = useRef(null)
  const [newPassword, setNewPassword] = useState('')
  const [cfPassword, setCfPassword] = useState('')
  const [validateNewPassword, setValidateNewPassword] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [validateCfPassword, setValidateCfPassword] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [validateNotMatch, setValidateNotMatch] = useState(true)
  const [verifyCFPassword, setVerifyCFPassword] = useState(false)
  const [validateEmail, setValidateEmail] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [validatePhone, setValidatePhone] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [validateName, setValidateName] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [validateEmailalready, setValidateEmailalready] = useState(true)

  const [checkedLetter, setCheckedLetter] = useState(false)
  const [checkedCapital, setCheckedCapital] = useState(false)
  const [checkedNumber, setCheckedNumber] = useState(false)
  const [checkedSpecial, setCheckedSpecial] = useState(false)
  const [checkedLength, setCheckedLength] = useState(false)
  const [accessToken, setAccessToken] = useState('')

  const [verifyToken, setVerifyToken] = useState('')
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0)

  const [passwordsconditionbox, setPasswordsconditionbox] = useState({
    no: 0,
    isOpen: false,
  })
  const [isClick, setIsClick] = useState(false)

  useEffect(() => {
    document.title = 'Signup | Tag Turbo'
    const token = localStorage.getItem('token')
    document.addEventListener('click', handleClickOutside, true)
    refreshRecaptcha()

    document
      .getElementById('viewport')
      .setAttribute('content', 'initial-scale=0.9, minimum-scale=0.9,width=450')
      ; (function () {
        function onTidioChatApiReady() {
          window.tidioChatApi.hide()
          window.tidioChatApi.on('close', function () {
            window.tidioChatApi.hide()
          })
        }

        if (window.tidioChatApi) {
          window.tidioChatApi.on('ready', onTidioChatApiReady)
        } else {
          document.addEventListener('tidioChat-ready', onTidioChatApiReady)
        }
      })()
  }, [])

  useEffect(() => {
    setVerifyToken(verifyToken)
  }, [verifyToken])

  const keepTokenFn = (token) => {
    setVerifyToken(token)
  }

  const refreshRecaptcha = () => {
    const interval = setInterval(async () => {
      round = round === 0 ? 1 : 0
      setRefreshReCaptcha(round)
    }, 60000)
    return () => clearInterval(interval)
  }

  const handleClickOutside = (event) => {
    setPasswordsconditionbox({
      no: 0,
      isOpen: false,
    })
  }

  const handlePasswordsconditionbox = (no) => {
    let obj = {}
    if (passwordsconditionbox.no === no) {
      obj = {
        no: no,
        isOpen: !passwordsconditionbox.isOpen,
      }
    } else {
      obj = {
        no: no,
        isOpen: true,
      }
    }
    setPasswordsconditionbox(obj)
  }

  const checkPassword = (e) => {
    setPasswordsconditionbox({
      no: 1,
      isOpen: true,
    })

    setNewPassword(e.target.value)
    if (e.target.value) {
      setValidateNewPassword(true)
    }
    const lowerCaseLetters = /[a-z]/g
    if (e.target.value.match(lowerCaseLetters)) {
      setCheckedLetter(true)
    } else {
      setCheckedLetter(false)
    }

    const upperCaseLetters = /[A-Z]/g
    if (e.target.value.match(upperCaseLetters)) {
      setCheckedCapital(true)
    } else {
      setCheckedCapital(false)
    }

    // Validate numbers
    const numbers = /[0-9]/g
    if (e.target.value.match(numbers)) {
      setCheckedNumber(true)
    } else {
      setCheckedNumber(false)
    }

    const specials = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi
    if (e.target.value.match(specials)) {
      setCheckedSpecial(true)
    } else {
      setCheckedSpecial(false)
    }

    // Validate length
    if (e.target.value.length >= 8) {
      setCheckedLength(true)
    } else {
      setCheckedLength(false)
    }
  }
  const onSubmit = async () => {
    setIsClick(true)
    if (userName) {
      handleName('', true)
    } else {
      handleName('Name is required.', false)
    }

    if (email) {
      handleEmail('', true)
    } else {
      handleEmail('Email is required.', false)
    }

    if (newPassword) {
      handleNewPassword('', true)
    } else {
      handleNewPassword('New password is required.', false)
    }

    if (cfPassword) {
      handleCfPassword('', true)
    } else {
      handleCfPassword('Confirm password is required.', false)
    }
    if (userName && email && newPassword && cfPassword) {
      const formatEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g

      if (email.match(formatEmail)) {
        if (
          checkedLetter &&
          checkedCapital &&
          checkedNumber &&
          checkedSpecial &&
          checkedLength
        ) {
          handleNewPassword('', true)

          if (newPassword !== cfPassword) {
            if (cfPassword) {
              handleCfPassword(
                'Password is not match. Please try again.',
                false,
              )
            } else {
              handleCfPassword('', true)
            }
          } else {
            if (!cfPassword) {
              setVerifyCFPassword(true)
            } else {
              let obj = {
                name: userName,
                username: email,
                password: newPassword,
                recaptcha_token: verifyToken,
                country_code: dialCode,
                phone_number: phoneNumber
                  ? convertToFormatTel(phoneNumber, dialCode)
                  : null,
              }

              const queryString = window.location.search
              const urlParams = new URLSearchParams(queryString)
              const inviteCode = urlParams.get('invite')
              if (inviteCode) {
                obj.invite_code = inviteCode
              }

              const { data, status } = await signUp(obj)
              if (data && status === 200) {
                setIsClick(false)
                localStorage.setItem('token', data.token)
                window.location.href = '/verifysignup'
              } else {
                setIsClick(false)
                round = round === 0 ? 1 : 0
                setRefreshReCaptcha(round)

                if (
                  data.response.data.message ===
                  'phone_number/country code is invalid'
                ) {
                  handlePhone('This phone number is invalid.', false)
                }
                if (
                  data.response.data.message === 'Error: user is activated.'
                ) {
                  setValidateEmailalready(false)
                }
              }
            }
          }
        } else {
          round = round === 0 ? 1 : 0
          setRefreshReCaptcha(round)
          handleNewPassword('Password is on the wrong format.', false)

          // setValidateCfPassword(false);
          // setValidateEmail(false)
          // setValidateName(false)
          console.log('error')
        }
      } else {
        handleEmail('Email Address is in the wrong format.', false)
      }
    } else {
      setIsClick(false)
    }
  }

  const handleName = (msg, status) => {
    setValidateName({ msg, status })
  }
  const handleEmail = (msg, status) => {
    setValidateEmail({ msg, status })
  }
  const handlePhone = (msg, status) => {
    setValidatePhone({ msg, status })
  }
  const handleNewPassword = (msg, status) => {
    setValidateNewPassword({ msg, status })
  }
  const handleCfPassword = (msg, status) => {
    setValidateCfPassword({ msg, status })
  }
  const handlePhoneInput = (phoneNumber, country) => {
    setPhoneNumber(phoneNumber)
    setCountryCode(country?.countryCode)
    setDialCode(country?.dialCode)
  }

  const convertToFormatTel = (phoneNumber, dialCode) => {
    if (phoneNumber === dialCode) {
      return null
    } else {
      const regex = new RegExp(`^${dialCode}0?(\\d+)`)
      const match = phoneNumber.match(regex)
      return match ? match[1] : phoneNumber
    }
  }

  return (
    <SignUpBox>
      <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_CAPTCHA}>
        <div className="page full-page">
          <div className="content-box">
            <div className="container-fluid animate fadeIn one h-100">
              <div className="row justify-content-md-center h-100">
                <div className="col-lg-6 text-center p-0 d-none d-lg-block">
                  <img
                    src="/assets/img/BG_login.png"
                    className="w-100 p-0"
                    alt="Tag Turbo"
                  />
                </div>
                <div className="col-lg-6 text-center p-0 content h-100 d-flex-center">
                  <div id="reset" className="min-h-100 max-w-718">
                    <div className="p-5 min-h-95">
                      <h2 className="mt-2">
                        <img
                          src="/assets/img/logo-Tagtb.svg"
                          className="img-180"
                        />
                      </h2>
                      <p className="text-14">Create your account</p>
                      <div className="text-left mt-3">
                        <p className="text-20">
                          <b>Sign up</b>
                        </p>
                        {!validateEmailalready && (
                          <p className="text-14 text-danger">
                            This email address already has an account. Please
                            try logging in with this email.
                          </p>
                        )}

                        <div className="mb-3">
                          <label className="form-label text-14">Name</label>
                          <input
                            type="text"
                            className={`form-control  ${!validateName.status ? 'invalidate-box' : ''
                              }`}
                            id="Name"
                            placeholder="name"
                            value={userName}
                            onChange={(e) => (
                              setUserName(e.target.value),
                              handleName('', true),
                              setValidateEmailalready(true)
                            )}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                inputEmail.current.focus()
                              }
                            }}
                          />
                          {!validateName.status && (
                            <label className="form-label invalidate-text text-14">
                              {validateName.msg}
                            </label>
                          )}
                        </div>
                        <div className="mb-3">
                          <label className="form-label text-14">
                            Email Address
                          </label>
                          <input
                            ref={inputEmail}
                            type="email"
                            className={`form-control  ${!validateEmail.status ? 'invalidate-box' : ''
                              }`}
                            id="email"
                            placeholder="name@example.com"
                            value={email}
                            onChange={(e) => (
                              setEmail(e.target.value),
                              handleEmail('', true),
                              setValidateEmailalready(true)
                            )}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                inputPassword.current.focus()
                              }
                            }}
                          />
                          {!validateEmail.status && (
                            <label className="form-label invalidate-text text-14">
                              {validateEmail.msg}
                            </label>
                          )}
                        </div>
                        <div className="mb-3">
                          <label className="form-label text-14">
                            Phone Number{' '}
                          </label>
                          <PhoneInput
                            enableSearch={true}
                            disableSearchIcon={true}
                            inputStyle={{
                              width: '100%',
                              height: '38px',
                              border: !validatePhone.status
                                ? '1px solid #ed3451'
                                : '1px solid #dee2e6',
                            }}
                            buttonStyle={{
                              border: !validatePhone.status
                                ? '1px solid #ed3451'
                                : '1px solid #dee2e6',
                            }}
                            country={'th'}
                            value={phoneNumber}
                            onChange={(phone, country) => (
                              handlePhoneInput(phone, country),
                              handlePhone('', true)
                            )}
                            preferredCountries={['th']}
                            masks={{ th: '..........' }}
                            placeholder=""
                          />
                          {!validatePhone.status && (
                            <label className="form-label invalidate-text text-14">
                              {validatePhone.msg}
                            </label>
                          )}
                        </div>

                        <div>
                          <label className="form-label  text-14">
                            Enter New Password
                          </label>
                          <label
                            id="newpassword"
                            className="form-label"
                          ></label>
                          <div className="input-group ">
                            <input
                              ref={inputPassword}
                              id="newpassword"
                              type={showNewPassword ? 'password' : ''}
                              className={`form-control border-right-0 ${!validateNewPassword.status
                                ? 'invalidate-box'
                                : ''
                                }`}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  inputCFPassword.current.focus()
                                }
                              }}
                              value={newPassword}
                              defaultValue={newPassword}
                              onChange={(e) => (
                                checkPassword(e),
                                handleNewPassword('', true),
                                setValidateEmailalready(true)
                              )}
                              onClick={(e) => handlePasswordsconditionbox(1)}
                            />
                            <span
                              className={`input-group-text border-left-0 bg-transparent ${!validateNewPassword.status
                                ? 'invalidate-box'
                                : ''
                                }`}
                              id="basic-addon2"
                            >
                              <div
                                className="cursor"
                                onClick={() =>
                                  setShowNewPassword(!showNewPassword)
                                }
                              >
                                {showNewPassword ? (
                                  <FaEye className="text-lighter" />
                                ) : (
                                  <FaEyeSlash className="text-lighter" />
                                )}{' '}
                              </div>
                            </span>
                          </div>
                          {!validateNewPassword.status && (
                            <label className="form-label invalidate-text  text-14">
                              {validateNewPassword.msg}
                            </label>
                          )}
                          {passwordsconditionbox.no === 1 &&
                            passwordsconditionbox.isOpen && (
                              <div className="passwords-condition-box animate fadeIn one mt-3">
                                <p className="text-12 m-0">
                                  Passwords must contain :
                                </p>

                                <p
                                  id="pass-length"
                                  className={`invalid mb-1 text-12 ${checkedLength ? 'text-success' : 'invalid'
                                    }`}
                                >
                                  <FaCheck /> a minimum of 8 characters
                                </p>
                                <p
                                  id="pass-lower"
                                  className={`invalid mb-1 text-12 ${checkedLetter ? 'text-success' : 'invalid'
                                    }`}
                                >
                                  <FaCheck /> a minimum of 1 lowercase letter
                                  [a-z]
                                </p>
                                <p
                                  id="pass-upper"
                                  className={`invalid mb-1 text-12 ${checkedCapital ? 'text-success' : 'invalid'
                                    }`}
                                >
                                  <FaCheck /> a minimum of 1 uppercase letter
                                  [A-Z]
                                </p>
                                <p
                                  id="pass-number"
                                  className={`invalid mb-1 text-12 ${checkedNumber ? 'text-success' : 'invalid'
                                    }`}
                                >
                                  <FaCheck /> a minimum of 1 numeric character
                                  [0-9]
                                </p>
                                <p
                                  id="pass-special"
                                  className={`invalid mb-1 text-12 ${checkedSpecial ? 'text-success' : 'invalid'
                                    }`}
                                >
                                  <FaCheck /> a minimum of 1 special character:
                                  ~`!@#$%^&amp;*()- _+={ }
                                  []|\;:&quot;&lt;&gt;,./?{' '}
                                </p>
                              </div>
                            )}
                        </div>

                        <div className="mb-3 mt-3">
                          <label className="form-label text-14">
                            Confirm Password
                          </label>
                          <label id="cfpassword" className="form-label"></label>
                          <div className="input-group">
                            <input
                              ref={inputCFPassword}
                              id="cfpassword"
                              type={showCfPassword ? 'password' : ''}
                              className={`form-control border-right-0 ${!validateCfPassword.status
                                ? 'invalidate-box'
                                : ''
                                }`}
                              value={cfPassword}
                              defaultValue={cfPassword}
                              onChange={(e) => (
                                setCfPassword(e.target.value),
                                handleCfPassword('', true),
                                setValidateEmailalready(true)
                              )}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  onSubmit()
                                }
                              }}
                            />
                            <span
                              className={`input-group-text border-left-0 bg-transparent ${!validateCfPassword.status
                                ? 'invalidate-box'
                                : ''
                                }`}
                              id="basic-addon2"
                            >
                              <div
                                className="cursor"
                                onClick={() =>
                                  setShowCfPassword(!showCfPassword)
                                }
                              >
                                {showCfPassword ? (
                                  <FaEye className="text-lighter" />
                                ) : (
                                  <FaEyeSlash className="text-lighter" />
                                )}{' '}
                              </div>
                            </span>
                          </div>
                          {!validateCfPassword.status && (
                            <label className="form-label invalidate-text text-14">
                              {validateCfPassword.msg}
                            </label>
                          )}
                        </div>
                        <div className="mb-3">
                          <p className="text-14 m-0">
                            By clicking the “Sign up” button, you are creating a{' '}
                            Tag Turbo account, <br />
                            and you agree to Tag Turbo’s{' '}
                            <span className="text-14 m-0">
                              <u>
                                <a
                                  target="_blank"
                                  className="text-primary"
                                  href="https://tagturbo.com/privacy-notice/"
                                >
                                  Privacy Notice.
                                </a>
                              </u>{' '}
                              and{' '}
                              <u>
                                <a
                                  target="_blank"
                                  className="text-primary"
                                  href="https://tagturbo.com/terms/"
                                >
                                  Terms of Service
                                </a>
                              </u>
                            </span>
                          </p>
                        </div>
                        <div className="text-center">


                          <button type="button"
                            onClick={() => onSubmit()}
                            className="btn btn-gradient-authen w-50 text-14 text-semibold btn-load"
                            disabled={isClick}>
                            <span className="flex-grow-1  d-flex align-items-center justify-content-center">
                              {isClick && <span className="spinner-border flex-shrink-0 mx-2" role="status"></span>}
                              Sign up
                            </span>
                          </button>
                        </div>
                        <div className="mt-4 text-center">
                          <p className="text-14">
                            Already have an account?{' '}
                            <span
                              className="text-primary cursor"
                              onClick={() => (window.location.href = '/login')}
                            >
                              Sign in
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReCaptchaComponent
          keepTokenFn={keepTokenFn}
          refreshToken={refreshReCaptcha}
        />
      </GoogleReCaptchaProvider>
    </SignUpBox>
  )
}

export default SignUpComponent
