import axios from 'axios'
import { redirectLogin, redirectPermission } from '../../helper/helper'
const { REACT_APP_API_TAG } = process.env

const accountList = async (token, account_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/user-management/role-user-account-list`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const roleAccountByEmail = async (token, account_id, email) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/user-management/role-user-account-by-email/${email}`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const editRoleAccount = async (token, account_id, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/user-management/role-user-account`
    const response = await axios.patch(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const inviteAccount = async (token, account_id, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/user-management/invite-account-user`
    const response = await axios.post(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else if (error.response?.status === 400) {
      res = {
        status: 400,
        data: error,
      }
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const confirmInviteAccount = async (token, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/user-management/confirm-invite-user`
    const response = await axios.post(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else if (error.response?.status === 400) {
      res = {
        status: 400,
        data: error,
      }
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const allAccountByToken = async (token) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/account`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const allCountry = async (token) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/get-country`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const editAccountSetting = async (token, account_id, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/account-setting`
    const response = await axios.patch(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const closeAccountSetting = async (token, account_id, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/user-management/delete-invite-account-user`
    const response = await axios.delete(URL, {
      headers: customConfigHeader.headers,
      data: obj,
    })
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const deleteAccount = async (token, account_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/account/delete-account`
    const response = await axios.post(URL, {}, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const deleteUserInAccount = async (token, account_id, delete_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/user-management/delete-account-user?email=${encodeURIComponent(
      delete_id,
    )}`
    const response = await axios.delete(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      redirectLogin()
    } else if (error?.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: error?.response?.status || 500,
        data: error,
      }
    }
  }
  return res
}

const roleByAccountId = async (token, account_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/user-management/get-role-by-account-id`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const roleByWorkspaceId = async (token, workspace_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/user-management/get-role-by-workspace-id`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const allCurrency = async (token) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/get-currency`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const checkAlreadyFreeTrial = async (token) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,

      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/check-already-has-free-trial`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const checkAlreadyFreePlan = async (token) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,

      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/check-already-has-free-plan`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const createAccountTrial = async (token, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,

      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/account-trial`
    const response = await axios.post(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const checkLimitWorkspace = async (token, account_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/check-limit-workspace`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else if (error.response?.status === 400) {
      res = {
        status: 400,
        data: error,
      }
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const checkLimitAccountUser = async (token, account_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/user-management/check-user-account`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else if (error.response?.status === 400) {
      res = {
        status: 400,
        data: error,
      }
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
export {
  accountList,
  roleAccountByEmail,
  editRoleAccount,
  inviteAccount,
  confirmInviteAccount,
  allAccountByToken,
  allCountry,
  editAccountSetting,
  closeAccountSetting,
  deleteAccount,
  deleteUserInAccount,
  roleByAccountId,
  roleByWorkspaceId,
  allCurrency,
  checkAlreadyFreeTrial,
  createAccountTrial,
  checkLimitWorkspace,
  checkLimitAccountUser,
  checkAlreadyFreePlan
}
